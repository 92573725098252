import { FC } from "react";
import { FormikProps } from "formik";
import { wrapOnchange } from "utils";
import { SelectInput, TextArea, TextInput } from "components/core";
import {
    BlockPicker,
    DistrictPicker,
    PlotPicker,
    RegionPicker,
    RoundPicker,
} from "containers";

export interface UpdateCustomerLocationProps {
    form: FormikProps<any>;
}

const UpdateCustomerLocation: FC<UpdateCustomerLocationProps> = ({ form }) => {
    const handleCodeChange = (value: string) => {
        form.setFieldValue(
            `deliveryPointNumber`,
            value.match(/\d+/g)?.join("") || ""
        );
    };

    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className="text-xs font-light">Geo Code Information</span>
                <div className="grid grid-cols-2 gap-6 mt-2">
                    <div>
                        <RegionPicker id={"region"} rawId {...form} required={true} />
                    </div>

                    {form.values.region && (
                        <div>
                            <DistrictPicker
                                id={"district"}
                                filter={{ region: form?.values?.region }}
                                {...form}
                                rawId={true}
                                required={true}
                            />
                        </div>
                    )}

                    {form.values.district && (
                        <div>
                            <BlockPicker
                                id="block"
                                label="Block"
                                placeholder="e.g. Block 01-01-001"
                                filter={{ district: form?.values?.district, status: "Active" }}
                                required={true}
                                {...form}
                                rawId={true}
                            />
                        </div>
                    )}

                    {form.values.block && (
                        <div>
                            <RoundPicker
                                id="round"
                                label="Round"
                                placeholder="e.g. Block 01-01-001-001"
                                filter={{ block: form?.values?.block, status: "Active" }}
                                required={true}
                                {...form}
                                rawId={true}
                            />
                        </div>
                    )}

                    {form.values.round && (
                        <div>
                            <PlotPicker
                                id="plot"
                                label="Plot"
                                placeholder="e.g. Plot 01-01-001-001-001"
                                filter={{ round: form?.values?.round, status: "Active" }}
                                required={true}
                                {...form}
                                rawId={false}
                            />
                        </div>
                    )}

                    {form.values.plot && (
                        <div className="">
                            <TextInput
                                id="deliveryPointNumber"
                                label="Delivery point"
                                disabled={!form?.values?.plot}
                                preText={form?.values?.plot?.code + "-"}
                                type="text"
                                placeholder="e.g. 0404"
                                minLength={4}
                                maxLength={4}
                                required={true}
                                {...form}
                                handleChange={wrapOnchange(handleCodeChange)}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="pt-6">
                <span className="text-xs font-light">Coordinate Information</span>
                <div className="grid grid-cols-2 gap-6 mt-2">
                    <div className="col-start-1">
                        <TextInput
                            id="easting"
                            label="Easting (X)"
                            type="number"
                            placeholder="e.g. 56"
                            {...form}
                            required={true}
                        />
                    </div>

                    <div>
                        <TextInput
                            id="northing"
                            label="Northing (Y)"
                            type="number"
                            placeholder="e.g. 56"
                            {...form}
                            required={true}
                        />
                    </div>

                    <div className="">
                        <SelectInput
                            id="zone"
                            label="Zone"
                            options={[
                                { label: "Select Zone", value: "" },
                                { label: "Zone 30°N", value: "30" },
                                { label: "Zone 31°N", value: "31" },
                            ]}
                            placeholder="e.g. Zone 30/31"
                            {...form}
                            required={true}
                        />
                    </div>

                    <div className="space-y-1 col-span-1">
                        <TextInput
                            id="structureNumber"
                            label="Structure ID"
                            type="text"
                            placeholder="e.g. 093093494"
                            {...form}
                            required={true}
                        />
                    </div>

                    <div className="space-y-1">
                        <TextInput
                            id="address"
                            label="Address"
                            type="text"
                            placeholder="e.g. No. 28, Kofi Annan Avenue, North Legon"
                            {...form}
                        />
                    </div>

                    <div className="space-y-1">
                        <TextInput
                            id="ghanaPostAddress"
                            label="GhanaPost Address"
                            type="text"
                            placeholder="e.g. GA-343-3534"
                            {...form}
                        />
                    </div>
                    <div className="space-y-1 col-span-2">
                        <TextArea
                            id="reason"
                            label="Reason"
                            placeholder="e.g. Reason for change"
                            {...form}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateCustomerLocation;
