import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import MaterialInfoSummary from "components/forms/resolve-inspection-service-order/materials-info-summary";
import PropertyInfoSummary from "components/forms/resolve-inspection-service-order/property-info-summary";
import ServiceInfoSummary from "components/forms/resolve-inspection-service-order/service-info-summary";
import { FC } from "react";

interface InspectionServiceOrderResolutionViewProps {
  resolution: any;
  region: any;
  district: any;
  result?: "Passed" | "Failed";
}

const InspectionServiceOrderResolutionView: FC<
  InspectionServiceOrderResolutionViewProps
> = ({ resolution, region, district, result }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="p-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Property Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about property</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <PropertyInfoSummary
            data={{ ...resolution?.property, result, region, district }}
            config={currentConfig}
          />
        </div>
      </div>
      <div className="pt-6 p-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Service Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about property</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <ServiceInfoSummary data={resolution?.service} />
        </div>
      </div>
      <div className="pt-6 p-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Installation Infomation
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about property</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <MaterialInfoSummary
            data={{
              installationType: resolution?.installationType,
              appliances: resolution?.appliances,
              materials: resolution?.materials,
              notes: resolution?.notes,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default InspectionServiceOrderResolutionView;
