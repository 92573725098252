import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { classNames, wrapClick, wrapImage } from "utils";
import parse from "html-react-parser";
import { Avatar } from "components";
import moment from "moment";

interface ResolutionDetailsProps {
  complaint: any;
}

const ResolutionDetailsContainer: FC<ResolutionDetailsProps> = ({
  complaint,
}) => {
  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      <div className='flex-1 flex flex-col overflow-hidden'>
        <div className='hidden print:flex flex-row items-center space-x-4 justify-center py-6 border-b border-gray-300'>
          <div>
            <img
              alt='ECG Logo'
              src={require("assets/logo-new.png")}
              className='h-32 w-32'
            />
          </div>
          <div className='space-y-1 text-gray-900'>
            <h1 className='text-xl text-primary-600 font-bold'>
              ELECTRICITY COMPANY OF GHANA LIMITED
            </h1>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <EnvelopeIcon className='h-4 w-4' />
                <span>P.O BOX GP 521, ACCRA</span>
              </div>
              <div className='flex items-center space-x-2'>
                <PhoneIcon className='h-4 w-4' />
                <span>0302-611-611</span>
              </div>
              <div className='flex items-center space-x-2'>
                <GlobeAltIcon className='h-4 w-4' />
                <span>ecg@ecggh.com</span>
              </div>
            </div>
            <div className='flex items-center space-x-6'>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>VAT REG:</span>
                <span>714V000395</span>
              </div>
              <div className='flex items-center space-x-2'>
                <span className='font-semibold'>Website:</span>
                <span>https://www.ecggh.com/</span>
              </div>
            </div>
          </div>
        </div>

        <div className=' p-6 overflow-y-auto flex-1'>
        <div className=''>
          <span className='text-xs font-light'>Resolution</span>
          <div className='grid grid-cols-3 gap-6 mt-2'>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Started At
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {moment(complaint?.startedAt).isValid()
                  ? moment(complaint?.startedAt).format("LL LT")
                  : "Resolution not started"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Resolved At
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {moment(complaint?.closedAt).isValid()
                  ? moment(complaint?.closedAt).format("LL LT")
                  : "Resolution not closed"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Investigation Service order
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {complaint?.resolution?.investigationServiceOrderCode || "N/A"}
              </div>
            </div>
            <div className='col-span-3'>
              <span className='block text-sm font-light text-gray-700'>
                Resolution Notes
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {complaint?.resolution?.notes || "N/A"}
              </div>
            </div>
            <div className='col-span-3'>
              <span className='block text-sm font-light text-gray-700'>
                Supporting Document
              </span>
              {complaint?.resolution?.supportingDocumentUrls.length > 0 ? (
                <div className='mt-1 grid grid-cols-3 gap-3 w-full sm:text-sm'>
                  {complaint?.resolution?.supportingDocumentUrls?.map(
                    (el: string) => (
                      <>
                        {wrapImage(
                          <img
                            src={el}
                            alt='front'
                            className='w-full h-64 text-xs'
                          />
                        )}
                      </>
                    )
                  )}
                </div>
              ) : (
                <div className='border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600'>
                  Dcoument not provided
                </div>
              )}
            </div>
          </div>
        </div>
        </div>
      </div>

      {/* <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        <button
          type='button'
          // onClick={wrapClick(handlePrint)}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          Print
        </button>
      </div> */}
    </div>
  );
};

export default ResolutionDetailsContainer;
