import { useMemo } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import numeral from "numeral";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import lodash from "lodash";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  TvIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

const GET_SERVICE_REQUEST_SUMMARY = gql`
  query GetServiceRequestsSummary($region: ID, $district: ID) {
  summary: getServiceRequestsSummary(district: $district, region: $region) {
    totalRequests
    totalPendingRequests
    totalAbandonedRequests
    totalCompletedRequests
    totalPremiumRequests
    totalPendingPremiumRequests
    totalStandardRequests
    totalPendingStandardRequests
  }
}

`;

const GET_CUSTOMERS_BY_GROUP = gql`
  query GetCustomersOverviewSummary(
    $groupBy: CustomersByGroupSummaryGroupBy!
    $region: ID
    $district: ID
  ) {
    summary: getCustomersByGroupSummary(
      groupBy: $groupBy
      region: $region
      district: $district
    ) {
      label: _id
      count
    }
  }
`;

const serviceRequestStats = [
  {
    id: 1,
    name: "Total Service Requests",
    icon: UsersIcon,
    accessor: "totalRequests",
  },
  {
    id: 2,
    name: "Total Requests Pending Installation",
    icon: EnvelopeOpenIcon,
    accessor: "totalPendingRequests",
  },
  {
    id: 3,
    name: "Total Requests Pending Inspection",
    icon: CursorArrowRaysIcon,
    accessor: "totalAbandonedRequests",
  },
  {
    id: 4,
    name: "Total Completed Requests",
    icon: CursorArrowRaysIcon,
    accessor: "totalCompletedRequests",
  },
  {
    id: 5,
    name: "Total Premium Requests",
    icon: CursorArrowRaysIcon,
    accessor: "totalPremiumRequests",
  },
  {
    id: 6,
    name: "Total Pending Premium Requests",
    icon: CursorArrowRaysIcon,
    accessor: "totalPendingPremiumRequests",
  },
  {
    id: 7,
    name: "Total Standard Requests",
    icon: CursorArrowRaysIcon,
    accessor: "totalStandardRequests",
  },
  {
    id: 8,
    name: "Total Pending Standard Requests",
    icon: CursorArrowRaysIcon,
    accessor: "totalPendingStandardRequests",
  },
  {
    id: 8,
    name: "Total Customers",
    icon: UsersIcon,
    accessor: "totalCustomers",
  },
];

const ServiceRequestsOverviewContainer = () => {
  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_SERVICE_REQUEST_SUMMARY, {
    variables: {
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    },
  });

  const { data: customersData } = useQuery(GET_CUSTOMERS_BY_GROUP, {
    variables: {
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      groupBy: "ContractStatus",
    },
  });

  const totalCustomers = useMemo(
    () => lodash.sumBy(customersData?.summary, "count"),
    [customersData?.summary]
  );
  const totalRequests = useMemo(
    () => data?.summary?.totalRequests ?? 0,
    [data?.summary]
  );

  return (
    <>
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        Service Request Overview
      </h3>

      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {serviceRequestStats.map((item) => (
          <div
            key={item.id}
            className="flex items-center space-x-6 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6"
          >
            <div className="rounded-md bg-primary-500 flex items-center justify-center aspect-square h-14 w-14">
              <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
            <div className="flex-1">
              <dt>
                <p className=" truncate text-sm font-medium text-gray-500">
                  {item.name}
                </p>
              </dt>
              <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-primary-600">
                  {numeral(
                    lodash.get(
                      { ...data?.summary, totalCustomers: totalCustomers },
                      item.accessor,
                      0
                    )
                  ).format("0,0")}
                </div>

                {!["totalRequests", "totalCustomers"].includes(
                  item.accessor
                ) && (
                    <div className="bg-gray-100 text-gray-800 inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0">
                      {numeral(
                        lodash.round(
                          (lodash.get(
                            { ...data?.summary, totalCustomers: totalCustomers },
                            item.accessor,
                            0
                          ) /
                            totalRequests) *
                          100,
                          2
                        )
                      ).format("0,0.00")}
                      %
                    </div>
                  )}
              </dd>
            </div>
          </div>
        ))}
      </dl>
    </>
  );
};

export default ServiceRequestsOverviewContainer;
