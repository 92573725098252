import { FC } from "react";
import {
  AcademicCapIcon,
  ArrowRightOnRectangleIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { Link, Navigate, Outlet } from "react-location";
import { useReactiveVar } from "@apollo/client";
import _ from "lodash";
import routes from "router/routes";
import { clearAuth, currentTokenVar, currentUserVar } from "apollo/cache/auth";
import { classNames, wrapClick } from "utils";
import { Avatar } from "components/core";
import { withRoutePermissions } from "router/filter";

const AppLayout: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const currentToken = useReactiveVar(currentTokenVar);

  const navigation = _.chain(routes)
    .filter((route) => _.get(route, "meta.layout") === "App")
    .thru(withRoutePermissions)
    .filter((item) => !!item.sidebar)
    .value();

  if (!currentToken) {
    if (window.location.hostname.includes("ecg.com.gh")) {
      window.location.href = "https://staff.ecg.com.gh";
    } else {
      return (
        <Navigate
          to={"/signin"}
          // search={{ redirect: location.current.href }}
          replace
        />
      );
    }
  }

  return (
    <div className="h-screen">
      <div className="h-full flex">
        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col border-r border-gray-200 bg-primary-700 w-64">
            <div className="flex flex-shrink-0 items-center border-b border-primary-600 p-4 flex-row">
              <div className="flex-shrink-0 h-10 w-10 p-1 bg-indigo-800">
                <img
                  className="h-full w-full aspect-h-1 aspect-w-1 border-white border p-1"
                  src={require("assets/logo.png")}
                  alt="SUBS"
                />
              </div>
              <div className="ml-3">
                <p className="text-sm text-white font-mono">
                  Commercial Services Console
                </p>
                {/* <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">View profile</p> */}
              </div>
            </div>
            <div className="py-4 flex flex-grow flex-col overflow-y-auto no-scrollbar">
              <nav className="flex-1 space-y-1.5 px-2" aria-label="Sidebar">
                {_.map(navigation, (item) => {
                  const isCurrent = false;
                  return (
                    <Link
                      key={item.sidebar?.label}
                      to={item.path}
                      getActiveProps={() => ({
                        className: "bg-primary-600 text-white",
                      })}
                      getInactiveProps={() => ({
                        className:
                          "text-gray-300 hover:bg-primary-800 hover:text-white",
                      })}
                      className="group flex items-center px-2 py-2 text-sm rounded-md"
                    >
                      {item.sidebar?.icon && (
                        <item.sidebar.icon
                          className={classNames(
                            isCurrent
                              ? "text-gray-900"
                              : "text-gray-200 group-hover:text-gray-50",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                      )}
                      {item.sidebar?.label}
                    </Link>
                  );
                })}
              </nav>
              <nav className="space-y-1 px-2">
                <a
                  href="mailto:supoort.cra@polymorphlabs.io"
                  target="_blank"
                  className="group w-full flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-primary-800 hover:text-white"
                  rel="noreferrer"
                >
                  <EnvelopeIcon
                    className={classNames(
                      "text-gray-200 group-hover:text-gray-50",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  Support
                </a>
                <a
                  href="https://docs.subsenterprise.com/commercial-services-console"
                  target="_blank"
                  className="group w-full flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-primary-800 hover:text-white"
                  rel="noreferrer"
                >
                  <AcademicCapIcon
                    className={classNames(
                      "text-gray-200 group-hover:text-gray-50",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  Tutorials
                </a>
                <button
                  onClick={wrapClick(clearAuth)}
                  className="group w-full flex items-center px-2 py-2 text-sm rounded-md text-gray-300 hover:bg-primary-800 hover:text-white"
                >
                  <ArrowRightOnRectangleIcon
                    className={classNames(
                      "text-gray-200 group-hover:text-gray-50",
                      "mr-3 flex-shrink-0 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  Logout
                </button>
              </nav>
            </div>

            <div className="flex flex-shrink-0 border-t border-primary-600 p-4 overflow-hidden">
              <div className="flex-1 flex items-center space-x-3 overflow-hidden">
                <div className="flex-shrink-0">
                  <Avatar
                    src={currentUser?.profileImageUrl}
                    alt={
                      [
                        currentUser?.firstName || "",
                        currentUser?.lastName || "",
                      ]
                        .join(" ")
                        .trim() || "N A"
                    }
                  />
                </div>
                <div className="flex-1 overflow-hidden">
                  <p className="text-sm font-medium text-gray-200 group-hover:text-white line-clamp-1">
                    {[currentUser?.firstName || "", currentUser?.lastName || ""]
                      .join(" ")
                      .trim()}
                  </p>
                  <p className="text-xs font-medium text-gray-300 group-hover:text-gray-100 line-clamp-1 text-ellipsis overflow-hidden">
                    {currentUser?.emailAddress}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
          {/* Display content here */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
