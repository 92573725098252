import { DistrictCodes, Nationalities } from "apollo/data";
import lodash from "lodash";

export const GhanaPostRegex = new RegExp(
  `^(${lodash.map(DistrictCodes, ({code}) => code.length > 2 ? lodash.chain(code).split("").chunk(2).map(chunk =>  chunk.join("")).join("-?").value() : code).join("|")})-?\\d{3,4}-\\d{4}$`,
  "g"
);

export const GhanaCardRegex = new RegExp(
  `^(${lodash
    .map(Nationalities, "alpha_3_code")
    .join("|")})-\\d{9}-(\\d|[A-Z])$`,
  "g"
);
