import React, { FC } from "react";
import { Avatar, TextArea, TextInput, UploadArea } from "components/core";
import { FormikProps } from "formik";
import { classNames } from "utils";
import { TariffClassPicker } from "containers";
import _ from "lodash";

type Props = {
  customer: any;
  form: FormikProps<any>;
};

const UpdateCustomerTariffForm: FC<Props> = ({ customer, form }) => {
  return (
    <div className='space-y-6 divide-y divide-gray-200 '>
      <div>
        <div className=''>
          <span className='text-xs font-light'>Customer Information</span>
        </div>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
            <Avatar
              alt={customer?.fullName || "N A"}
              src={customer?.profileImageUrl || ""}
              size='xl'
            />

            <span
              className={classNames(
                "bg-gray-100 text-gray-800",
                customer?.status === "SuperAdmin"
                  ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                  : "",
                customer?.status === "Admin"
                  ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                  : "",
                customer?.status === "Supervisor"
                  ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                  : "",
                customer?.status === "Reader"
                  ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                  : "",
                customer?.status === "Queued"
                  ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                  : "",
                customer?.status === "Success"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                customer?.status === "Reversed"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                !customer?.status
                  ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                  : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
              )}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 10 10'
                fill='currentColor'
                className='w-1.5 h-1.5'
              >
                <circle
                  fillRule='evenodd'
                  cx='5'
                  cy='5'
                  r='5'
                  clipRule='evenodd'
                />
              </svg>
              <span>{_.startCase(customer?.status) || "Active Contract"}</span>
            </span>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Customer Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {customer?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Ghana Card Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.ghanaCardNumber || "N/A"
                : customer?.customer?.organization
                  ?.organizationRegistrationNumber || "N/A"}
            </div>
          </div>
          <div className='col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Full Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.fullName
                : customer?.customer?.organization?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Phone Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.phoneNumber || "N/A"
                : customer?.customer?.organization?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Email Address
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.emailAddress || "N/A"
                : customer?.customer?.organization?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='space-y-6 divide-y divide-gray-200 pt-6 flex-1'>
        <div>
          <span className='text-xs font-light'>User Information</span>
          <div className='grid grid-cols-2 gap-6 mt-2'>
            <div>
              <TariffClassPicker
                id='tariffClass'
                label='Tariff Class'
                rawId
                placeholder='e.g. Mensah Enoch Nana Nyankah'
                {...form}
              />

            </div>

            <div className=''>

              <TextInput
                id='effectiveDate'
                label='Effective Date'
                type='date'
                placeholder=''
                {...form}
              />
            </div>

            <div className='col-span-2'>
              <TextArea
                id='reason'
                label='Reason'
                placeholder='e.g. Changed Phone Number'
                {...form}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCustomerTariffForm;
