import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, Loader, StepWizardStatus } from "components";
import lodash from "lodash";
import moment from "moment";
import { FC, useEffect, useState, useMemo } from "react";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { currentConfigVar } from "apollo/cache/config";
import RegularizationDetailsContainer from "./service-request-details";
import ContractSigningDetailsContainer from "./contract-signing-details";
import InspectionOrderDetailsContainer from "./inspection-order-details";
import ConnectionChargeDetailsContainer from "./connection-charge-details";
import InstallationOrderDetailsContainer from "./installation-order-details";
import ApplicationChargeDetailsContainer from "./application-charge-details";
import CustomerDetailsContainer from "./customer-details";

const GET_SERVICE_REQUEST = gql`
  query GetRegularization($id: ID!) {
    regularization: getRegularization(id: $id) {
      _id
      code
      inspection {
        _id
      }
      installation {
        _id
      }
      status
      createdAt
      updatedAt
      connectionFeeInvoice {
        _id
      }
      applicationFeeInvoice {
        _id
      }
    }
  }
`;

const regularizationSteps = [
  {
    name: "Service Regularization",
    description: "",
    Component: RegularizationDetailsContainer,
  },
  {
    name: "Application Charge",
    description: "",
    Component: ApplicationChargeDetailsContainer,
  },
  {
    name: "Inspection Order",
    description: "",
    Component: InspectionOrderDetailsContainer,
  },
  {
    name: "Contract Signing",
    description: "",
    Component: ContractSigningDetailsContainer,
  },
  {
    name: "Connection Charge",
    description: "",
    Component: ConnectionChargeDetailsContainer,
  },
  {
    name: "Installation Order",
    description: "",
    Component: InstallationOrderDetailsContainer,
  },
  {
    name: "Customer Details",
    description: "",
    Component: CustomerDetailsContainer,
  },
];

const RegularizationPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const { params } = useMatch<LocationGenerics>();

  const { data, loading, refetch } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: params.serviceRegularization,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  const filteredFormSteps = useMemo(
    () => regularizationSteps.filter((_step) => true),
    []
  );

  const steps = useMemo(
    () => [...filteredFormSteps].map((rawStep, href) => ({ ...rawStep, href })),
    [filteredFormSteps]
  );

  useEffect(() => {
    switch (data?.regularization?.status) {
      case "ApplicationFeeGenerated":
      case "ApplicationFeePaid": {
        setStep(1);
        setLastStep(1);
        break;
      }
      case "InspectionInitiated":
      case "InspectionFailed":
      case "InspectionCompleted": {
        setStep(2);
        setLastStep(2);
        break;
      }
      case "ContractGenerated":
      case "ContractSigned": {
        setStep(3);
        setLastStep(3);
        break;
      }
      case "ConnectionFeeGenerated":
      case "ConnectionFeePaid": {
        setStep(4);
        setLastStep(4);
        break;
      }
      case "InstallationInitiated":
      case "InstallationCompleted":
      case "InstallationFailed": {
        setStep(5);
        setLastStep(5);
        break;
      }

      case "Completed": {
        setLastStep(6);
        setStep(6);
        break;
      }

      default:
        setStep(1);
        setLastStep(1);
        break;
    }
  }, [data?.regularization?.status]);

  const renderView = () => {
    const { Component } = steps[step || 0];
    return (
      <Component
        values={data?.regularization}
        id={lodash.get(data?.regularization, "_id")}
        code={lodash.get(data?.regularization, "code")}
        status={lodash.get(data?.regularization, "status")}
        createdAt={lodash.get(data?.regularization, "createdAt")}
        updatedAt={lodash.get(data?.regularization, "updatedAt")}
        refetch={refetch}
      />
    );
  };

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <Header />
      <div className='flex flex-1 overflow-hidden'>
        {loading ? (
          <Loader />
        ) : (
        <div className="bg-gray-100 shadow-xl flex-1 flex p-8 overflow-hidden relative">
          <div className="w-1/3 justify-center flex flex-col space-y-6">
            <div className="p-6 border border-dashed border-gray-300 rounded grid grid-cols-2 gap-6 mr-12">
              <div>
                <span className="block text-md font-light text-gray-700">
                  Regularization Code
                </span>
                <div className="mt-1 block w-full text-md">
                  {data?.regularization?.code || "N/A"}
                </div>
              </div>
              <div className="">
                <span className="block text-md font-light text-gray-700">
                  Regularization Date
                </span>
                <div className="mt-1 block w-full text-md">
                  {data?.regularization?.createdAt
                    ? moment(data?.regularization?.createdAt).format(dateFormat)
                    : "N/A"}
                </div>
              </div>
            </div>
            <StepWizardStatus
              steps={steps}
              step={step}
              setStep={setStep}
              lastStep={lastStep}
              status={data?.regularization?.status}
            />
          </div>
          <div className="flex flex-1 overflow-hidden bg-white rounded-lg">
            {renderView()}
          </div>
        </div>
        )}
      </div>
    </main>
  );
};

export default RegularizationPage;
