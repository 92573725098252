
import moment from 'moment';
import { FC } from 'react'
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { classNames } from 'utils';
import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from '@heroicons/react/20/solid'
import MyResponsivePie from './graphs/pie';
import numeral from 'numeral';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import lodash from 'lodash';
import { OfficeCalendarHeader, OfficeHeader } from 'components';
import MyResponsiveSunburst from './graphs/sunburst';
import MyResponsiveTreeMap from './graphs/tree-map';
import CustomersByRegionContainer from './containers/customers-by-region';
import CustomersByDistrictContainer from './containers/customers-by-district';
import CustomersByGroupContainer from './containers/customers-by-group';
import CustomersOverviewContainer from './containers/customers-overview';
import ServiceRequestsOverviewContainer from './containers/service-request-overview';

const DashboardPage: FC = () => {

  const searchParams = useSearch<LocationGenerics>();

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <OfficeCalendarHeader
        renderActions={() => (
          <>
          </>
        )}
      />
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto  p-4 sm:p-6 overflow-y-auto bg-gray-50">

          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">

            <div className='col-span-12'>
              <ServiceRequestsOverviewContainer />
            </div>

            {/* <div className='col-span-3 flex flex-col h-full'>
              <CustomersOverviewContainer />
            </div>
            
            {!searchParams.region && (
              <div className='col-span-9'>
                <CustomersByRegionContainer />
              </div>
            )} */}

            {/* {!searchParams.region && !searchParams.district && (
              <div className='col-span-9'>
                <CustomersByDistrictContainer />
              </div>
            )} */}

            <div className='col-span-6'>
              <CustomersByGroupContainer
                groupBy='ServiceType'
              />
            </div>

            <div className='col-span-6'>
              <CustomersByGroupContainer
                groupBy='ServiceClass'
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default DashboardPage;
