import { gql, useQuery } from "@apollo/client";

const GET_SERVICE_REGULARIZATIONS = gql`
  query GetRegularizations(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $month: PositiveInt
    $year: PositiveInt
    $status: RegularizationStatus
  ) {
    rows: getRegularizations(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      month: $month
      year: $year
      status: $status
    ) {
      _id
      code
      status
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      createdAt
      updatedAt
      customerData {
        customerType

        organization {
          name
        }
        representative {
          fullName
          phoneNumber
        }
      }
      billing {
        neighbourhoodClass
      }
      property {
        community
        ghanaPostAddress
      }
      service {
        serviceClass
      }
    }
    count: getRegularizationsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      month: $month
      year: $year
      status: $status
    )
  }
`;

export const useServiceRegularizations = (variables: any) => {
  const { data, ...rest } = useQuery(GET_SERVICE_REGULARIZATIONS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const serviceRegularizations = data?.rows || [];
  const count = data?.count || 0;
  return { serviceRegularizations, count, ...rest };
};
