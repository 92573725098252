import { FC } from 'react'
import { wrapImage } from 'utils';
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapProvider, Marker } from "react-map-gl";
import config from 'config';

interface AnomalyDetailsViewProps {
  anomaly: any;
}

const AnomalyDetailsView: FC<AnomalyDetailsViewProps> = ({ anomaly }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      {anomaly?.reports?.map((report: any) => (
        <div key={report?._id} className='pt-6 first:pt-0'>
          <span className='text-xs font-light'>Anomaly Information</span>
          <div className='mt-2 grid grid-cols-2 gap-6'>
            <div>
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Anomaly Image
                </span>
                {report?.imageUrl ? (
                  <div className="mt-1 block w-full">
                    {wrapImage(
                      <img
                        src={report?.imageUrl}
                        alt={report?.code}
                        className="h-64 w-full object-cover object-center"
                      />
                    )}
                  </div>
                ) : (
                  <div className="mt-1 block w-full sm:text-sm">
                    {"N/A"}
                  </div>
                )}
              </div>
            </div>
            <div className=''>
              <span className="block text-sm font-light text-gray-700">
                Anomaly Location
              </span>
              <div className="mt-1 block w-full sm:text-sm h-64">
                {(report?.meta?.anomalyLocation?.coordinates?.length > 0) && (<MapProvider>
                  <Map
                    id="service-location"
                    initialViewState={{
                      longitude:
                        report?.meta?.anomalyLocation?.coordinates?.[0],
                      latitude:
                        report?.meta?.anomalyLocation?.coordinates?.[1],
                      zoom: 15
                    }}
                    mapStyle="mapbox://styles/mapbox/streets-v12"
                    mapboxAccessToken={config.mapbox.token}
                  >
                    <Marker
                      longitude={
                        report?.meta?.anomalyLocation?.coordinates?.[0]
                      }
                      latitude={
                        report?.meta?.anomalyLocation?.coordinates?.[1]
                      }
                    >
                      <img
                        className="w-6 h-6"
                        src={require("assets/marker.png")}
                        alt={"pin"}
                      />
                    </Marker>
                    <Marker
                      longitude={
                        report?.meta?.anomalyImageLocation?.coordinates?.[0]
                      }
                      latitude={
                        report?.meta?.anomalyImageLocation?.coordinates?.[1]
                      }
                    >
                      <img
                        className="w-6 h-6"
                        src={require("assets/marker.png")}
                        alt={"pin"}
                      />
                    </Marker>
                  </Map>
                </MapProvider>)}
              </div>
            </div>
            <div className='col-span-2'>
              <span className="block text-sm font-light text-gray-700">
                Anomalies
              </span>
              <ul className='list-decimal'>
                {report?.anomalies?.map?.((item: any, idx: any) => (
                  <li key={idx} className="mt-1 block w-full sm:text-sm">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default AnomalyDetailsView