import { wrapClick } from "utils";
import { gql, useQuery } from "@apollo/client";
import { ServiceRequestView, Modal } from "components";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";

const GET_SERVICE_REQUEST = gql`
  query GetServiceRequest($id: ID!) {
    serviceRequest: getServiceRequest(id: $id) {
      _id
      code
      category
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      request {
        customer {
          customerType

          organization {
            name
            taxIdentificationNumber
            organizationRegistrationNumber
            organizationRegistrationDate
            organizationRegistrationDocumentUrl
            certificateOfIncorporationDocumentUrl
          }
          representative {
            title
            fullName
            nationality
            dateOfBirth
            gender
            phoneNumber
            emailAddress
            profileImageUrl
            hasGhanaCard
            ghanaCardNumber
            ghanaCardIssueDate
            ghanaCardExpiryDate
            ghanaCardFrontImageUrl
            ghanaCardBackImageUrl
            identityCardType
            identityCardNumber
            identityCardIssueDate
            identityCardExpiryDate
            identityCardFrontImageUrl
            identityCardBackImageUrl
          }
        }
        property {
          owner {
            fullName
            phoneNumber
          }
          region {
            _id
            code
            name
          }
          district {
            _id
            code
            name
          }
          ghanaPostAddress
          community
          streetName
          houseNumber
          structureNumber
          landmark
          premiseType {
            _id
            code
            name
          }
          premiseCategory {
            _id
            code
            name
          }
          activity {
            _id
            code
            name
          }
          subActivity {
            _id
            code
            name
          }
          geoLocation {
            type
            coordinates
          }
          sitePlanDocumentUrl
        }
        service {
          serviceType
          serviceClass
          serviceQuantity
          energyCertificateNumber
          energyCertificateDocumentUrl
        }
      }
      type
      status
      createdAt
      updatedAt
    }
  }
`;

export default function ViewServiceRequestContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const expandItem = () => {
    navigate({
      to: `./${searchParams.id}`,
    });
  };

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title='Service Request Information'
      description='Details of service request are shown below'
      size='4xl'
      renderActions={() => (
        <>
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(expandItem)}
          >
            View More
          </button>
        </>
      )}
    >
      <ServiceRequestView serviceRequest={data?.serviceRequest} />
    </Modal>
  );
}
