export default [
  {
    "region": "Ahafo Region",
    "district": "Asunafo North",
    "code": "HA"
  },
  {
    "region": "Ahafo Region",
    "district": "Asunafo South",
    "code": "HB"
  },
  {
    "region": "Ahafo Region",
    "district": "Asutifi North",
    "code": "HQ"
  },
  {
    "region": "Ahafo Region",
    "district": "Asutifi South",
    "code": "HR"
  },
  {
    "region": "Ahafo Region",
    "district": "Tano North",
    "code": "HT"
  },
  {
    "region": "Ahafo Region",
    "district": "Tano South",
    "code": "HS"
  },
  {
    "region": "Ashanti Region",
    "district": "Adansi Asokwa",
    "code": "A5"
  },
  {
    "region": "Ashanti Region",
    "district": "Adansi North",
    "code": "A2"
  },
  {
    "region": "Ashanti Region",
    "district": "Adansi South",
    "code": "A3"
  },
  {
    "region": "Ashanti Region",
    "district": "Afigya Kwabre",
    "code": "AF"
  },
  {
    "region": "Ashanti Region",
    "district": "Afigya Kwabre North",
    "code": "AAK"
  },
  {
    "region": "Ashanti Region",
    "district": "Ahafo Ano North",
    "code": "AX"
  },
  {
    "region": "Ashanti Region",
    "district": "Ahafo Ano South East",
    "code": "A8"
  },
  {
    "region": "Ashanti Region",
    "district": "Ahafo Ano South West",
    "code": "AY"
  },
  {
    "region": "Ashanti Region",
    "district": "Akrofuom",
    "code": "AAF"
  },
  {
    "region": "Ashanti Region",
    "district": "Amansie Central",
    "code": "AV"
  },
  {
    "region": "Ashanti Region",
    "district": "Amansie South",
    "code": "AAM"
  },
  {
    "region": "Ashanti Region",
    "district": "Amansie West",
    "code": "AW"
  },
  {
    "region": "Ashanti Region",
    "district": "Asante Akim Central",
    "code": "AC"
  },
  {
    "region": "Ashanti Region",
    "district": "Asante Akim North",
    "code": "AN"
  },
  {
    "region": "Ashanti Region",
    "district": "Asante Akim South",
    "code": "AA"
  },
  {
    "region": "Ashanti Region",
    "district": "Asokore Mampong",
    "code": "AS"
  },
  {
    "region": "Ashanti Region",
    "district": "Asokwa",
    "code": "AAS"
  },
  {
    "region": "Ashanti Region",
    "district": "Atwima Kwanwoma",
    "code": "AG"
  },
  {
    "region": "Ashanti Region",
    "district": "Atwima Mponua",
    "code": "AI"
  },
  {
    "region": "Ashanti Region",
    "district": "Atwima Nwabiagya",
    "code": "AH"
  },
  {
    "region": "Ashanti Region",
    "district": "Atwima Nwabiagya North",
    "code": "AAT"
  },
  {
    "region": "Ashanti Region",
    "district": "Bekwai",
    "code": "AB"
  },
  {
    "region": "Ashanti Region",
    "district": "BOSOMTWE",
    "code": "AT"
  },
  {
    "region": "Ashanti Region",
    "district": "Ejisu",
    "code": "AE"
  },
  {
    "region": "Ashanti Region",
    "district": "Ejura-Sekyedumase",
    "code": "AJ"
  },
  {
    "region": "Ashanti Region",
    "district": "Juaben",
    "code": "AL"
  },
  {
    "region": "Ashanti Region",
    "district": "Kumasi",
    "code": "AK"
  },
  {
    "region": "Ashanti Region",
    "district": "Kwabre East",
    "code": "AD"
  },
  {
    "region": "Ashanti Region",
    "district": "Kwadaso",
    "code": "AKW"
  },
  {
    "region": "Ashanti Region",
    "district": "Mampong",
    "code": "AM"
  },
  {
    "region": "Ashanti Region",
    "district": "Obuasi",
    "code": "AO"
  },
  {
    "region": "Ashanti Region",
    "district": "Obuasi East",
    "code": "AOE"
  },
  {
    "region": "Ashanti Region",
    "district": "Offinso North",
    "code": "A6"
  },
  {
    "region": "Ashanti Region",
    "district": "Offinso South",
    "code": "A7"
  },
  {
    "region": "Ashanti Region",
    "district": "Oforikrom",
    "code": "AOK"
  },
  {
    "region": "Ashanti Region",
    "district": "Old Tafo",
    "code": "AOT"
  },
  {
    "region": "Ashanti Region",
    "district": "Sekyere Afram Plains",
    "code": "AP"
  },
  {
    "region": "Ashanti Region",
    "district": "Sekyere Central",
    "code": "AQ"
  },
  {
    "region": "Ashanti Region",
    "district": "Sekyere East",
    "code": "AR"
  },
  {
    "region": "Ashanti Region",
    "district": "Sekyere Kumawu",
    "code": "AU"
  },
  {
    "region": "Ashanti Region",
    "district": "Sekyere South",
    "code": "AZ"
  },
  {
    "region": "Ashanti Region",
    "district": "Suame",
    "code": "ASU"
  },
  {
    "region": "Bono Region",
    "district": "Banda",
    "code": "BA"
  },
  {
    "region": "Bono Region",
    "district": "Berekum East",
    "code": "BB"
  },
  {
    "region": "Bono Region",
    "district": "Berekum West",
    "code": "BC"
  },
  {
    "region": "Bono Region",
    "district": "Dormaa Central",
    "code": "BD"
  },
  {
    "region": "Bono Region",
    "district": "Dormaa East",
    "code": "BE"
  },
  {
    "region": "Bono Region",
    "district": "Dormaa West",
    "code": "BF"
  },
  {
    "region": "Bono Region",
    "district": "Jaman North",
    "code": "BJ"
  },
  {
    "region": "Bono Region",
    "district": "Jaman South",
    "code": "BI"
  },
  {
    "region": "Bono Region",
    "district": "Sunyani",
    "code": "BS"
  },
  {
    "region": "Bono Region",
    "district": "Sunyani West",
    "code": "BY"
  },
  {
    "region": "Bono Region",
    "district": "Tain",
    "code": "BZ"
  },
  {
    "region": "Bono Region",
    "district": "Wenchi",
    "code": "BW"
  },
  {
    "region": "Bono East Region",
    "district": "Atebubu-Amantin",
    "code": "TA"
  },
  {
    "region": "Bono East Region",
    "district": "Kintampo North",
    "code": "TK"
  },
  {
    "region": "Bono East Region",
    "district": "Kintampo South",
    "code": "TL"
  },
  {
    "region": "Bono East Region",
    "district": "Nkoranza North",
    "code": "TN"
  },
  {
    "region": "Bono East Region",
    "district": "Nkoranza South",
    "code": "TO"
  },
  {
    "region": "Bono East Region",
    "district": "Pru East",
    "code": "TP"
  },
  {
    "region": "Bono East Region",
    "district": "Pru West",
    "code": "TW"
  },
  {
    "region": "Bono East Region",
    "district": "Sene East",
    "code": "TE"
  },
  {
    "region": "Bono East Region",
    "district": "Sene West",
    "code": "TS"
  },
  {
    "region": "Bono East Region",
    "district": "Techiman",
    "code": "TT"
  },
  {
    "region": "Bono East Region",
    "district": "Techiman North",
    "code": "TX"
  },
  {
    "region": "Central Region",
    "district": "Abura Asebu Kwamankese",
    "code": "CA"
  },
  {
    "region": "Central Region",
    "district": "Agona East",
    "code": "CP"
  },
  {
    "region": "Central Region",
    "district": "Agona West",
    "code": "CO"
  },
  {
    "region": "Central Region",
    "district": "Ajumako Enyan Esiam",
    "code": "CJ"
  },
  {
    "region": "Central Region",
    "district": "Asikuma / Odoben / Brakwa",
    "code": "CB"
  },
  {
    "region": "Central Region",
    "district": "Assin Central",
    "code": "CN"
  },
  {
    "region": "Central Region",
    "district": "Assin North",
    "code": "CR"
  },
  {
    "region": "Central Region",
    "district": "Assin South",
    "code": "CS"
  },
  {
    "region": "Central Region",
    "district": "Awutu Senya East",
    "code": "CX"
  },
  {
    "region": "Central Region",
    "district": "Awutu Senya West",
    "code": "CW"
  },
  {
    "region": "Central Region",
    "district": "Cape Coast",
    "code": "CC"
  },
  {
    "region": "Central Region",
    "district": "Effutu",
    "code": "CE"
  },
  {
    "region": "Central Region",
    "district": "Ekumfi",
    "code": "CF"
  },
  {
    "region": "Central Region",
    "district": "Gomoa Central",
    "code": "CL"
  },
  {
    "region": "Central Region",
    "district": "Gomoa East",
    "code": "CG"
  },
  {
    "region": "Central Region",
    "district": "Gomoa West",
    "code": "CI"
  },
  {
    "region": "Central Region",
    "district": "Hemang Lower Denkyira",
    "code": "CH"
  },
  {
    "region": "Central Region",
    "district": "Komenda Edina Eguafo",
    "code": "CK"
  },
  {
    "region": "Central Region",
    "district": "Mfantseman",
    "code": "CM"
  },
  {
    "region": "Central Region",
    "district": "Twifo Ati-Morkwa",
    "code": "CT"
  },
  {
    "region": "Central Region",
    "district": "Upper Denkyira East",
    "code": "CU"
  },
  {
    "region": "Central Region",
    "district": "Upper Denkyira West",
    "code": "CV"
  },
  {
    "region": "Eastern Region",
    "district": "Abuakwa North",
    "code": "E4"
  },
  {
    "region": "Eastern Region",
    "district": "Abuakwa South",
    "code": "E5"
  },
  {
    "region": "Eastern Region",
    "district": "Achiase",
    "code": "EC"
  },
  {
    "region": "Eastern Region",
    "district": "Akuapem North",
    "code": "E2"
  },
  {
    "region": "Eastern Region",
    "district": "Akuapem South",
    "code": "E3"
  },
  {
    "region": "Eastern Region",
    "district": "Akyemansa",
    "code": "EM"
  },
  {
    "region": "Eastern Region",
    "district": "Asene Manso Akroso",
    "code": "E8"
  },
  {
    "region": "Eastern Region",
    "district": "Asuogyaman",
    "code": "EA"
  },
  {
    "region": "Eastern Region",
    "district": "Atiwa East",
    "code": "E9"
  },
  {
    "region": "Eastern Region",
    "district": "Atiwa West",
    "code": "ET"
  },
  {
    "region": "Eastern Region",
    "district": "Ayensuano",
    "code": "EO"
  },
  {
    "region": "Eastern Region",
    "district": "Birim Central",
    "code": "EB"
  },
  {
    "region": "Eastern Region",
    "district": "Birim North",
    "code": "EX"
  },
  {
    "region": "Eastern Region",
    "district": "Birim South",
    "code": "EZ"
  },
  {
    "region": "Eastern Region",
    "district": "Denkyembour",
    "code": "ED"
  },
  {
    "region": "Eastern Region",
    "district": "Fanteakwa North",
    "code": "EF"
  },
  {
    "region": "Eastern Region",
    "district": "Fanteakwa South",
    "code": "E6"
  },
  {
    "region": "Eastern Region",
    "district": "Kwaebibirem",
    "code": "EK"
  },
  {
    "region": "Eastern Region",
    "district": "Kwahu Afram Plains North",
    "code": "EP"
  },
  {
    "region": "Eastern Region",
    "district": "Kwahu Afram Plains South",
    "code": "EQ"
  },
  {
    "region": "Eastern Region",
    "district": "Kwahu East",
    "code": "EH"
  },
  {
    "region": "Eastern Region",
    "district": "Kwahu South",
    "code": "EI"
  },
  {
    "region": "Eastern Region",
    "district": "Kwahu West",
    "code": "EJ"
  },
  {
    "region": "Eastern Region",
    "district": "Lower Manya Krobo",
    "code": "EL"
  },
  {
    "region": "Eastern Region",
    "district": "New Juaben North",
    "code": "E7"
  },
  {
    "region": "Eastern Region",
    "district": "New Juaben South",
    "code": "EN"
  },
  {
    "region": "Eastern Region",
    "district": "Nsawam Adoagyiri",
    "code": "EG"
  },
  {
    "region": "Eastern Region",
    "district": "Okere",
    "code": "ER"
  },
  {
    "region": "Eastern Region",
    "district": "Suhum",
    "code": "ES"
  },
  {
    "region": "Eastern Region",
    "district": "Upper Manya Krobo",
    "code": "EU"
  },
  {
    "region": "Eastern Region",
    "district": "Upper West Akim",
    "code": "EV"
  },
  {
    "region": "Eastern Region",
    "district": "West Akim",
    "code": "EW"
  },
  {
    "region": "Eastern Region",
    "district": "Yilo Krobo",
    "code": "EY"
  },
  {
    "region": "Greater Accra",
    "district": "Ablekuma Central",
    "code": "G7"
  },
  {
    "region": "Greater Accra",
    "district": "Ablekuma North",
    "code": "GF"
  },
  {
    "region": "Greater Accra",
    "district": "Ablekuma West",
    "code": "GU"
  },
  {
    "region": "Greater Accra",
    "district": "Accra",
    "code": "GA"
  },
  {
    "region": "Greater Accra",
    "district": "Ada East",
    "code": "GY"
  },
  {
    "region": "Greater Accra",
    "district": "Ada West",
    "code": "GX"
  },
  {
    "region": "Greater Accra",
    "district": "Adentan",
    "code": "GD"
  },
  {
    "region": "Greater Accra",
    "district": "Ashaiman",
    "code": "GB"
  },
  {
    "region": "Greater Accra",
    "district": "Ayawaso Central",
    "code": "G2"
  },
  {
    "region": "Greater Accra",
    "district": "Ayawaso East",
    "code": "GV"
  },
  {
    "region": "Greater Accra",
    "district": "Ayawaso North",
    "code": "G3"
  },
  {
    "region": "Greater Accra",
    "district": "Ayawaso West",
    "code": "G4"
  },
  {
    "region": "Greater Accra",
    "district": "Ga Central",
    "code": "GC"
  },
  {
    "region": "Greater Accra",
    "district": "Ga East",
    "code": "GE"
  },
  {
    "region": "Greater Accra",
    "district": "Ga North",
    "code": "GG"
  },
  {
    "region": "Greater Accra",
    "district": "Ga South",
    "code": "GS"
  },
  {
    "region": "Greater Accra",
    "district": "Ga West",
    "code": "GW"
  },
  {
    "region": "Greater Accra",
    "district": "Korle Klottey",
    "code": "GR"
  },
  {
    "region": "Greater Accra",
    "district": "Kpone Katamanso",
    "code": "GK"
  },
  {
    "region": "Greater Accra",
    "district": "Krowor",
    "code": "G6"
  },
  {
    "region": "Greater Accra",
    "district": "La Dade Kotopon",
    "code": "GL"
  },
  {
    "region": "Greater Accra",
    "district": "La Nkwantanang Madina",
    "code": "GM"
  },
  {
    "region": "Greater Accra",
    "district": "Ledzokuku",
    "code": "GZ"
  },
  {
    "region": "Greater Accra",
    "district": "Ningo Prampram",
    "code": "GN"
  },
  {
    "region": "Greater Accra",
    "district": "Okaikwei North",
    "code": "GI"
  },
  {
    "region": "Greater Accra",
    "district": "ShaI-Osudoku",
    "code": "GO"
  },
  {
    "region": "Greater Accra",
    "district": "Tema",
    "code": "GT"
  },
  {
    "region": "Greater Accra",
    "district": "Tema West",
    "code": "GQ"
  },
  {
    "region": "Greater Accra",
    "district": "Weija Gbawe",
    "code": "GJ"
  },
  {
    "region": "North East Region",
    "district": "Bunkpurugu Nakpanduri",
    "code": "MP"
  },
  {
    "region": "North East Region",
    "district": "Chereponi",
    "code": "MC"
  },
  {
    "region": "North East Region",
    "district": "East Mamprusi",
    "code": "ME"
  },
  {
    "region": "North East Region",
    "district": "Mamprugu Moaduri",
    "code": "MM"
  },
  {
    "region": "North East Region",
    "district": "West Mamprusi",
    "code": "MW"
  },
  {
    "region": "North East Region",
    "district": "Yunyoo Nasuan",
    "code": "MY"
  },
  {
    "region": "Northern Region",
    "district": "Gushiegu",
    "code": "NG"
  },
  {
    "region": "Northern Region",
    "district": "Karaga",
    "code": "NR"
  },
  {
    "region": "Northern Region",
    "district": "Kpandai",
    "code": "NA"
  },
  {
    "region": "Northern Region",
    "district": "Kumbungu",
    "code": "NK"
  },
  {
    "region": "Northern Region",
    "district": "Mion",
    "code": "NI"
  },
  {
    "region": "Northern Region",
    "district": "Nanton",
    "code": "NU"
  },
  {
    "region": "Northern Region",
    "district": "Nanumba North",
    "code": "NN"
  },
  {
    "region": "Northern Region",
    "district": "Nanumba South",
    "code": "NO"
  },
  {
    "region": "Northern Region",
    "district": "Saboba",
    "code": "NX"
  },
  {
    "region": "Northern Region",
    "district": "Sagnerigu",
    "code": "NS"
  },
  {
    "region": "Northern Region",
    "district": "Savelugu",
    "code": "NV"
  },
  {
    "region": "Northern Region",
    "district": "Tamale",
    "code": "NT"
  },
  {
    "region": "Northern Region",
    "district": "Tatale Sangule",
    "code": "NF"
  },
  {
    "region": "Northern Region",
    "district": "Tolon",
    "code": "NL"
  },
  {
    "region": "Northern Region",
    "district": "Yendi",
    "code": "NY"
  },
  {
    "region": "Northern Region",
    "district": "Zabzugu",
    "code": "NZ"
  },
  {
    "region": "Oti Region",
    "district": "Biakoye",
    "code": "OB"
  },
  {
    "region": "Oti Region",
    "district": "Guan",
    "code": "OG"
  },
  {
    "region": "Oti Region",
    "district": "Jasikan",
    "code": "OJ"
  },
  {
    "region": "Oti Region",
    "district": "Kadjebi",
    "code": "OK"
  },
  {
    "region": "Oti Region",
    "district": "Krachi East",
    "code": "OE"
  },
  {
    "region": "Oti Region",
    "district": "Krachi Nchumuru",
    "code": "OQ"
  },
  {
    "region": "Oti Region",
    "district": "Krachi West",
    "code": "OW"
  },
  {
    "region": "Oti Region",
    "district": "Nkwanta North",
    "code": "ON"
  },
  {
    "region": "Oti Region",
    "district": "Nkwanta South",
    "code": "OS"
  },
  {
    "region": "Savannah Region",
    "district": "BOLE",
    "code": "SB"
  },
  {
    "region": "Savannah Region",
    "district": "CENTRAL GONJA",
    "code": "SG"
  },
  {
    "region": "Savannah Region",
    "district": "EAST GONJA",
    "code": "SE"
  },
  {
    "region": "Savannah Region",
    "district": "North East Gonja",
    "code": "SJ"
  },
  {
    "region": "Savannah Region",
    "district": "NORTH GONJA",
    "code": "SN"
  },
  {
    "region": "Savannah Region",
    "district": "Sawla Tuna Kalba",
    "code": "SS"
  },
  {
    "region": "Savannah Region",
    "district": "WEST GONJA",
    "code": "SW"
  },
  {
    "region": "Upper East Region",
    "district": "Bawku",
    "code": "UA"
  },
  {
    "region": "Upper East Region",
    "district": "Bawku West",
    "code": "UW"
  },
  {
    "region": "Upper East Region",
    "district": "Binduri",
    "code": "UU"
  },
  {
    "region": "Upper East Region",
    "district": "Bolgatanga",
    "code": "UB"
  },
  {
    "region": "Upper East Region",
    "district": "Bolgatanga East",
    "code": "UE"
  },
  {
    "region": "Upper East Region",
    "district": "Bongo",
    "code": "UO"
  },
  {
    "region": "Upper East Region",
    "district": "Builsa North",
    "code": "UR"
  },
  {
    "region": "Upper East Region",
    "district": "Builsa South",
    "code": "US"
  },
  {
    "region": "Upper East Region",
    "district": "Garu",
    "code": "UG"
  },
  {
    "region": "Upper East Region",
    "district": "Kassena Nankana East",
    "code": "UK"
  },
  {
    "region": "Upper East Region",
    "district": "Kassena Nankana West",
    "code": "UL"
  },
  {
    "region": "Upper East Region",
    "district": "Nabdam",
    "code": "UN"
  },
  {
    "region": "Upper East Region",
    "district": "Pusiga",
    "code": "UP"
  },
  {
    "region": "Upper East Region",
    "district": "Talensi",
    "code": "UT"
  },
  {
    "region": "Upper East Region",
    "district": "Tempane",
    "code": "UM"
  },
  {
    "region": "Upper West Region",
    "district": "Daffiama Bussie Issa",
    "code": "XD"
  },
  {
    "region": "Upper West Region",
    "district": "Jirapa",
    "code": "XJ"
  },
  {
    "region": "Upper West Region",
    "district": "Lambussie Karni",
    "code": "XK"
  },
  {
    "region": "Upper West Region",
    "district": "Lawra",
    "code": "XL"
  },
  {
    "region": "Upper West Region",
    "district": "Nadowli Kaleo",
    "code": "XO"
  },
  {
    "region": "Upper West Region",
    "district": "Nandom",
    "code": "XN"
  },
  {
    "region": "Upper West Region",
    "district": "Sissala East",
    "code": "XS"
  },
  {
    "region": "Upper West Region",
    "district": "Sissala West",
    "code": "XT"
  },
  {
    "region": "Upper West Region",
    "district": "Wa",
    "code": "XW"
  },
  {
    "region": "Upper West Region",
    "district": "Wa East",
    "code": "XX"
  },
  {
    "region": "Upper West Region",
    "district": "Wa West",
    "code": "XY"
  },
  {
    "region": "Volta Region",
    "district": "Adaklu",
    "code": "VA"
  },
  {
    "region": "Volta Region",
    "district": "Afadjato South",
    "code": "VF"
  },
  {
    "region": "Volta Region",
    "district": "Agotime Ziope",
    "code": "VG"
  },
  {
    "region": "Volta Region",
    "district": "Akatsi North",
    "code": "VW"
  },
  {
    "region": "Volta Region",
    "district": "Akatsi South",
    "code": "VX"
  },
  {
    "region": "Volta Region",
    "district": "Anloga",
    "code": "VN"
  },
  {
    "region": "Volta Region",
    "district": "Central Tongu",
    "code": "VV"
  },
  {
    "region": "Volta Region",
    "district": "Ho",
    "code": "VH"
  },
  {
    "region": "Volta Region",
    "district": "Ho West",
    "code": "VI"
  },
  {
    "region": "Volta Region",
    "district": "Hohoe",
    "code": "VC"
  },
  {
    "region": "Volta Region",
    "district": "Keta",
    "code": "VK"
  },
  {
    "region": "Volta Region",
    "district": "Ketu North",
    "code": "VY"
  },
  {
    "region": "Volta Region",
    "district": "Ketu South",
    "code": "VZ"
  },
  {
    "region": "Volta Region",
    "district": "Kpando",
    "code": "VP"
  },
  {
    "region": "Volta Region",
    "district": "North Dayi",
    "code": "VD"
  },
  {
    "region": "Volta Region",
    "district": "North Tongu",
    "code": "VT"
  },
  {
    "region": "Volta Region",
    "district": "South Dayi",
    "code": "VE"
  },
  {
    "region": "Volta Region",
    "district": "South Tongu",
    "code": "VU"
  },
  {
    "region": "Western Region",
    "district": "Ahanta West",
    "code": "WH"
  },
  {
    "region": "Western Region",
    "district": "Effia Kwesimintim",
    "code": "WK"
  },
  {
    "region": "Western Region",
    "district": "Ellembelle",
    "code": "WE"
  },
  {
    "region": "Western Region",
    "district": "Jomoro",
    "code": "WJ"
  },
  {
    "region": "Western Region",
    "district": "Mpohor",
    "code": "WM"
  },
  {
    "region": "Western Region",
    "district": "Nzema East",
    "code": "WN"
  },
  {
    "region": "Western Region",
    "district": "Prestea Huni Valley",
    "code": "WP"
  },
  {
    "region": "Western Region",
    "district": "SekondI-Takoradi",
    "code": "WS"
  },
  {
    "region": "Western Region",
    "district": "Shama",
    "code": "WR"
  },
  {
    "region": "Western Region",
    "district": "Tarkwa Nsuaem",
    "code": "WT"
  },
  {
    "region": "Western Region",
    "district": "Wassa Amenfi Central",
    "code": "WW"
  },
  {
    "region": "Western Region",
    "district": "Wassa Amenfi East",
    "code": "WX"
  },
  {
    "region": "Western Region",
    "district": "Wassa Amenfi West",
    "code": "WY"
  },
  {
    "region": "Western Region",
    "district": "Wassa East",
    "code": "WZ"
  },
  {
    "region": "Western North Region",
    "district": "Aowin",
    "code": "YA"
  },
  {
    "region": "Western North Region",
    "district": "Bia East",
    "code": "YE"
  },
  {
    "region": "Western North Region",
    "district": "Bia West",
    "code": "YW"
  },
  {
    "region": "Western North Region",
    "district": "Bibiani Anhwiaso Bekwai",
    "code": "YB"
  },
  {
    "region": "Western North Region",
    "district": "Bodi",
    "code": "YD"
  },
  {
    "region": "Western North Region",
    "district": "Juaboso",
    "code": "YJ"
  },
  {
    "region": "Western North Region",
    "district": "Sefwi Akontombra",
    "code": "YK"
  },
  {
    "region": "Western North Region",
    "district": "Sefwi Wiawso",
    "code": "YS"
  },
  {
    "region": "Western North Region",
    "district": "Suaman",
    "code": "YU"
  }
] as const;
