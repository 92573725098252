import { useReactiveVar } from "@apollo/client";
import { FC } from "react";
import { useSearch, MakeGenerics, Navigate, Outlet } from "react-location";
import { currentTokenVar } from 'apollo/cache/auth';

export type AuthLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
  }
}>

const AuthLayout: FC = () => {
  const search = useSearch<AuthLocationGenerics>()
  const currentToken = useReactiveVar(currentTokenVar)

  if (currentToken) {
    // check if theres a token
    // if yes hit server to reauthenticate and redirect to app
    return <Navigate
      to={search?.redirect ?? "/"}
      replace
    />
  }
  return (
    <div className="h-screen flex">
      <div className="flex-1 flex flex-col justify-center items-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24 relative">
        <Outlet />
        <span className="absolute text-sm bottom-8 text-center inset-x-0">&copy; {new Date().getFullYear()} - Powered by <a href="https://www.polymorphlabs.io" target={"_blank"} className="text-primary-700 hover:text-primary-900" rel="noreferrer">Polymorph Labs Ghana Limited</a></span>
      </div>
    </div>
  )
}

export default AuthLayout;