import { FC } from 'react'
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { MapProvider, Marker } from "react-map-gl";
import config from "config";
import mapboxgl from "mapbox-gl";

// @ts-ignore 
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

interface MapViewProps {
  longitude: number;
  latitude: number;
}

const MapView: FC<MapViewProps> = ({ longitude, latitude }) => {

  return (
    <MapProvider>
      <Map
        id="service-location"
        longitude={longitude}
        latitude={latitude}
        zoom={15}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken={config.mapbox.token}
      >
        <Marker
          longitude={longitude}
          latitude={latitude}
        >
          <img
            className="w-6 h-6"
            src={require("assets/logo.png")}
            alt={"pin"}
          />
        </Marker>
      </Map>
    </MapProvider>
  )
}

export default MapView