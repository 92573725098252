import { FC } from "react";
import { SearchSelectInput } from "components/core";
import { PremiseCategory } from "apollo/data";

interface PremiseTypePickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
}


const PremiseTypePickerContainer: FC<PremiseTypePickerContainerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {
  return (
    <SearchSelectInput
      id={id ?? "premiseType"}
      label={label ?? "Premise Type"}
      placeholder="Select Premise Type"
      options={PremiseCategory?.filter((el) => el?.type === "PremiseType")?.map(
        (premiseType: any) => ({
          label: {
            title: premiseType.name as string,
          },
          value: rawId ? premiseType?._id : premiseType,
        })
      )}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default PremiseTypePickerContainer;
