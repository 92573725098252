import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, Loader, StepWizardStatus } from "components";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useState, useMemo } from "react";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { currentConfigVar } from "apollo/cache/config";
import ServiceRequestDetailsContainer from "./service-request-details";
import ContractSigningDetailsContainer from "./contract-signing-details";
import InspectionOrderDetailsContainer from "./inspection-order-details";
import ConnectionChargeDetailsContainer from "./connection-charge-details";
import InstallationOrderDetailsContainer from "./installation-order-details";
import ApplicationChargeDetailsContainer from "./application-charge-details";
import CustomerDetailsContainer from "./customer-details";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { classNames, useUrlState, wrapClick } from "utils";
import ReplacementOrderDetailsContainer from "./replacement-order-details";

const GET_SERVICE_REQUEST = gql`
query GetServiceRequest($id: ID!) {
  serviceRequest: getServiceRequest(id: $id) {
    _id
    code
    category
    type
    status
    origin
    createdAt
    updatedAt
    isBulk
    region {
      _id
      code
      name
    }
    district {
      _id
      code
      name
    }
    subRequests {
      _id
      code
      applicationFee {
        _id
      }
      connectionFee {
        _id
        amount
      }
      inspection {
        _id
      }
      installation {
        _id
      }
      contract {
        contractNumber
        status
        accountNumber
      }
      status
      replacement {
        _id
      }
    }
    request {
      customer {
        customerType

        organization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        representative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
      }
      property {
        owner {
          fullName
          phoneNumber
        }
        ghanaPostAddress
        community
        streetName
        houseNumber
        structureNumber
        landmark
        premiseType {
          _id
          code
          name
        }
        premiseCategory {
          _id
          code
          name
        }
        activity {
          _id
          code
          name
        }
        subActivity {
          _id
          code
          name
        }
        geoLocation {
          type
          coordinates
        }
        sitePlanDocumentUrl
      }
      isExistingProperty
      isExistingCustomer
      service {
        serviceType
        serviceClass
        serviceQuantity
        energyCertificateNumber
        energyCertificateDocumentUrl
        energyCertificateIssuerId
        energyCertificateIssuerName
        energyCertificateIssuerPhone
      }
      existingCustomer {
        _id
        code
      }
      existingProperty {
        _id
        code
      }
    }
    response {
      customer {
        _id
        code
      }
      property {
        _id
        code
      }
    }
  }
}

`;

const serviceRequestSteps = [
  {
    name: "Service Reqeust",
    description: "",
    Component: ServiceRequestDetailsContainer,
    failedStatus: "Rejected",
  },
  {
    name: "Application Charge",
    description: "",
    Component: ApplicationChargeDetailsContainer,
  },
  {
    name: "Inspection Order",
    description: "",
    Component: InspectionOrderDetailsContainer,
    failedStatus: "InspectionFailed",
  },
  {
    name: "Contract Signing",
    description: "",
    Component: ContractSigningDetailsContainer,
  },
  {
    name: "Connection Charge",
    description: "",
    Component: ConnectionChargeDetailsContainer,
  },
  {
    name: "Installation Order",
    description: "",
    Component: InstallationOrderDetailsContainer,
    failedStatus: "InstallationFailed",
  },
  {
    name: "Customer Details",
    description: "",
    Component: CustomerDetailsContainer,
  },
];

const updateItem = (array: any[], condition: (item: any) => boolean, updateFunction: (item: any) => void): any[] => {
  const index = array.findIndex(condition);

  if (index !== -1) {
    _.update(array, `[${index}]`, updateFunction);
  }
  return array;
};
const processStep = (type: string) => (data: any[]) => {
  if (type === "AdditionalLoadRequest") {
    return updateItem(data, (item) => item.name === "Installation Order", (item) => ({
      ...item,
      name: "Replacement Order",
      Component: ReplacementOrderDetailsContainer,
    }));
  }

  return data;
}

const ServiceRequestPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);
  const [selectedSubRequestIndex, setSelectedSubRequestIndex] =
    useUrlState<number>("subrequest");

  const { params } = useMatch<LocationGenerics>();

  const { data, loading, refetch } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: params.serviceRequest,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  const filteredFormSteps = useMemo(
    () => processStep(data?.serviceRequest?.type ?? "")(serviceRequestSteps.filter((_stpe) => true)),
    [data]
  );

  const steps = useMemo(
    () => [...filteredFormSteps].map((rawStep, href) => ({ ...rawStep, href })),
    [filteredFormSteps]
  );

  const selectedSubRequest = useMemo(
    () => data?.serviceRequest?.subRequests?.[selectedSubRequestIndex - 1],
    [selectedSubRequestIndex, data?.serviceRequest]
  );

  useEffect(() => {
    switch (selectedSubRequest?.status) {
      case "ApplicationFeeGenerated":
      case "ApplicationFeePaid": {
        setStep(1);
        setLastStep(1);
        break;
      }
      case "InspectionInitiated":
      case "InspectionFailed":
      case "InspectionCompleted": {
        setStep(2);
        setLastStep(2);
        break;
      }
      case "ContractGenerated":
      case "ContractSigned": {
        setStep(3);
        setLastStep(3);
        break;
      }
      case "ConnectionFeeGenerated":
      case "ConnectionFeePaid": {
        setStep(4);
        setLastStep(4);
        break;
      }
      case "InstallationInitiated":
      case "InstallationCompleted":
      case "InstallationFailed": {
        setStep(5);
        setLastStep(5);
        break;
      }
      case "Completed": {
        setStep(6);
        setLastStep(6);
        break;
      }
      default:
        setStep(0);
        setLastStep(0);
        break;
    }
  }, [selectedSubRequest?.status, selectedSubRequest?._id]);

  useEffect(() => {
    if (!selectedSubRequestIndex) {
      setSelectedSubRequestIndex(1);
    }
  }, []);

  const prevRequest = () => {
    setSelectedSubRequestIndex(
      _.max([1, (selectedSubRequestIndex || 1) - 1]) as number
    );
  };

  const nextRequest = () => {
    setSelectedSubRequestIndex(
      _.min([
        data?.serviceRequest?.subRequests?.length,
        (selectedSubRequestIndex || 1) + 1,
      ]) as number
    );
  };

  const renderView = () => {
    const { Component } = steps[step || 0];
    return (
      <Component
        parentValue={data?.serviceRequest}
        values={selectedSubRequest}
        id={_.get(data?.serviceRequest, "_id")}
        code={_.get(selectedSubRequest, "code")}
        status={_.get(
          selectedSubRequest,
          "status",
          data?.serviceRequest?.status
        )}
        createdAt={_.get(selectedSubRequest, "createdAt")}
        updatedAt={_.get(selectedSubRequest, "updatedAt")}
        refetch={refetch}
      />
    );
  };

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <Header />
      <div className='flex flex-1 overflow-hidden'>
        {loading ? (
          <Loader />
        ) : (
          <div className='bg-gray-100 shadow-xl flex-1 flex p-8 overflow-hidden relative'>
            <div className='w-1/3 flex flex-col space-y-6 overflow-y-auto no-scrollbar'>
              <div className='p-6 border border-dashed border-gray-300 rounded grid grid-cols-2 gap-6 mr-12'>
                <div>
                  <span className='block text-md font-light text-gray-700'>
                    Service Request Code
                  </span>
                  <div className='mt-1 block w-full text-md'>
                    {data?.serviceRequest?.code || "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-md font-light text-gray-700'>
                    Service Request Date
                  </span>
                  <div className='mt-1 block w-full text-md'>
                    {data?.serviceRequest?.createdAt
                      ? moment(data?.serviceRequest?.createdAt).format(
                        dateFormat
                      )
                      : "N/A"}
                  </div>
                </div>
              </div>
              {data?.serviceRequest?.isBulk && (
                <div className='p-3 px-6 border border-dashed border-gray-300 bg-white rounded grid grid-cols-2 gap-4 mr-12'>
                  <div>
                    <span className='block text-md font-light text-gray-700'>
                      Sub Request Code
                    </span>
                    <div className='mt-1 block w-full text-md'>
                      {selectedSubRequest?.code || "N/A"}
                    </div>
                  </div>
                  <div className=''>
                    <span className='block text-md font-light text-gray-700'>
                      Sub Request Status
                    </span>
                    <div className='mt-1 block w-full text-md'>
                      {_.startCase(selectedSubRequest?.status) || "N/A"}
                    </div>
                  </div>
                  <div className='col-span-2 flex items-center justify-between'>
                    <div>
                      <p className='text-sm text-gray-700'>
                        Request{" "}
                        <span className='font-medium'>
                          {selectedSubRequestIndex}
                        </span>{" "}
                        of{" "}
                        <span className='font-medium'>
                          {data?.serviceRequest?.subRequests?.length}
                        </span>
                      </p>
                    </div>
                    <span className='isolate inline-flex rounded-md shadow-sm'>
                      <button
                        type='button'
                        onClick={wrapClick(prevRequest)}
                        disabled={(selectedSubRequestIndex || 1) === 1}
                        className={classNames(
                          (selectedSubRequestIndex || 1) === 1
                            ? "cursor-notallowed bg-gray-100"
                            : "bg-white hover:bg-gray-50",
                          "relative inline-flex items-center rounded-l-md  px-1.5 pr-2 py-1.5 text-gray-700 ring-1 ring-inset ring-gray-300"
                        )}
                      >
                        <ChevronLeftIcon className='h-5 w-5' />
                        <span className='text-sm'>Prev</span>
                      </button>
                      <button
                        type='button'
                        onClick={wrapClick(nextRequest)}
                        disabled={
                          data?.serviceRequest?.subRequests?.length ===
                          (selectedSubRequestIndex || 1)
                        }
                        className={classNames(
                          data?.serviceRequest?.subRequests?.length ===
                            (selectedSubRequestIndex || 1)
                            ? "cursor-notallowed bg-gray-100"
                            : "bg-white hover:bg-gray-50",
                          "relative -ml-px inline-flex flex-row-reverse items-center rounded-r-md  px-1.5 pl-2 py-1.5 text-gray-700 ring-1 ring-inset ring-gray-300"
                        )}
                      >
                        <ChevronRightIcon className='h-5 w-5' />
                        <span className='text-sm'>Next</span>
                      </button>
                    </span>
                  </div>
                </div>
              )}

              <StepWizardStatus
                steps={steps}
                step={step}
                setStep={setStep}
                lastStep={lastStep}
                status={_.get(
                  selectedSubRequest,
                  "status",
                  data?.serviceRequest?.status
                )}
              />
            </div>
            <div className='flex flex-1 overflow-hidden bg-white rounded-lg'>
              {renderView()}
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default ServiceRequestPage;
