import { useReactiveVar } from "@apollo/client";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { currentUserVar } from "apollo/cache/auth";
import { RichInput, TextArea, TextInput, UploadBox } from "components";
import {
  ComplaintCategoryPicker,
  ComplaintTypePicker,
  DistrictPicker,
  RegionPicker,
} from "containers";
import { FormikProps } from "formik";
import { FC } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";

type Props = {
  form: FormikProps<any>;
};

const TicketForm: FC<Props> = ({ form }) => {
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const inCompleteCustomer =
    !form.values?.contactPersonInfo?.fullName ||
    !form.values?.contactPersonInfo?.phoneNumber;

  const currentUser = useReactiveVar(currentUserVar);

  return (
    <div className='space-y-6 divide-y no-scrollbar divide-gray-200 flex-1 overflow-y-auto'>
      {/* {JSON.stringify(form.values, null, 2)} */}
      {inCompleteCustomer &&
        form.values.complaintInfo?.type &&
        form.values.complaintInfo?.type?.requiresServicePoint && (
          <div className='rounded-md bg-yellow-50 p-4'>
            <div className='flex'>
              <div className='flex-shrink-0'>
                <ExclamationTriangleIcon
                  className='h-5 w-5 text-yellow-400'
                  aria-hidden='true'
                />
              </div>
              <div className='ml-3'>
                <h3 className='text-sm font-medium text-yellow-800'>
                  Attention needed
                </h3>
                <div className='mt-2 text-sm text-yellow-700'>
                  <p>
                    Ensure customer information is updated before proceeding, as
                    it is necessary to create a ticket.
                  </p>
                </div>
                <div className='mt-3'>
                  <button
                    onClick={() =>
                      navigate({
                        search(prev) {
                          return {
                            ...prev,
                            modal: "update",
                            id: searchParams.id,
                          };
                        },
                      })
                    }
                    type='button'
                    className='rounded-md  bg-yellow-100 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50'
                  >
                    Click to Update customer
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      <div className='pt-6'>
        <span className='text-xs font-light'>Complaint type information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div className='space-y-1'>
            <ComplaintCategoryPicker
              id='complaintInfo.category'
              label='Complaint Category'
              required
              {...form}
            />
          </div>
          <div>
            <ComplaintTypePicker
              id='complaintInfo.type'
              label='Complaint Type'
              required
              filter={{
                category: form.values?.complaintInfo?.category?.name,
                ...(!form.values?.customerInfo?.existingServicePoint
                  ? { requiresServicePoint: false }
                  : {}),
              }}
              {...form}
            />
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Location information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          <div>
            {!form.values?.customerInfo?.existingServicePoint && (
              <RegionPicker
                id='customerInfo.region'
                label='Region'
                placeholder='e.g. Accra East Region'
                disabled={!!currentUser?.region?._id}
                required={true}
                rawId
                {...form}
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue(field, value);
                }}
              />
            )}
          </div>

          {form.values.customerInfo?.region && (
            <div>
              <DistrictPicker
                id='customerInfo.district'
                label='District'
                placeholder='e.g. Makola District'
                required={true}
                disabled={!!currentUser?.district?._id}
                filter={{ region: form.values.customerInfo?.region }}
                {...form}
                rawId
                setFieldValue={(field: string, value: string) => {
                  form.setFieldValue(field, value);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <span className='text-xs font-light'>Contact Person Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div className='col-span-3'>
            <TextInput
              id='contactPersonInfo.fullName'
              label='Full Name'
              type='text'
              required
              disabled={
                !form.values.complaintInfo?.type ||
                form.values.complaintInfo?.type?.requiresServicePoint
              }
              placeholder='e.g. Emmanuel Baidoo'
              {...form}
            />
          </div>

          <div className='col-span-2'>
            <TextInput
              id='contactPersonInfo.emailAddress'
              label='Email Address'
              type='text'
              disabled={
                !form.values.complaintInfo?.type ||
                form.values.complaintInfo?.type?.requiresServicePoint
              }
              placeholder='e.g. ebaidoo@office.com'
              {...form}
            />
          </div>
          <div className=''>
            <TextInput
              id='contactPersonInfo.phoneNumber'
              label='Phone Number'
              type='text'
              disabled={
                !form.values.complaintInfo?.type ||
                form.values.complaintInfo?.type?.requiresServicePoint
              }
              placeholder='e.g. 0506339153'
              required
              {...form}
            />
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Contract Information</span>
        <div className='grid grid-cols-2 gap-6 mt-2'>
          {form.values.complaintInfo?.type?.description && (
            <div className='rounded-md bg-yellow-50 p-4 col-span-2'>
              <div className='flex'>
                <div className='flex-shrink-0'>
                  <InformationCircleIcon
                    className='h-5 w-5 text-yellow-400'
                    aria-hidden='true'
                  />
                </div>
                <div className='ml-3'>
                  <div className='mt-2 text-sm text-yellow-700'>
                    <p>{form.values.complaintInfo?.type?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='col-span-2 mb-4'>
            <RichInput
              id='complaintInfo.description'
              label='Description'
              placeholder='e.g. Connected the cables underground from next door'
              {...form}
            />
          </div>
          {/* {JSON.stringify(form.values.complaintInfo?.supportingDocumentUrls, null, 2)} */}
          <div className='col-span-2'>
            <UploadBox
              label='Supporting document'
              required={form.values.complaintInfo?.type?.requiresDocument}
              multiple
              id={"complaintInfo.supportingDocumentUrls"}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketForm;
