
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBTGBGla7V3tlFC96JvBMLtYAsfzpRZ1x0",
  authDomain: "zeus-billing.firebaseapp.com",
  projectId: "zeus-billing",
  storageBucket: "zeus-billing.appspot.com",
  messagingSenderId: "358019425210",
  appId: "1:358019425210:web:04dba1ea532926a44bb383",
  measurementId: "G-S23L09HEM1",
  vapidKey: "BA9aKg6CAoMs6JcqxuySkW3l2RTXuxAGjsneq7cJiz7Z6jULjgpHvcRX5bJfed-lrNV3-ZG7h_Y_9iFi27_AMDU",
};

// Initialize Firebase
export default firebaseConfig;