import { useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_CUSTOMER } from "./view";
import UpdateCustomerForm from "pages/customers/forms/update-customer";
import { withPermissions, wrapClick } from "utils";
import * as Yup from "yup";

const UPDATE_CUSTOMER = gql`
mutation UpdateServicePointContact(
  $updateServicePointContactId: ID!
  $primaryPhoneNumber: String!
  $primaryEmailAddress: String
  $phoneNumbers: [String]
  $emailAddresses: [String]
  $payer: PayerInput
  $reason: String
) {
  updateServicePointContact(
    id: $updateServicePointContactId
    primaryPhoneNumber: $primaryPhoneNumber
    primaryEmailAddress: $primaryEmailAddress
    phoneNumbers: $phoneNumbers
    emailAddresses: $emailAddresses
    payer: $payer
    reason: $reason
  ) {
    _id
  }
}

`;

export type CustomerContactFormVariables = {
  primaryPhoneNumber: string;
  primaryEmailAddress: string;
  fullName: string;
  phoneNumbers: string[];
  payer: {
    primaryPhoneNumber: string;
    primaryEmailAddress: string;
    fullName: string;
    phoneNumbers: string[];
  };
  reason: string;
}

export default function UpdateCustomerContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      getServicePointId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const form = useFormik<CustomerContactFormVariables>({
    initialValues: {
      primaryPhoneNumber: "",
      primaryEmailAddress: "",
      fullName: "",
      phoneNumbers: [],
      payer: {
        primaryPhoneNumber: "",
        primaryEmailAddress: "",
        fullName: "",
        phoneNumbers: [],
      },
      reason: "",
    },
    validationSchema: Yup.object().shape({
      primaryPhoneNumber: Yup.string().required("Phone Number is Required"),
      primaryEmailAddress: Yup.string().notRequired(),
      fullName: Yup.string().required("Full Name is Required"),
      reason: Yup.string().required("Reason is Required"),
      payer: Yup.object().shape({
        primaryPhoneNumber: Yup.string().required("Phone Number is Required"),
        primaryEmailAddress: Yup.string().notRequired(),
        fullName: Yup.string().required("Full Name is Required"),
        phoneNumbers: Yup.array().of(Yup.string().notRequired()),
      }),
    }),
    onSubmit: async (values) => {
      await updateCustomer({
        variables: {
          updateServicePointContactId: data?.customer?._id,
          fullName: values.fullName,
          primaryPhoneNumber: values.primaryPhoneNumber,
          primaryEmailAddress: values.primaryEmailAddress,
          phoneNumbers: values.phoneNumbers,
          reason: values.reason,
          payer: values.payer
        },
      }).then(({ data }) => {
        if (data.updateServicePointContact._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Contact Edited Successfully",
            })
          );
          form.resetForm();
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Customer Contact",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.customer) {
      form.setValues({
        fullName: data?.customer?.customerRepresentative?.fullName,
        primaryPhoneNumber: data?.customer?.customerRepresentative?.phoneNumber || "",
        primaryEmailAddress: data?.customer?.customerRepresentative?.emailAddress || "",
        phoneNumbers: data?.customer?.customerRepresentative?.extraPhoneNumbers || [],
        payer: {
          fullName: data?.customer?.payer?.fullName,
          primaryPhoneNumber: data?.customer?.payer?.primaryPhoneNumber || "",
          primaryEmailAddress: data?.customer?.payer?.primaryEmailAddress || "",
          phoneNumbers: data?.customer?.payer?.phoneNumbers || [],
        },
        reason: "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customer, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      size='4xl'
      title='Edit Customer Contact Information'
      description='Provide the details to update customer contact information'
      renderActions={() => (
        <>
          {withPermissions(["*:*", "customers:*", "customers:update"])(
            <button
              type='button'
              disabled={form.isSubmitting}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 disabled:grayscale focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(form.handleSubmit)}
            >
              {form.isSubmitting ? "Saving Changes ..." : "Save Changes"}
            </button>
          )}
        </>
      )}
    >
      {data?.customer?._id && (
        <UpdateCustomerForm form={form} customer={data?.customer} />
      )}
    </Modal>
  );
}
