import { useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_CUSTOMER } from "./view";
import { withPermissions, wrapClick } from "utils";
import UpdateCustomerLocationForm from "./forms/update-customer-location";

const UPDATE_CUSTOMER = gql`
 mutation UpdateServicePointContact(
  $updateServicePointContactId: ID!
  $fullName: String!
  $primaryPhoneNumber: String!
  $primaryEmailAddress: String!
  $reason: String
) {
  updateServicePointContact(
    id: $updateServicePointContactId
    fullName: $fullName
    primaryPhoneNumber: $primaryPhoneNumber
    primaryEmailAddress: $primaryEmailAddress
    reason: $reason
  ) {
    _id
  }
}

`;

export default function UpdateCustomerLocationContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      getServicePointId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const form = useFormik<{ fullName: string; primaryPhoneNumber: string, primaryEmailAddress: string, reason: string }>({
    initialValues: {
      primaryPhoneNumber: "",
      primaryEmailAddress: "",
      fullName: "",
      reason: "",
    },
    // validationSchema: Yup.object().shape({
    //   primaryPhoneNumber: Yup.string().required("Phone Number is Required"),
    //   primaryEmailAddress: Yup.string().required("Email is Required"),
    //   fullName: Yup.string().required("Full Name is Required"),
    //   reason: Yup.string().required("Reason is Required"),
    // }),
    onSubmit: async (values) => {
      await updateCustomer({
        variables: {
          updateServicePointContactId: data?.customer?._id,
          fullName: values.fullName,
          primaryPhoneNumber: values.primaryPhoneNumber,
          primaryEmailAddress: values.primaryEmailAddress,
          reason: values.reason,
        },
      }).then(({ data }) => {
        if (data.updateServicePointContact._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer Contact Edited Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Customer Contact",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.customer) {
      // alert("Hello" + data?.customer?.customer?.fullName)
      form.setValues({
        fullName: data?.customer?.customerRepresentative?.fullName,
        primaryPhoneNumber: data?.customer?.customerRepresentative?.phoneNumber || "",
        primaryEmailAddress: data?.customer?.customerRepresentative?.emailAddress || "",
        reason: "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customer, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      // hideActions={true}
      loading={loading}
      size='4xl'
      title='Edit Customer Location Information'
      description='Provide the details to update customer location information'
      renderActions={() => (
        <>
          {withPermissions(["*:*", "service-points:*", "service-points:update"])(
            <button
              type='button'
              disabled={form.isSubmitting || !form.isValid}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(form.handleSubmit)}
            >
              {form.isSubmitting ? "Saving Changes ..." : "Save Changes"}
            </button>
          )}
        </>
      )}
    >
      {/* {JSON.stringify(form?.errors, null, 2)} */}
      {data?.customer?._id && (
        <UpdateCustomerLocationForm form={form} />
      )}
    </Modal>
  );
}
