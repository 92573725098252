import { wrapClick } from "utils";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { ApproveServiceOrderForm, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import * as Yup from "yup";
import lodash from "lodash";
import { ServiceClasses, ServiceTypes } from "apollo/data";

const APPROVE_SERVICE_ORDER = gql`
mutation ApproveServiceRequest(
  $approveServiceRequestId: ID!
  $reason: String
  $serviceType: ServiceType!
  $serviceClass: ServiceClass!
  $premiseCategory: ID!
  $premiseType: ID!
  $subActivity: ID!
  $activity: ID!
  $energyCertificateNumber: String!
  $energyCertificateDocumentUrl: String!
  $energyCertificateIssuerId: String
  $energyCertificateIssuerName: String
  $energyCertificateIssuerPhone: String
) {
  approveServiceRequest(
    id: $approveServiceRequestId
    reason: $reason
    serviceType: $serviceType
    serviceClass: $serviceClass
    premiseCategory: $premiseCategory
    premiseType: $premiseType
    subActivity: $subActivity
    activity: $activity
    energyCertificateNumber: $energyCertificateNumber
    energyCertificateDocumentUrl: $energyCertificateDocumentUrl
    energyCertificateIssuerId: $energyCertificateIssuerId
    energyCertificateIssuerName: $energyCertificateIssuerName
    energyCertificateIssuerPhone: $energyCertificateIssuerPhone
  ) {
    _id
  }
}

`


export type ApproveServiceOrderFormSchema = {
    premiseType: {
        _id: string;
        code: string;
        name: string;
    } | null;
    premiseCategory: {
        _id: string;
        code: string;
        name: string;
    } | null;
    activity: {
        _id: string;
        code: string;
        name: string;
    } | null;
    subActivity: {
        _id: string;
        code: string;
        name: string;
    } | null;
    serviceType: string;
    serviceClass: string;
    energyCertificateNumber: string;
    energyCertificateIssuerPhone: string;
    energyCertificateDocumentUrl: string;
    reason: string;
}

const validationSchema = Yup.object().shape({
    serviceType: Yup.string().oneOf(lodash.map(ServiceTypes, "value")).required(),
    serviceClass: Yup.string()
        .oneOf(lodash.map(ServiceClasses, "value"))
        .required(),
    energyCertificateNumber: Yup.string().required(
        "Energy certificate number is required"
    ),
    energyCertificateDocumentUrl: Yup.string()
        .url()
        .required("Kindly attach energy certificate document"),
    premiseCategory: Yup.object().typeError("Premise category is required").required("Premise category is required"),
    premiseType: Yup.object().typeError("Premise type is required").required("Premise type is required"),
    subActivity: Yup.object().typeError("Sub activity is required").required("Sub activity is required"),
    activity: Yup.object().typeError("Activity is required").required("Activity is required"),
});

export default function ApproveServiceOrderContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
    const searchParams = useSearch<LocationGenerics>();
    const [approveReplacementServiceOrder] = useMutation(APPROVE_SERVICE_ORDER, {
        refetchQueries: ["GetServiceRequest"]
    })
    const form = useFormik<ApproveServiceOrderFormSchema>({
        initialValues: {
            reason: "",
            serviceType: "",
            serviceClass: "",
            premiseCategory: null,
            premiseType: null,
            subActivity: null,
            activity: null,
            energyCertificateNumber: "",
            energyCertificateIssuerPhone:"",
            energyCertificateDocumentUrl: "",
        },
        validationSchema,
        onSubmit: async (values) => {
            await approveReplacementServiceOrder({
                variables: {
                    approveServiceRequestId: searchParams.id,
                    ...values,
                    premiseType: values.premiseType?._id,
                    premiseCategory: values.premiseCategory?._id,
                    activity: values.activity?._id,
                    subActivity: values.subActivity?._id,
                }
            }).then(({ data }) => {
                if (data.approveServiceRequest._id) {
                    toast(JSON.stringify({ type: "success", title: "Service Request Approved Successfully" }))
                    refetch?.();
                    setOpen(false)
                }
                else {
                    toast(JSON.stringify({ type: "error", title: "Could not approve Service Request" }))
                }
            })
        },
        onReset: () => {
            setOpen(false)
        }
    })



    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Approve Service Request"
            size="5xl"
            description="Provide notes for approving service request"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={form.isSubmitting}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {form.isSubmitting ? "Approving Service Request..." : "Approve Service Request"}
                    </button>
                </>
            )}>
            <ApproveServiceOrderForm
                form={form}
            />
        </Modal>
    )
}