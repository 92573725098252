import { FC } from "react"
import { ICreateServiceRequestFormSchema } from "./schema"
import _ from "lodash"
import { wrapImage } from "utils"

const ContractInfoSummary: FC<{ data: ICreateServiceRequestFormSchema["serviceInfo"] }> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className=''>
      <span className='text-xs font-light'>Service Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Service Type
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.serviceType || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Service Class
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.serviceClass ? _.startCase(data.serviceClass) : "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className='pt-6'>
      <span className='text-xs font-light'>Energy Certification Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Energy Certificate Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.energyCertificateNumber || "N/A"}
          </div>
        </div>
        <div className="col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Energy Certificate Document
          </span>
          <div className="mt-1 block sm:text-sm">
            {data?.energyCertificateDocumentUrl ? (
          <>
            {wrapImage(
              <img
                src={data?.energyCertificateDocumentUrl}
                alt={data?.energyCertificateNumber}
                className="w-full  h-64 object-cover object-top text-xs"
              />
            )}
            </>
            ) : (
              <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                Energy certificate not provided
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ContractInfoSummary