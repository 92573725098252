import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useEffect } from "react";
import * as yup from "yup";
import TicketForm from "pages/customers/forms/create-ticket";

const CREATE_COMPLAINT = gql`
  mutation CreateComplaint(
    $type: ID!
    $description: String
    $supportingDocumentUrls: [String]
    $servicePoint: ID
    $region: ID
    $district: ID
    $contactPerson: ContactPersonInput
    $meta: ComplaintMetaInput #   $contactPerson: ContactPersonInput #   $data: ComplaintDataInput
  ) {
    createComplaint(
      type: $type
      description: $description
      supportingDocumentUrls: $supportingDocumentUrls
      servicePoint: $servicePoint
      region: $region
      district: $district
      meta: $meta
      contactPerson: $contactPerson
    ) {
      _id
    }
  }
`;

const validationSchema = yup.object().shape({
  complaintInfo: yup.object().shape({
    type: yup
      .object()
      .nullable()
      .required("Required")
      .when("requiresDocument", {
        is: true,
        then: yup.object().required("Required"),
        otherwise: yup.object(),
      }),
    category: yup.object().nullable().required("Required"),
    supportingDocumentUrls: yup
      .array(yup.string())
      .when(["type", "type.requiresDocument"], {
        is: (_: any, otherValue: boolean) => {
          return otherValue === true;
        },
        then: (schema) =>
          schema
            .min(1, "Atleast an image or document is required")
            .required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
  }),
});

export default function CreateTicketContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [createComplaint] = useMutation(CREATE_COMPLAINT);

  const form = useFormik<any>({
    initialValues: {
      customerInfo: {
        existingServicePoint: null,
        servicePoint: null,
        district: searchParams.district,
        region: searchParams.region,
      },
      contactPersonInfo: {
        emailAddress: "",
        fullName: "",
        phoneNumber: "",
      },
      complaintInfo: {
        category: "",
        type: null,
        description: "",
        supportingDocumentUrls: [],
      },
    },
    validationSchema,
    onSubmit: async (values) => {
      await createComplaint({
        variables: {
          ...values.customerInfo,
          ...values.complaintInfo,
          ...values.contractInfo,
          region: values.customerInfo.region,
          district: values.customerInfo.district,
          contactPerson: values.contactPersonInfo,
          type: values.complaintInfo.type?._id,
        },
      }).then(({ data }) => {
        if (data.createComplaint._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Complaint Created Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Complaint",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size={"4xl"}
      title='Add New Ticket'
      description='Create a general complaint'
      renderActions={() => (
        <button
          type='button'
          disabled={form.isSubmitting || !form.isValid}
          onClick={form.submitForm}
          className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-500 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
        >
          {form.isSubmitting ? "Creating..." : "Create Ticket"}
        </button>
      )}
    >
      <TicketForm form={form} />
    </Modal>
  );
}
