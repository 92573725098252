import { FC } from "react";
import { wrapImage } from "utils";
import numeral from "numeral";

import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapProvider, Marker } from "react-map-gl";
import config from "config";
import lodash from "lodash";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";

interface ExceptionDetailsViewProps {
  exception: {
    meta: any;
    type: string;
    code: string;
    description: string;
    value: number;
    imageUrl: string;
  };
  reading: {
    code: string;
    previousReading: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
      totalAmountDueUpdatedAt: Date;
    };
    currentReading: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
      totalAmountDueUpdatedAt: Date;
    };
    meta: {
      readingRecordedAt: any;
      exceptionImageUrl: any;
      exceptionLocation: any;
      exceptionImageLocation: any;
      anomalyLocation: any;
      anomalyImageLocation: any;
      readingImageUrl: string;
      readingLocation: {
        coordinates: [number, number];
      };
      readingImageLocation: {
        coordinates: [number, number];
      };
    };
    bill: {
      outstandingBalance: number;
      totalAmountDue: number;
      consumptionPeriod: number;
      currentBill: {
        readingValue: number;
        consumptionValue: number;
      };
      previousBill: {
        readingValue: number;
        consumptionValue: number;
      };
      currentBillAmount: number;
      bills: {
        currentDate: string;
        previousDate: string;
        tariffInstance: {
          _id: string;
        };
        numberOfDays: number;
        consumption: number;
        billItems: {
          title: string;
          unit: string;
          quantity: number;
          unitCost: number;
          cost: number;
        }[];
        billAmount: number;
      }[];
    };
  };
}

const ExceptionDetailsView: FC<ExceptionDetailsViewProps> = ({
  exception,
  reading,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Reading Information</span>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Reading Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.previousReading?.readingDate
                ? moment(reading.previousReading.readingDate).format(dateFormat)
                : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Reading Value
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(reading?.previousReading?.readingValue || 0).format(
                "0,0"
              )}{" "}
              KWh
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Reading Estimated?
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.previousReading?.readingEstimated ? "Yes" : "No"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Outstanding Amount
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              GHS{" "}
              {numeral(reading?.previousReading?.totalAmountDue || 0).format(
                "0,0.00"
              )}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Current Reading Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.meta?.readingRecordedAt
                ? moment(reading.meta.readingRecordedAt).format(dateFormat)
                : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Current Reading Value
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {exception?.value
                ? `${numeral(exception.value || 0).format("0,0")} KWh`
                : "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Exception Information</span>
        <div className="mt-2 grid grid-cols-4 gap-6">
          <div className="col-span-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Exception Image
              </span>
              {exception?.imageUrl ? (
                <div className="mt-1 block w-full">
                  {wrapImage(
                    <img
                      src={exception?.imageUrl}
                      alt={exception?.code}
                      className="h-64 w-full object-cover object-center"
                    />
                  )}
                </div>
              ) : (
                <div className="mt-1 block w-full sm:text-sm">{"N/A"}</div>
              )}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Exception Location
            </span>
            <div className="mt-1 block w-full sm:text-sm h-64">
              {(exception?.meta?.exceptionLocation?.coordinates?.length > 0 ||
                reading?.meta?.exceptionLocation?.coordinates?.length > 0) && (
                  <MapProvider>
                    <Map
                      id="service-location"
                      initialViewState={{
                        longitude:
                          exception?.meta?.exceptionLocation?.coordinates?.[0] ??
                          reading?.meta?.exceptionLocation?.coordinates?.[0],
                        latitude:
                          exception?.meta?.exceptionLocation?.coordinates?.[1] ??
                          reading?.meta?.exceptionLocation?.coordinates?.[1],
                        zoom: 15,
                      }}
                      mapStyle="mapbox://styles/mapbox/streets-v12"
                      mapboxAccessToken={config.mapbox.token}
                    >
                      <Marker
                        longitude={
                          exception?.meta?.exceptionLocation?.coordinates?.[0] ??
                          reading?.meta?.exceptionLocation?.coordinates?.[0]
                        }
                        latitude={
                          exception?.meta?.exceptionLocation?.coordinates?.[1] ??
                          reading?.meta?.exceptionLocation?.coordinates?.[1]
                        }
                      >
                        <img
                          className="w-6 h-6"
                          src={require("assets/marker.png")}
                          alt={"pin"}
                        />
                      </Marker>
                      <Marker
                        longitude={
                          exception?.meta?.exceptionLocation?.coordinates?.[0] ??
                          reading?.meta?.exceptionLocation?.coordinates?.[0]
                        }
                        latitude={
                          exception?.meta?.exceptionLocation?.coordinates?.[1] ??
                          reading?.meta?.exceptionLocation?.coordinates?.[1]
                        }
                      >
                        <img
                          className="w-6 h-6"
                          src={require("assets/marker.png")}
                          alt={"pin"}
                        />
                      </Marker>
                    </Map>
                  </MapProvider>
                )}
            </div>
          </div>
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Exception Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.startCase(exception?.type) || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Exception Description
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {exception?.description || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExceptionDetailsView;
