import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
  PlusIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { classNames, useUrlState, wrapClick, wrapImage } from "utils";
import parse from "html-react-parser";
import { Avatar } from "components";
import moment from "moment";
import { Menu } from "@headlessui/react";
import { useReactiveVar } from "@apollo/client";
import { currentUserVar } from "apollo/cache/auth";
import { Action } from "components/buttons/action-button";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import ResolveComplaintContainer from "pages/complaints/resolve";
import EscalateComplaintContainer from "pages/complaints/escalate";
import MeterRemovalServiceOrderDetailsContainer from "./service-orders/meter-removal";
import InvestigationServiceOrderDetailsContainer from "./service-orders/investigations";
import ReplacementOrderDetailsContainer from "./service-orders/replacements";

interface ServiceOrderGenerationProps {
  complaint: any;
  refetch: () => void;
}

const serviceOrders = [
  // {
  //   name: "Inspection",
  //   href: "InspectionServiceOrder",
  //   component: ReplacementOrderDetailsContainer,
  // },
  // {
  //   name: "Regularization Inspection",
  //   href: "RegularizationInspectionServiceOrder",

  // },
  // {
  //   name: "Installation",
  //   href: "InstallationServiceOrderServiceOrder",
  // },
  // {
  //   name: "Regularization Installation ",
  //   href: "RegularizationInstallationServiceOrder",
  // },
  // {
  //   name: "Backlog Installation ",
  //   href: "BacklogInstallationServiceOrder",
  // },
  {
    name: "Replacement ",
    href: "ReplacementServiceOrder",
    component: ReplacementOrderDetailsContainer,
  },
  {
    name: "Investigation ",
    href: "InvestigationServiceOrder",
    component: InvestigationServiceOrderDetailsContainer,
  },
  {
    name: "Meter Removal ",
    href: "TerminationServiceOrder",
    component: MeterRemovalServiceOrderDetailsContainer,
  },
];

const renderServiceOrderDetails = (
  serviceOrderType: any,
  serviceOrderId: any
) => {
  if (!serviceOrderType) return null;
  const Component = serviceOrders.find(
    (item) => item.href === serviceOrderType
  )?.component;
  return Component ? <Component id={serviceOrderId} /> : null;
};

const ServiceOrderGenerationContainer: FC<ServiceOrderGenerationProps> = ({
  complaint,
  refetch,
}) => {
  const currentUser = useReactiveVar(currentUserVar);
  const [serviceOrder, setServiceOrder] = useUrlState("serviceOrder");
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };
  return (
    <div className='flex-1 flex flex-col overflow-hidden'>
      {complaint?.serviceOrder?._id ? (
        renderServiceOrderDetails(
          complaint?.serviceOrderType,
          complaint?.serviceOrder?._id
        )
      ) : (
        <>
          <div className='space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1'>
            <div className='p-4'>
              {/* {complaint?.userRole?._id === currentUser.role._id &&
                complaint?.status === "InProgress" && (
                  <div className='flex justify-end '>
                    <Menu as='div' className='relative inline-block text-left'>
                      <Menu.Button className=' inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2.5 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2'>
                        <PlusIcon
                          className='-ml-0.5 mr-2 h-4 w-4'
                          aria-hidden='true'
                        />
                        Initiate Service Order
                      </Menu.Button>
                      <Menu.Items className='absolute z-50 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        {serviceOrders.map((item) => (
                          <Menu.Item>
                            {({ active, close }) => (
                              <button
                                onClick={wrapClick(() => {
                                  setServiceOrder(item?.href);
                                  close();
                                })}
                                className={`${
                                  active
                                    ? "bg-primary-600 text-white"
                                    : "text-gray-900"
                                } group flex items-center w-full rounded-md px-2 py-2 text-sm`}
                              >
                                {item.name}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Menu>
                  </div>
                )} */}
              <div className='min-h-[600px]  rounded-lg border-2 mt-8 border-dashed items-center justify-center flex'>
                <div className='text-center'>
                  <svg
                    className='mx-auto h-12 w-12 text-gray-400 dark:text-gray-300'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path
                      vectorEffect='non-scaling-stroke'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={2}
                      d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
                    />
                  </svg>
                  <h3 className='mt-2 text-sm font-medium text-gray-900 dark:text-gray-100'>
                    No Service Order Generated
                  </h3>

                  {/* <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            New Project
          </button>
        </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className='bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200'>
        {complaint?.status === "InProgress" &&
          complaint?.userRole?._id === currentUser.role._id && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={dispatchAction(searchParams?.id || "", "resolve")}
            >
              {"Resolve Ticket"}
            </button>
          )}

        {complaint?.status === "InProgress" &&
          complaint?.userRole?._id === currentUser.role._id && (
            <button
              type='button'
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={dispatchAction(searchParams?.id || "", "send")}
            >
              {"Escalate Ticket"}
            </button>
          )}
      </div>
      {complaint?._id && (
        <>
          <ResolveComplaintContainer
            open={modal === "resolve"}
            setOpen={(val: boolean) => setModal(val ? "resolve" : undefined)}
            refetch={refetch}
          />
          <EscalateComplaintContainer
            open={modal === "send"}
            setOpen={(val: boolean) => setModal(val ? "send" : undefined)}
            refetch={refetch}
          />
        </>
      )}
    </div>
  );
};

export default ServiceOrderGenerationContainer;
