import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { Header, Loader, StepWizardStatus } from "components";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useState, useMemo } from "react";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";
import { currentConfigVar } from "apollo/cache/config";
// import ServiceRequestDetailsContainer from "./service-request-details";
// import ContractSigningDetailsContainer from "./contract-signing-details";
// import InspectionOrderDetailsContainer from "./inspection-order-details";
// import ConnectionChargeDetailsContainer from "./connection-charge-details";
// import InstallationOrderDetailsContainer from "./installation-order-details";
// import ApplicationChargeDetailsContainer from "./application-charge-details";
// import CustomerDetailsContainer from "./customer-details";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { classNames, useUrlState, wrapClick } from "utils";
import { GET_COMPLAINT } from "pages/complaints/view";
import TicketGeneratedDetailsContainer from "./ticket-generated-details";
import ResolutionDetailsContainer from "./resolution-details";
import EscalationDetailsContainer from "./escalation-details";
import ServiceOrderGenerationContainer from "./service-orders-generation";
// import ReplacementOrderDetailsContainer from "./replacement-order-details";

const complaintSteps = [
  {
    name: "Ticket Generation",
    description: "",
    Component: TicketGeneratedDetailsContainer,
  },
  {
    name: "Service Orders",
    description: "",
    Component: ServiceOrderGenerationContainer,
  },
  {
    name: "Escalation",
    description: "",
    Component: EscalationDetailsContainer,
  },
  {
    name: "Resolution",
    description: "",
    Component: ResolutionDetailsContainer,
  },
];

const updateItem = (
  array: any[],
  condition: (item: any) => boolean,
  updateFunction: (item: any) => void
): any[] => {
  const index = array.findIndex(condition);

  if (index !== -1) {
    _.update(array, `[${index}]`, updateFunction);
  }
  return array;
};
const processStep = (escalatedAt: any) => (data: any[]) => {
  if (!escalatedAt) {
    return updateItem(
      data,
      (item) => item.name === "Escalation",
      (item) => ({
        ...item,
        status: "Skipped",
      })
    );
  }

  return data;
};

const ComplaintPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const { params } = useMatch<LocationGenerics>();

  const { data, loading, refetch } = useQuery(GET_COMPLAINT, {
    variables: {
      getComplaintId: params.complaint,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  const filteredFormSteps = useMemo(
    () => processStep(data?.complaint?.escalatedAt)(complaintSteps.filter((_stpe) => true)),
    [data]
  );

  const steps = useMemo(
    () => [...filteredFormSteps].map((rawStep, href) => ({ ...rawStep, href })),
    [filteredFormSteps]
  );

  useEffect(() => {
    switch (data?.complaint?.status) {
      case "InProgress": {
        setStep(1);
        setLastStep(1);
        break;
      }
      case "Escalated": {
        setStep(2);
        setLastStep(2);
        break;
      }
      case "Closed": {
        setStep(3);
        setLastStep(3);
        break;
      }
      case "ConnectionFeeGenerated":
      case "ConnectionFeePaid": {
        setStep(4);
        setLastStep(4);
        break;
      }
      case "InstallationInitiated":
      case "InstallationCompleted":
      case "InstallationFailed": {
        setStep(5);
        setLastStep(5);
        break;
      }
      case "Completed": {
        setStep(6);
        setLastStep(6);
        break;
      }
      default:
        setStep(0);
        setLastStep(0);
        break;
    }
  }, [data?.complaint?.status]);

  const renderView = () => {
    const { Component } = steps[step || 0];
    return (
      <Component
        parentValue={data?.complaint}
        id={_.get(data?.complaint, "_id")}
        complaint={data?.complaint}
        refetch={refetch}
      />
    );
  };

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <Header />
      <div className='flex flex-1 overflow-hidden'>
        {loading ? (
          <Loader />
        ) : (
          <div className='bg-gray-100 shadow-xl flex-1 flex p-8 overflow-hidden relative'>
            <div className='w-1/3 flex flex-col space-y-6 overflow-y-auto no-scrollbar'>
              <div className='p-6 border border-dashed border-gray-300 rounded grid grid-cols-2 gap-6 mr-12'>
                <div>
                  <span className='block text-md font-light text-gray-700'>
                    Code
                  </span>
                  <div className='mt-1 block w-full text-md'>
                    {data?.complaint?.code || "N/A"}
                  </div>
                </div>
                <div className=''>
                  <span className='block text-md font-light text-gray-700'>
                    Date
                  </span>
                  <div className='mt-1 block w-full text-md'>
                    {data?.complaint?.createdAt
                      ? moment(data?.complaint?.createdAt).format(dateFormat)
                      : "N/A"}
                  </div>
                </div>
              </div>

              <StepWizardStatus
                steps={steps}
                step={step}
                setStep={setStep}
                lastStep={lastStep}
                status={_.get(data?.complaint, "status")}
              />
            </div>
            <div className='flex flex-1 overflow-hidden bg-white rounded-lg'>
              {renderView()}
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default ComplaintPage;
