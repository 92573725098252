import { FC } from "react";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { ActionButton } from "components";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { Action } from "components/buttons/action-button";
import parse from "html-react-parser";

interface CustomerComplaintsViewProps {
  complaints: any[];
  loading: boolean;
}
const statuses = {
  Open: "text-yellow-700 bg-yellow-50 ring-yellow-600/20",
  InProgress: "text-blue-600 bg-blue-50 ring-blue-500/10",
  Escalated: "text-red-800 bg-red-50 ring-red-600/20",
  Closed: "text-green-800 bg-green-50 ring-green-600/20",
};

const CustomerComplaintsView: FC<CustomerComplaintsViewProps> = ({
  complaints,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const navigate = useNavigate<LocationGenerics>();
  const searchParams = useSearch<LocationGenerics>();
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };
  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      {complaints?.length > 0 ? (
        <div>
          <ul role='list' className='divide-y divide-gray-100'>
            {/* <!-- Heading --> */}
            {complaints.map((complaint) => (
              <li
                key={complaint.id}
                className='flex items-center justify-between gap-x-6 py-5'
              >
                <div className='min-w-0'>
                  <div className='flex  items-center gap-x-2 mb-0.5'>
                    <span className='  text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'>
                      Code:
                    </span>
                    <p className='text-sm font-semibold  text-gray-900'>
                      {complaint.code}
                    </p>
                  </div>
                  <div className='flex  items-center gap-x-2'>
                    <span className='  text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'>
                      TYPE:
                    </span>
                    <p className='text-sm '>{complaint?.type?.name}</p>
                  </div>
                  <span className='  text-xs font-light underline text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap'>
                    DESCRIPTION:
                  </span>
                  <div className='mb-2.5 flex items-center gap-x-2 text-xs leading-5 text-gray-700'>
                    <p className='whitespace-nowrap'>
                      {parse(complaint?.description)}
                    </p>
                  </div>

                </div>
                <div className='flex flex-none items-center gap-x-4'>
                  <div className='mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500'>
                    <p className='whitespace-nowrap'>
                      Created at{" "}
                      <time dateTime={complaint?.createdAt}>
                        {complaint?.createdAt
                          ? moment(complaint?.createdAt).format(dateFormat)
                          : "N/A"}
                      </time>
                    </p>
                  </div>
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      complaint?.status === "Open"
                        ? `bg-yellow-100 text-yellow-800`
                        : "",
                      complaint?.status === "InProgress"
                        ? `bg-blue-100 text-blue-800`
                        : "",
                      complaint?.status === "Escalated"
                        ? `bg-red-100 text-red-800`
                        : "",
                      complaint?.status === "Closed"
                        ? `bg-green-100 text-green-800`
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 10 10'
                      fill='currentColor'
                      className='w-1.5 h-1.5'
                    >
                      <circle
                        fillRule='evenodd'
                        cx='5'
                        cy='5'
                        r='5'
                        clipRule='evenodd'
                      />
                    </svg>
                    <span>{complaint?.status || "Unknown"}</span>
                  </span>
                  <ActionButton
                    action='view'
                    onClick={dispatchAction(complaint?._id, "viewComplaint")}
                  />
                </div>
              </li>
            ))}
          </ul>
          {/* <div className="ps-[7px] flex gap-x-3">
            <button type="button" className="hs-collapse-toggle hs-collapse-open:hidden text-start inline-flex items-center gap-x-1 text-sm text-blue-600 font-medium decoration-2 hover:underline dark:text-blue-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" id="hs-timeline-collapse-content" data-hs-collapse="#hs-timeline-collapse">
              <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6" /></svg>
              Show older
            </button>
          </div> */}
        </div>
      ) : (
        <div className='flex min-h-[600px] items-center justify-center'>
          <div className='text-center'>
            <svg
              className='mx-auto h-12 w-12 text-gray-400 dark:text-gray-300'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              aria-hidden='true'
            >
              <path
                vectorEffect='non-scaling-stroke'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
              />
            </svg>
            <h3 className='mt-2 text-sm font-medium text-gray-900 dark:text-gray-100'>
              No tickets created for this customer
            </h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerComplaintsView;
