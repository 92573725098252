import { FC, useRef } from "react";
import { wrapClick } from "utils";
import { useReactToPrint } from "react-to-print";
import {
  EnvelopeIcon,
  GlobeAltIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import { Loader } from "components";
import { gql, useQuery } from "@apollo/client";
import lodash from "lodash";
import moment from "moment";
import ReplacemementServiceOrderResolutionView from "components/cards/replacement-service-order-resolution-view";

const GET_INSTALLATION_SERVICE_ORDER = gql`
  query GetReplacementServiceOrder($id: ID!) {
  installation: getReplacementServiceOrder(id: $id) {
    _id
    code
    status
    notes
    createdAt
    updatedAt
    estimatedResolutionDate
    priority
    result
    installationMeter {
      _id
      code
      model {
        _id
        brand {
          code
          name
        }
        name
        code
      }
    }
    resolution {
      service {
        qrCode
        meter {
          _id
          code
          modelMeta {
            brandCode
            brandName
            modelCode
            modelName
            systemCode
            systemName
            phase
          }
        }
      }
      # reading {
      #   readingDate
      #   readingImageUrl
      #   readingValue
      # }
      property {
        propertyImageUrls
        poleNumber
        geoLocation {
          coordinates
          type
        }
      }
      notes
      currentReading {
        readingDate
        readingValue
        readingImageUrl
      }
      currentReading {
        readingDate
        readingValue
        readingImageUrl
      }
      previousReading {
        readingDate
        readingValue
        readingImageUrl
      }
    }
  }
}

`;

interface ReplacementOrderDetailsContainerProps {
  values: any;
  id: string;
  code: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const ReplacementOrderDetailsContainer: FC<
  ReplacementOrderDetailsContainerProps
> = ({ values, code }) => {
  const printRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: code,
    bodyClass: "w-[1000px]",
  });


  console.log("values", values);

  const { data, loading } = useQuery(GET_INSTALLATION_SERVICE_ORDER, {
    variables: {
      id: values?.replacement?._id,
    },
    fetchPolicy: "network-only",
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div ref={printRef} className="flex-1 flex flex-col overflow-hidden">
        <div className="hidden print:flex flex-row items-center space-x-4 justify-center py-6 border-b border-gray-300">
          <div>
            <img
              alt="ECG Logo"
              src={require("assets/logo-new.png")}
              className="h-32 w-32"
            />
          </div>
          <div className="space-y-1 text-gray-900">
            <h1 className="text-xl text-primary-600 font-bold">
              ELECTRICITY COMPANY OF GHANA LIMITED
            </h1>
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                <EnvelopeIcon className="h-4 w-4" />
                <span>P.O BOX GP 521, ACCRA</span>
              </div>
              <div className="flex items-center space-x-2">
                <PhoneIcon className="h-4 w-4" />
                <span>0302-611-611</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-4 w-4" />
                <span>ecg@ecggh.com</span>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                <span className="font-semibold">VAT REG:</span>
                <span>714V000395</span>
              </div>
              <div className="flex items-center space-x-2">
                <span className="font-semibold">Website:</span>
                <span>https://www.ecggh.com/</span>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : data?.installation?.status === "Completed" ? (
          <div className="p-6 overflow-y-auto flex-1">
            <ReplacemementServiceOrderResolutionView
              resolution={data?.installation?.resolution}
            />
          </div>
        ) : (
          <div className="space-y-6 divide-y divide-gray-200 p-6 overflow-y-auto flex-1">
            <div className="p-4">
              <div className="flex justify-between items-center cursor-pointer">
                <div>
                  <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
                    Replacement Order Information
                  </h3>
                  <p className="mt-1 text-xs text-gray-500">
                    Details about replacement
                  </p>
                </div>
                <div />
              </div>
              <div className="mt-6">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
                  <div className="">
                    <span className="text-xs font-light">
                      Order Information
                    </span>
                    <div className="grid grid-cols-3 gap-6 mt-2">
                      <div className="">
                        <span className="block text-sm font-light text-gray-700">
                          Code
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {data?.installation?.code || "N/A"}
                        </div>
                      </div>
                      <div className="">
                        <span className="block text-sm font-light text-gray-700">
                          Priority
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {data?.installation?.priority || "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          Initiated At
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {data?.installation?.createdAt
                            ? moment(data?.installation?.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )
                            : "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          Estimated Resolution Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {data?.installation?.estimatedResolutionDate
                            ? moment(
                              data?.installation?.estimatedResolutionDate
                            ).format("DD/MM/YYYY")
                            : "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          Status
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {lodash.startCase(data?.installation?.status) ||
                            "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          Last Updated At
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {data?.installation?.updatedAt
                            ? moment(data?.installation?.updatedAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(handlePrint)}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Print
        </button>
        {/* <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Previous
        </button> */}
      </div>
    </div>
  );
};

export default ReplacementOrderDetailsContainer;
