import { FC } from 'react'
import { Avatar } from 'components/core';
import "mapbox-gl/dist/mapbox-gl.css";

interface ReadingViewProps {
  reading: {
    code: string;
    customerCode: string;
    geoCode: string;
    phoneNumber: string;
    emailAddress: string;
    servicePointNumber: string;
    meterNumber: string;
    accountNumber: string;
    fullName: string;
    meterLocation: string;
    contractedDemand: number;
    ghanaCardNumber: string;
    serviceType: string;
    customerType: string;
    tariffClass: {
      _id: string;
      code: string;
      name: string;
      __typename: "TariffClass";
    } | null;
    tariffClassCode: string;
    tariffClassName: string;
    region: {
      _id: string;
      code: string;
      name: string;
      __typename: "Region";
    } | null;
    regionCode: string;
    regionName: string;
    district: {
      _id: string;
      code: string;
      name: string;
      __typename: "District";
    } | null;
    districtCode: string;
    districtName: string;
    block: {
      _id: string;
      code: string;
      name: string;
      __typename: "Block";
    } | null;
    blockCode: string;
    blockName: string;
    round: {
      _id: string;
      code: string;
      name: string;
      __typename: "Round";
    } | null;
    roundCode: string;
    roundName: string;
    plot: {
      _id: string;
      code: string;
      community: string;
      __typename: "Plot";
    } | null;
    plotCode: string;
    community: string;
    itinerary: {
      _id: string;
      code: string;
      description: string;
      __typename: "Itinerary";
    } | null;
    itineraryCode: string;
    itineraryDescription: string;
    property: {
      _id: string;
      code: string;
      ghanaPostAddress: string;
      __typename: "Property";
    } | null;
    propertyCode: string;
    qrCode: string;
    address: string;
    ghanaPostAddress: string;
    status: string;
    profileImageUrl: string;
    previousReading: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
      totalAmountDueUpdatedAt: Date;
    };
    currentReading: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
      totalAmountDueUpdatedAt: Date;
    };
    meta: {
      readingImageUrl: string;
      readingLocation: {
        coordinates: [number, number]
      };
      readingImageLocation: {
        coordinates: [number, number]
      }
    };
    bill: {
      outstandingBalance: number;
      totalAmountDue: number;
      consumptionPeriod: number;
      tariffClassCode: string;
      tariffClassName: string;
      currentBill: {
        readingValue: number;
        consumptionValue: number;
      };
      previousBill: {
        readingValue: number;
        consumptionValue: number;
      };
      currentBillAmount: number;
      bills: {
        currentDate: string;
        previousDate: string;
        tariffInstance: {
          _id: string;
        };
        numberOfDays: number;
        consumption: number;
        billItems: {
          title: string;
          unit: string;
          quantity: number;
          unitCost: number;
          cost: number;
        }[];
        billAmount: number;
      }[];
    };
  };
}

const ReadingView: FC<ReadingViewProps> = ({ reading }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Customer Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
            <Avatar
              alt={reading?.fullName || "N A"}
              src={reading?.profileImageUrl || ""}
              size="xl"
            />
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Customer Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.customerCode || "N/A"}
            </div>
          </div>
          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Full Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.fullName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Ghana Card Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.ghanaCardNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Contract Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Service Point Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.servicePointNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.meterNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Account Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.accountNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Contracted Demand
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.contractedDemand || "N/A"} KVA
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Customer Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.customerType || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Service Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.serviceType || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Tariff Class
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.tariffClassName || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>Property Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Region
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.regionName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              District
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.districtName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Block
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.blockName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Round
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.roundName || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Plot
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.plotCode || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Itinerary
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.itineraryCode || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Property
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.propertyCode || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Geo Code
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.geoCode || "N/A"}
            </div>
          </div>
          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Meter Location
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.meterLocation || "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReadingView