import { gql, useQuery } from "@apollo/client";
import { GET_CUSTOMER } from "./view";

const GET_CUSTOMERS = gql`
 query GetServicePoints(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $block: ID
  $round: ID
  $plot: ID
  $itinerary: ID
  $property: ID
  $tariffClass: ID
  $setupType: SetupType
  $customerType: CustomerType
  $serviceType: ServiceType
  $meterBrand: ID
  $fromDate: Date
  $toDate: Date
) {
  rows: getServicePoints(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    block: $block
    round: $round
    plot: $plot
    itinerary: $itinerary
    property: $property
    tariffClass: $tariffClass
    customerType: $customerType
    serviceType: $serviceType
    setupType: $setupType
    meterBrand: $meterBrand
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    code
    createdAt
    updatedAt
    accountCode
    customerCode
    meterCode
    geoCode
    propertyCode
    customer {
      _id
      code
      customerType

      organization {
        name
      }
      representative {
        fullName
        emailAddress
        phoneNumber
        profileImageUrl
      }
    }
    status
    subStatus
    ghanaPostAddress
    address
    tariffClassCode
    tariffClassName
  }
  count: getServicePointsCount(
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    block: $block
    round: $round
    plot: $plot
    itinerary: $itinerary
    property: $property
    tariffClass: $tariffClass
    customerType: $customerType
    serviceType: $serviceType
    meterBrand: $meterBrand
    setupType: $setupType
    fromDate: $fromDate
    toDate: $toDate
  )
}

`;

export const useServicePoints = (variables: any) => {
  const { data, ...rest } = useQuery(GET_CUSTOMERS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const servicePoints = data?.rows || [];
  const count = data?.count || 0;
  return { servicePoints, count, ...rest };
};

export const useServicePoint = (variables: any) => {
  const { data, ...rest } = useQuery(GET_CUSTOMER, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const servicePoint = data?.customer;
  return { servicePoint, ...rest };
};
