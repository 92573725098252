import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";

interface ComplaintTypePickerContainerProps {
  filter?: {
    category?: string;
    requiresServicePoint?: boolean;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched?: any;
  setFieldError?: any;
  rawId?: boolean;
}

const GET_COMPLAINT_TYPES = gql`
  query GetComplaintTypes(
    $page: Int
    $category: ComplaintCategory
    $categories: [ComplaintCategory]
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $requiresServicePoint: Boolean
  ) {
    complaintTypes: getComplaintTypes(
      categories: $categories
      category: $category
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      requiresServicePoint: $requiresServicePoint
    ) {
      _id
      code
      name
      description
      userRole {
        name
      }
      category
      createdAt
      updatedAt
      requiresDocument
      requiresServicePoint
    }
  }
`;

const ComplaintTypePickerContainer: FC<ComplaintTypePickerContainerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_COMPLAINT_TYPES, {
    variables: {
      ...(filter ? filter : {}),
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <>
      <SearchSelectInput
        id={id ?? "type"}
        label={label ?? ""}
        placeholder='Select Complaint Type'
        optionsLoading={loading}
        options={(data?.complaintTypes ?? [])?.map((complaintType: any) => ({
          label: {
            title: `${complaintType.name}`,
          },
          value: rawId ? complaintType._id : complaintType,
        }))}
        {...form}
      />
    </>
  );
};

export default ComplaintTypePickerContainer;
