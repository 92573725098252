import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";

interface BlockPickerContainerProps {
  filter?: {
    region?: string;
    district?: string;
    status?: ("Active" | "InActive");
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
}

const GET_BLOCKS = gql`
  query GetBlocksSelect($region: ID, $district: ID, $status: BlockStatus) {
    blocks: getBlocks(
      region: $region
      district: $district
      status: $status
      sort: "code"
      page: 0
      pageSize: 0
    ) {
      _id
      code
      name
    }
  }
`;

const BlockPickerContainer: FC<BlockPickerContainerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_BLOCKS, {
    variables: {
      ...(filter ? filter : {}),
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <>
      <SearchSelectInput
        id={id ?? "block"}
        label={label ?? "Block"}
        placeholder="Select Block"
        optionsLoading={loading}
        options={(data?.blocks ?? [])?.map((block: any) => ({
          label: {
            title: block.code as string,
          },
          value: rawId ? block._id : block,
        }))}
        {...form}
      />
    </>
  );
};

export default BlockPickerContainer;
