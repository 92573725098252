import { FC } from 'react'
import moment from 'moment';
import { classNames } from 'utils';
import _ from "lodash";
import { XMarkIcon } from '@heroicons/react/20/solid'
import { Disclosure } from '@headlessui/react';
import { ArrowsUpDownIcon, LightBulbIcon, PencilSquareIcon, PowerIcon, PrinterIcon } from '@heroicons/react/24/outline';


function removeEmptyValues(obj: Record<string, any>) {
  return _.pickBy(obj, (value, key) => {
    if (key === '__typename') {
      return false; // Exclude keys with "__typename"
    }
    if (_.isArray(value)) {
      return !_.isEmpty(value); // Check if the array is not empty
    }
    return !_.isNull(value) && !_.isEqual(value, ''); // Check for null and empty string
  });
}

function renderChanges(obj: Record<string, any>) {
  const newObj = removeEmptyValues(obj);
  return _.map(newObj, (value, key) => {
    return (
      <div key={key}>
        <span className='font-thin text-xs text-gray-900'>{_.startCase(key)}</span>
        <p className='text-sm text-gray-600 font-light'>{
          _.isArray(value) ? value.join(", ") || "N/A" : value || "N/A"
        }</p>
      </div>
    );
  });
}

interface ServicePointHistoryViewProps {
  history: any[];
}

const ServicePointHistoryView: FC<ServicePointHistoryViewProps> = ({ history }) => {
  const actionIconMap = {
    "MeterInstalled": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <LightBulbIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "MeterReplaced": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <ArrowsUpDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "TariffUpdated": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "ContractSuspended": (
      <span
        className={classNames(
          "bg-red-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "ContractTerminated": (
      <span
        className={classNames(
          "bg-red-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <PowerIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "IdentityUpdated": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "LocationUpdated": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "ContractRestored": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <PowerIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "ContactUpdated": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <PencilSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
    "MeterRead": (
      <span
        className={classNames(
          "bg-green-500",
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
        )}
      >
        <PrinterIcon className="h-5 w-5 text-white" aria-hidden="true" />
      </span>
    ),
  }


  return (
    <div className="flow-root  min-h-[600px]">
      <ul className="-mb-8">
        {history?.map((historyItem, historyItemIdx) => (
          <li key={historyItemIdx}>
            <div className="relative pb-8">
              {historyItemIdx !== history.length - 1 ? (
                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  {_.get(actionIconMap, historyItem.action, "")}
                </div>
                <div className="flex min-w-0 flex-1 justify-between space-x-12 pt-1.5">
                  <div className='flex-1'>
                    <HistoryDetails history={historyItem} />
                  </div>
                  <div className="whitespace-nowrap text-right text-sm flex flex-col text-gray-500">
                    <time dateTime={historyItem.createdAt}>{moment(historyItem.createdAt).format("DD/MM/YYYY")}</time>
                    <time dateTime={historyItem.createdAt}>{moment(historyItem.createdAt).format("hh:mm A")}</time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ServicePointHistoryView


const HistoryDetails = ({ history }: { history: any }) => {

  return (
    <>
      <p className="text-sm text-gray-500">
        <span className="font-medium text-gray-900">
          {history.actor.lastName} {history.actor.firstName}
        </span> {" "}  {_.split(history.action, /(?=[A-Z])/).reverse().join(" ")}
        <Disclosure defaultOpen as="div" className="relative p-4 border border-dashed grid-cols-4 border-gray-300 rounded mt-6">
          <Disclosure.Button className="absolute bg-white px-2 -top-2 text-xs">
            Changes
          </Disclosure.Button>
          <Disclosure.Panel className="grid mt-4 grid-cols-3 gap-4">
            {renderChanges(history?.data)}
          </Disclosure.Panel>
        </Disclosure>
      </p>
      {history?.description && (
        <div className='relative p-4 border border-gray-300 rounded mt-6'>
          <span className='absolute bg-white px-2 -top-2 text-xs'>Notes</span>
          <p className='text-sm text-gray-600 font-light'>{history.description}</p>
        </div>
      )}
    </>
  )
}