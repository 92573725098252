import {
  CustomerTypes,
  DistrictCodes,
  Genders,
  IdentityCardTypes,
  Nationalities,
  ServiceClasses,
  ServiceRequestCategories,
  ServiceRequestTypes,
  ServiceTypes,
  Titles,
} from "apollo/data";
import lodash from "lodash";
import moment from "moment";
import { validators } from "utils";
import * as Yup from "yup";

export type Title = (typeof Titles)[number];
export type Gender = (typeof Genders)[number];
export type RequestCategory =
  (typeof ServiceRequestCategories)[number]["value"];
export type RequestType = (typeof ServiceRequestTypes)[number]["value"];
export type CustomerType = (typeof CustomerTypes)[number];
export type ServiceType = (typeof ServiceTypes)[number]["value"];
export type ServiceClass = (typeof ServiceClasses)[number]["value"];
export type IdentityCardType = (typeof IdentityCardTypes)[number];

interface IdentityCardInfo {
  hasGhanaCard: boolean;
  ghanaCardNumber: string;
  ghanaCardIssueDate: string;
  ghanaCardExpiryDate: string;
  ghanaCardFrontImageUrl: string;
  ghanaCardBackImageUrl: string;
  identityCardType: IdentityCardType;
  identityCardNumber: string;
  identityCardIssueDate: string;
  identityCardExpiryDate: string;
  identityCardFrontImageUrl: string;
  identityCardBackImageUrl: string;
}
export interface ICreateServiceRequestFormSchema {
  requestInfo: {
    isExistingCustomer: false;
    existingCustomer: any;
    existingServicePoint: any;
    isExistingProperty: false;
    existingProperty: any;
    category: RequestCategory;
    type: RequestType;
    quantity: number;
  };
  customerInfo: {
    customerType: CustomerType;

    organization: {
      name: string;
      type: 'Private' | 'Public';
    };
    representative: {
      title: Title;
      fullName: string;
      nationality: string;
      dateOfBirth: string;
      gender: Gender;
      phoneNumber: string;
      emailAddress: string;
      profileImageUrl: string;
      hasGhanaCard: boolean;
      ghanaCardNumber: string;
      ghanaCardIssueDate: string;
      ghanaCardExpiryDate: string;
    };
  };
  identityInfo: {
    representative: IdentityCardInfo;
    organization: {
      taxIdentificationNumber?: string;
      organizationRegistrationNumber?: string;
      organizationRegistrationDate?: string;
      organizationRegistrationDocumentUrl?: string;
      certificateOfIncorporationDocumentUrl?: string;
    };
  };
  propertyInfo: {
    owner: {
      fullName: string;
      phoneNumber: string;
    };
    region: {
      _id: string;
      code: string;
      name: string;
    } | null;
    district: {
      _id: string;
      code: string;
      name: string;
    } | null;
    ghanaPostAddress: string;
    community: string;
    streetName: string;
    houseNumber: string;
    structureNumber: string;
    landmark: string;
    premiseType: {
      _id: string;
      code: string;
      name: string;
    } | null;
    premiseCategory: {
      _id: string;
      code: string;
      name: string;
    } | null;
    activity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    subActivity: {
      _id: string;
      code: string;
      name: string;
    } | null;
    geoLocation: {
      longitude: number;
      latitude: number;
    };
    sitePlanDocumentUrl: string;
  };
  serviceInfo: {
    serviceType: ServiceType;
    serviceClass: ServiceClass;
    energyCertificateNumber: string;
    energyCertificateIssuerId?: string;
    energyCertificateIssuerName?: string;
    energyCertificateIssuerPhone:string
    energyCertificateDocumentUrl: string;
  };
}

export const RequestInfoSchema = Yup.object().shape({
  category: Yup.string()
    .oneOf(lodash.map(ServiceRequestCategories, "value"))
    .required(),
  type: Yup.string().oneOf(lodash.map(ServiceRequestTypes, "value")).required(),
  quantity: Yup.number().min(0).max(3).notRequired(),
  existingCustomer: Yup.object()
    .when("type", {
      is: "NewServiceRequest",
      then: (schemaa) =>
        schemaa.when("isExistingCustomer", {
          is: true,
          then: (schema) => schema.nullable().required(),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
      otherwise: (schemaa) => schemaa.nullable().required(),
    })
    .nullable(),
  existingProperty: Yup.object()
    .when("type", {
      is: "NewServiceRequest",
      then: (schemaa) => schemaa.nullable().notRequired(),
      otherwise: (schemaa) => schemaa.nullable().required(),
    })
    .nullable(),
});

const PersonalRequiredInfoSchema = Yup.object()
  .shape({
    title: Yup.string()
      .oneOf([...Titles])
      .required("Kindly select title"),
    fullName: Yup.string().required("Name is required"),
    nationality: Yup.string()
      .oneOf(lodash.map(Nationalities, "nationality"))
      .required("Kindly select nationality"),
    dateOfBirth: Yup.date()
      .max(
        moment().subtract(18, "years").toDate(),
        "Customer must be at least 18 years"
      )
      .required("Date of birth is required"),
    gender: Yup.string()
      .oneOf([...Genders])
      .required("Kindly select gender"),
    phoneNumber: Yup.string()
      .matches(
        /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
        "Kindly enter a valid phone number"
      )
      .required("Phone number is required"),
    emailAddress: Yup.string()
      .email("Kindly enter a valid email address")
      .notRequired(),
    profileImageUrl: Yup.string().url().nullable().notRequired(),
  })
  .required();

export const CustomerInfoSchema = Yup.object().shape({
  customerType: Yup.string()
    .oneOf([...CustomerTypes])
    .required("Kindly select customer type"),
  representative: PersonalRequiredInfoSchema,
  organization: Yup.object().when("customerType", {
    is: "Organization",
    then: Yup.object()
      .shape({
        name: Yup.string().required("Organization name is required"),
      })
      .required(),
    otherwise: Yup.object()
      .shape({
        name: Yup.string().notRequired(),
      })
      .nullable()
      .notRequired(),
  }),
});

export const IdentityCardInfoSchema = Yup.object().shape({
  hasGhanaCard: Yup.boolean().required(),
  ghanaCardNumber: Yup.string()
    .matches(
      validators.GhanaCardRegex,
      "Kindly enter a valid Ghana Card Number"
    )
    .when("hasGhanaCard", {
      is: true,
      then: Yup.string().required("Ghana Card Number is required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  ghanaCardIssueDate: Yup.date().when("hasGhanaCard", {
    is: true,
    then: Yup.date().required("Ghana Card issue date is required"),
    otherwise: Yup.date().nullable().notRequired(),
  }),
  ghanaCardExpiryDate: Yup.date().when("hasGhanaCard", {
    is: true,
    then: Yup.date().required("Ghana Card expiry date is required"),
    otherwise: Yup.date().nullable().notRequired(),
  }),
  ghanaCardFrontImageUrl: Yup.string()
    .url()
    .when("hasGhanaCard", {
      is: true,
      then: Yup.string().required("Kindly attach front image of Ghana Card"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  ghanaCardBackImageUrl: Yup.string()
    .url()
    .when("hasGhanaCard", {
      is: true,
      then: Yup.string().required("Kindly attach rear image of Ghana Card"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  identityCardType: Yup.string().when("hasGhanaCard", {
    is: false,
    then: Yup.string()
      .oneOf([...IdentityCardTypes])
      .required("Kindly select ID Card type"),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  identityCardNumber: Yup.string().when("hasGhanaCard", {
    is: false,
    then: Yup.string().required("Kindly enter ID Card number"),
    otherwise: Yup.string().nullable().notRequired(),
  }),
  identityCardIssueDate: Yup.date().when("hasGhanaCard", {
    is: false,
    then: Yup.date().required("Kindly enter ID Card issue date"),
    otherwise: Yup.date().nullable().notRequired(),
  }),
  identityCardExpiryDate: Yup.date().when("hasGhanaCard", {
    is: false,
    then: Yup.date().when("identityCardType", {
      is: "VotersIdentificationCard",
      then: (schema) => schema.nullable().notRequired(),
      otherwise: (schema) =>
        schema.required("Kindly enter ID Card expiry date"),
    }),
    otherwise: Yup.date().nullable().notRequired(),
  }),
  identityCardFrontImageUrl: Yup.string()
    .url()
    .when("hasGhanaCard", {
      is: false,
      then: Yup.string().required("Kindly attach front image of ID Card"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  identityCardBackImageUrl: Yup.string()
    .url()
    .when("hasGhanaCard", {
      is: false,
      then: Yup.string().when("identityCardType", {
        is: "DriversLicense",
        then: (schema) =>
          schema.required("Kindly attach rear image of ID Card"),
        otherwise: (schema) => schema.nullable().notRequired(),
      }),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
});

export const IdentityInfoSchema = (customerType: CustomerType, isPublicOrg=true) =>
  Yup.object().shape({
    representative: IdentityCardInfoSchema,
    organization:
      customerType === "Organization" && !isPublicOrg
        ? Yup.object().shape({
            taxIdentificationNumber: Yup.string()
              .matches(
                /^(C|P|R|G|V|S)(\d|[A-Z]){10}$/,
                "Enter a valid Tax Identification Number"
              )
              .required("Tax Identification is required"),
            organizationRegistrationNumber: Yup.string()
              .matches(/^[0-9A-Z]{11}$/, "Enter a valid registration number")
              // .length(11, "Registration Number is not")
              .required("Registration number is required"),
            organizationRegistrationDate: Yup.date()
              .max(new Date())
              .required("Registration date is required"),
            organizationRegistrationDocumentUrl: Yup.string()
              .url()
              .required("Registration Document Is Required"),
            certificateOfIncorporationDocumentUrl: Yup.string()
              .url()
              .required("Certificate Of Incorporation Is Required"),
          })
        : Yup.object()
            .shape({
              taxIdentificationNumber: Yup.string().nullable().notRequired(),
              organizationRegistrationNumber: Yup.string()
                .nullable()
                .notRequired(),
              organizationRegistrationDate: Yup.date().nullable().notRequired(),
              organizationRegistrationDocumentUrl: Yup.string()
                .nullable()
                .notRequired(),
              certificateOfIncorporationDocumentUrl: Yup.string()
                .nullable()
                .notRequired(),
            })
            .nullable(),
  });

export const PropertyInfoSchema = Yup.object().shape({
  owner: Yup.object()
    .shape({
      fullName: Yup.string().required("Kindly enter property owner's name"),
      phoneNumber: Yup.string()
        .matches(
          /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
          "Kindly enter a valid phone number"
        )
        .required("Phone number is required"),
    })
    .required(),
  region: Yup.object().nullable().required("Kindly select region"),
  district: Yup.object().nullable().required("Kindly select district"),
  ghanaPostAddress: Yup.string()
    .matches(
      validators.GhanaPostRegex,
      "Kindly enter a valid GhanaPost Address"
    )
    .required("GhanaPost Address is required"),
  community: Yup.string().required("Kindly enter community"),
  streetName: Yup.string().required("Kindly enter street name"),
  houseNumber: Yup.string().required("Kindly enter house number"),
  structureNumber: Yup.string().notRequired(),
  landmark: Yup.string().required("Kindly enter closest landmark"),
  premiseType: Yup.object().nullable().required("Kindly select premise type"),
  premiseCategory: Yup.object()
    .nullable()
    .required("Kindly select premise category"),
  activity: Yup.object().nullable().required("Kindly select activity"),
  subActivity: Yup.object().nullable().required("Kindly select sub activity"),
  geoLocation: Yup.object().shape({
    longitude: Yup.number().min(-180).max(180).required(),
    latitude: Yup.number().min(-180).max(180).required(),
  }),
  sitePlanDocumentUrl: Yup.string().url().notRequired(),
});

export const ServiceInfoSchema = Yup.object().shape({
  serviceType: Yup.string().oneOf(lodash.map(ServiceTypes, "value")).required(),
  serviceClass: Yup.string()
    .oneOf(lodash.map(ServiceClasses, "value"))
    .required(),
  energyCertificateNumber: Yup.string().required(
    "Energy certificate number is required"
  ),
  energyCertificateIssuerPhone: Yup.string().matches(
    /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
    "Kindly enter a valid phone number"
  ),
  energyCertificateDocumentUrl: Yup.string()
    .url()
    .required("Kindly attach energy certificate document"),
});

export const sanitizeCustomer = (customer: any) => ({
  customerType: customer.customerType || "Individual",

  organization: customer.organization
    ? lodash.pick(customer.organization, ["name"])
    : {
        name: "",
      },
  representative: customer.representative
    ? lodash.pick(customer.representative, [
        "title",
        "fullName",
        "nationality",
        "dateOfBirth",
        "gender",
        "phoneNumber",
        "emailAddress",
        "profileImageUrl",
      ])
    : {
        title: "Mr",
        fullName: "",
        nationality: "Ghanaian",
        dateOfBirth: "",
        gender: "Male",
        phoneNumber: "",
        emailAddress: "",
        profileImageUrl: "",
      },
});

export const sanitizeIdentity = (identity: any) => ({
  representative: identity.representative
    ? lodash.pick(identity.representative, [
        "hasGhanaCard",
        "ghanaCardNumber",
        "ghanaCardIssueDate",
        "ghanaCardExpiryDate",
        "ghanaCardFrontImageUrl",
        "ghanaCardBackImageUrl",
        "identityCardType",
        "identityCardNumber",
        "identityCardIssueDate",
        "identityCardExpiryDate",
        "identityCardFrontImageUrl",
        "identityCardBackImageUrl",
      ])
    : {
        hasGhanaCard: true,
        ghanaCardNumber: "",
        ghanaCardIssueDate: "",
        ghanaCardExpiryDate: "",
        ghanaCardFrontImageUrl: "",
        ghanaCardBackImageUrl: "",
        identityCardType: "DriversLicense",
        identityCardNumber: "",
        identityCardIssueDate: "",
        identityCardExpiryDate: "",
        identityCardFrontImageUrl: "",
        identityCardBackImageUrl: "",
      },
  organization: identity.organization
    ? lodash.pick(identity.organization, [
        "taxIdentificationNumber",
        "organizationRegistrationNumber",
        "organizationRegistrationDate",
        "organizationRegistrationDocumentUrl",
        "certificateOfIncorporationDocumentUrl",
      ])
    : {
        taxIdentificationNumber: "",
        organizationRegistrationNumber: "",
        organizationRegistrationDate: "",
        organizationRegistrationDocumentUrl: "",
        certificateOfIncorporationDocumentUrl: "",
      },
});

export const sanitizeProperty = (property: any) => ({
  owner: {
    fullName: property?.owner?.fullName || "",
    phoneNumber: property?.owner?.phoneNumber || "",
  },
  region: property?.region || null,
  district: property?.district || null,
  ghanaPostAddress: property?.ghanaPostAddress || "",
  community: property?.community || "",
  streetName: property?.streetName || "",
  houseNumber: property?.houseNumber || "",
  structureNumber: property?.structureNumber || "",
  landmark: property?.landmark || "",
  premiseType: property?.premiseType || null,
  premiseCategory: property?.premiseCategory || null,
  activity: property?.activity || null,
  subActivity: property?.subActivity || null,
  geoLocation: property?.geoLocation?.coordinates
    ? {
        longitude: property?.geoLocation?.coordinates?.[0],
        latitude: property?.geoLocation?.coordinates?.[1],
      }
    : {
        longitude: -0.234361,
        latitude: 5.667032,
      },
  sitePlanDocumentUrl: property?.sitePlanDocumentUrl || "",
});

export const sanitizeService = (service: any) => ({
  serviceType: service?.serviceType || "Postpaid",
  serviceClass: service?.serviceClass || "Residential",
  energyCertificateNumber: service?.energyCertificateNumber || "",
  energyCertificateDocumentUrl: service?.energyCertificateDocumentUrl || "",
});
