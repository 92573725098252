import { gql, useQuery } from "@apollo/client";

const GET_SHEP_REGULARIZATIONS = gql`
 query GetShepRegularizations(
  $region: ID
  $district: ID
  $page: Int
  $search: String
  $pageSize: Int
  $searchFields: [String!]
  $sort: String
  $fromDate: Date
  $toDate: Date
  $dateField: String
  $month: PositiveInt
  $year: PositiveInt
) {
 rows: getShepRegularizations(
    region: $region
    district: $district
    page: $page
    search: $search
    pageSize: $pageSize
    searchFields: $searchFields
    sort: $sort
    fromDate: $fromDate
    toDate: $toDate
    dateField: $dateField
    month: $month
    year: $year
  ) {
    _id
    accountCode
    code
    createdAt 
    customerCode
    service {
    meterNumber
    serviceClass
    }
    customerData {
        representative {
          fullName
          phoneNumber
          profileImageUrl
        }
        organization {
          name
        }
      }
    district {
      code
      name
    }
    region {
      code
      name
    }
    servicePointCode
  }
 count: getShepRegularizationsCount(
    region: $region
    district: $district
    search: $search
    searchFields: $searchFields
    fromDate: $fromDate
    toDate: $toDate
    dateField: $dateField
    month: $month
    year: $year
  )
}
`;

export const useShepRegularizations = (variables?: any) => {
  const { data, ...rest } = useQuery(GET_SHEP_REGULARIZATIONS, {
    variables: {
      ...variables,
    },
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  return {
    rows: data?.rows,
    count: data?.count,
    ...rest,
  };
};