import { useEffect } from 'react'
import { wrapClick } from 'utils';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Modal, SendBillForm } from 'components';
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import moment from 'moment';

const GET_BILL = gql`
  query GetBill($id: ID!) {
    bill: getBill(id: $id) {
      _id
      code
      customerCode
      emailAddress
      phoneNumber
      fullName
      payer {
        fullName
        emailAddress
      }
      month
      year
      createdAt
      updatedAt
    }
  }
`

const SEND_BILL = gql`
  mutation SendBill(
    $id: ID!
    $fullName: String
    $subject: String
    $emailAddress: String
    $emailAddressEnabled: Boolean!
    $phoneNumber: String
    $phoneNumberEnabled: Boolean!
  ) {
    sendBill(
      billId: $id
      fullName: $fullName
      subject: $subject
      emailAddress: $emailAddress
      emailAddressEnabled: $emailAddressEnabled
      phoneNumber: $phoneNumber
      phoneNumberEnabled: $phoneNumberEnabled
    )
  }
`

export default function SendBillContainer({ open, setOpen, refetch }: { open: boolean; setOpen: (val: boolean) => void; refetch?: () => void }) {
 
  const searchParams = useSearch<LocationGenerics>();
  const { data } = useQuery(GET_BILL, {
    variables: {
      id: searchParams.id
    },
    notifyOnNetworkStatusChange: false
  });
  const [sendBill, { loading }] = useMutation(SEND_BILL)

  const form = useFormik({
    initialValues: {
      fullName: "",
      subject: "",
      emailAddress: "",
      emailAddressEnabled: false,
      phoneNumber: "",
      phoneNumberEnabled: true,
    },
    onSubmit: async (values) => {
      await sendBill({
        variables: {
          id: searchParams.id,
          ...values
        }
      }).then(({ data }) => {
        if (data.sendBill) {
          toast(JSON.stringify({ type: "success", title: "Bill Sent Successfully" }))
          refetch?.();
          setOpen(false)
        }
        else {
          toast(JSON.stringify({ type: "error", title: "Could not send Bill" }))
        }
      })
    },
    onReset: () => {
      setOpen(false)
    }
  })

  useEffect(() => {
    form.setValues({
      fullName: data?.bill?.fullName || data?.bill?.payer?.fullName || "",
      subject: `ECG SUBS - ${moment({ year: data?.bill?.year, month: data?.bill?.month - 1 }).format("MMMM, YYYY")} Electricity Bill: Customer ${data?.bill?.customerCode}`,
      emailAddress: data?.bill?.emailAddress || data?.bill?.payer?.emailAddress || "",
      emailAddressEnabled: !!(data?.bill?.emailAddress || data?.bill?.payer?.emailAddress),
      phoneNumber: data?.bill?.phoneNumber || data?.bill?.payer?.phoneNumber || "",
      phoneNumberEnabled: !!(data?.bill?.phoneNumber || data?.bill?.payer?.phoneNumber),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.bill, searchParams.id, open])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Send Bill"
      description="Provide the contact details to send copy of bill to"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Sending Bill..." : "Send Bill"}
          </button>
        </>
      )}>
      {data?.bill?._id && (
        <SendBillForm
          form={form}
        />
      )}
    </Modal>
  )
}
