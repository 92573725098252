import { FC } from "react";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapProvider, Marker } from "react-map-gl";
import config from "config";

interface ReadingViewProps {
  shepRegularization: any;
}

const ReadingView: FC<ReadingViewProps> = ({ shepRegularization }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Contract Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.service?.meterNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Model
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.meterModel?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Meter Brand
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.meterBrand?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Contracted Demand
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.service?.contractedDemand || "N/A"} KVA
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Service Class
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.serviceClass || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Tariff Class
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.tariff?.name || "N/A"}
            </div>
          </div>
        </div>
      </div>

      <div className='pt-6'>
        <span className='text-xs font-light'>Reading Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Reading Date
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.service?.initialReadingDate
                ? moment(
                  shepRegularization?.service?.initialReadingDate
                ).format(dateFormat)
                : "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Reading Value
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.service?.initialReadingValue || "N/A"}
            </div>
          </div>

          <div>
            <span className='block text-sm font-light text-gray-700'>
              Installation Date
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.service?.installationDate ? moment(shepRegularization?.service?.installationDate).format(dateFormat) : "N/A"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReadingView;
