import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Header, TextInput } from "components";
import moment from "moment";
import lodash from "lodash";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useMatch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, classNames, wrapOnchange } from "utils";
import numeral from "numeral";
import { useReactToPrint } from "react-to-print";
import { PrinterIcon } from "@heroicons/react/24/outline";
import ls from "localstorage-slim";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  BlockPicker,
  DistrictPicker,
  PlotPicker,
  RegionPicker,
  RoundPicker,
} from "containers";
import DocketDetails from "./docket";

const GET_PLOTS = gql`
  query GetPlots($region: ID, $district: ID, $block: ID, $round: ID) {
    plots: getPlots(
      region: $region
      district: $district
      block: $block
      round: $round
    ) {
      _id
      code
      community
      regionName
      districtName
      meta {
        customersCount
      }
    }
  }
`;

const READ_READING_SET = gql`
  mutation ReadReadingSet($id: ID!, $readings: [ReadReadingSetReadingInput!]!) {
    readReadingSet(id: $id, readings: $readings) {
      _id
    }
  }
`;

const ReadingsPage: FC = () => {
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();

  const [readReadingSet, { loading }] = useMutation(READ_READING_SET);

  const form = useFormik({
    initialValues: {
      readings: [] as {
        _id: string;
        result: object;
      }[],
    },
    validationSchema: Yup.object().shape({
      readings: Yup.array().of(
        Yup.object().shape({
          _id: Yup.string().required(),
          result: Yup.object().shape({
            readingValue: Yup.number().when(
              ["readingAnomaly", "readingNoTrace"],
              {
                is: (readingAnomaly: boolean, readingNoTrace: boolean) =>
                  readingAnomaly === false && readingNoTrace === false,
                then: (schema) => schema.min(0).required(),
                otherwise: (schema) => schema.notRequired(),
              }
            ),
            readingDate: Yup.date().max(moment().toDate()).required(),
            readingAnomaly: Yup.boolean().required(),
            readingNoTrace: Yup.boolean().required(),
            readingNotes: Yup.string().when("readingAnomaly", {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          }),
        })
      ),
    }),
    onSubmit: async (values) => {
      console.log("submit", values);
      await readReadingSet({
        variables: {
          id: params.readingSet,
          readings: values.readings.map((reading) => ({
            _id: reading._id,
            result: reading.result,
          })),
        },
      }).then(({ data }) => {
        if (data?.readReadingSet?._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Reading set uploaded successfully",
            })
          );
          refetch?.();
          navigate({ to: "/reading-sets", replace: true });
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not upload reading set",
            })
          );
        }
      });
    },
  });

  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");
  const [block, setBlock] = useUrlState("block");
  const [round, setRound] = useUrlState("round");
  const [plot, setPlot] = useUrlState("plot");
  const [page, setPage] = useUrlState("page");
  const [minDebtAmount, setMinAmount] = useUrlState("minDebtAmount");
  const [maxDebtAmount, setMaxAmount] = useUrlState("maxDebtAmount");
  const [getPlots, { data, loading: loadingPlots, refetch }] =
    useLazyQuery(GET_PLOTS);

  const __getPlots = async () => {
    await getPlots({
      variables: {
        ...(region ? { region } : {}),
        ...(district ? { district } : {}),
        ...(block ? { block } : {}),
        ...(round ? { round } : {}),
        ...(plot ? { plot } : {}),
      },
    }).then(() => {
      setPage(1);
    });
  };

  const setPreviousPage = () => {
    setPage(lodash.max([1, page - 1]));
  };
  const setNextPage = () => {
    setPage(lodash.min([data?.plots?.length, page + 1]));
  };
  const previousEnabled = useMemo(() => page > 1, [page]);
  const nextEnabled = useMemo(
    () => page < data?.plots?.length,
    [page, data?.plots?.length]
  );

  const currentPlot = useMemo(() => data?.plots?.[page], [data?.plots, page]);

  const readingSheetRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => readingSheetRef.current,
    documentTitle: currentPlot?.code,
    bodyClass: "w-[1600px]",
  });

  const resetReadings = () => {
    form.setFieldValue(
      "readings",
      form.values.readings.map((reading: any) => ({
        ...reading,
        result: {
          readingDate: "",
          readingValue: "",
          readingAnomaly: false,
          readingNoTrace: false,
          readingNotes: "",
        },
      }))
    );
  };

  useEffect(() => {
    if (form.values.readings.length) {
      ls.set(
        `subs:readingsheet:${params.readingSet}`,
        { readings: form.values.readings, timestamp: new Date().getTime() },
        { encrypt: true, ttl: 60 * 60 * 12 }
      );
    }
  }, [form.values.readings]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <Header />
      <div className="flex-1 flex flex-col py-4 sm:py-6 px-4 space-y-6 sm:px-6 overflow-hidden light text-sm">
        <div className="flex flex-row items-center">
          <div className="sm:flex space-x-2">
            <RegionPicker
              id="region"
              labelHidden={true}
              setFieldTouched={() => {}}
              setFieldError={() => {}}
              setFieldValue={(_field: string, value: string) => {
                setRegion(value||undefined);
                setDistrict(undefined);
                setBlock(undefined);
                setRound(undefined);
                setPlot(undefined);
              }}
              values={{ region }}
              rawId={true}
            />
            {region && (
              <DistrictPicker
                id="district"
                labelHidden={true}
                setFieldTouched={() => {}}
                setFieldError={() => {}}
                values={{ district }}
                filter={{ region }}
                placeholder="All Districts"
                setFieldValue={(_field: string, value: string) => {
                  setDistrict(value||undefined);
                  setBlock(undefined);
                  setRound(undefined);
                  setPlot(undefined);
                }}
                rawId={true}
              />
            )}
            {district && (
              <BlockPicker
                id="block"
                labelHidden={true}
                setFieldTouched={() => {}}
                setFieldError={() => {}}
                values={{ block }}
                filter={{ district }}
                placeholder="All Blocks"
                setFieldValue={(_field: string, value: string) => {
                  setBlock(value||undefined);
                  setRound(undefined);
                  setPlot(undefined);
                }}
                rawId={true}
              />
            )}
            {block && (
              <RoundPicker
                id="round"
                labelHidden={true}
                setFieldTouched={() => {}}
                setFieldError={() => {}}
                values={{ round }}
                filter={{ block }}
                placeholder="All Rounds"
                setFieldValue={(_field: string, value: string) => {
                  setRound(value||undefined);
                  setPlot(undefined);
                }}
                rawId={true}
              />
            )}
            {round && (
              <PlotPicker
                id="plot"
                labelHidden={true}
                setFieldTouched={() => {}}
                setFieldError={() => {}}
                placeholder="All Plots"
                setFieldValue={(_field: string, value: string) =>
                  setPlot(value||undefined)
                }
                values={{ plot }}
                filter={{ round }}
                rawId={true}
              />
            )}
            <TextInput
              id={"minDebtAmount"}
              labelHidden={true}
              values={{ minDebtAmount }}
              type="number"
              step={0.01}
              placeholder="Minimum Debt Amount"
              handleChange={wrapOnchange(setMinAmount)}
              handleBlur={undefined}
            />
            <TextInput
              id={"maxDebtAmount"}
              labelHidden={true}
              values={{ maxDebtAmount }}
              type="number"
              step={0.01}
              placeholder="Maximum Debt Amount"
              handleChange={wrapOnchange(setMaxAmount)}
              handleBlur={undefined}
            />
            <button
              type="button"
              disabled={loadingPlots}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(__getPlots)}
            >
              {loadingPlots ? "Loading Dockets..." : "Load Dockets"}
            </button>
          </div>
        </div>
        <div className="flex-1 overflow-y-auto flex-col">
          {currentPlot && (
            <DocketDetails
              ref={readingSheetRef}
              plot={currentPlot}
              minDebtAmount={minDebtAmount}
              maxDebtAmount={maxDebtAmount}
            />
          )}
        </div>
        <nav
          className={classNames(
            "border",
            "bg-white dark:bg-gray-800 px-4 py-3 flex items-center justify-between  border-gray-200 dark:border-gray-700 sm:px-6 sm:rounded-lg",
            "justify-self-end flex-shrink-0"
          )}
          aria-label="Pagination"
        >
          <div className="hidden sm:block">
            <p className="text-sm text-gray-700 dark:text-gray-200">
              {data?.plots?.length > 0 ? (
                <>
                  Showing Plot{" "}
                  <span className="font-medium text-gray-900">{page}</span> of{" "}
                  <span className="font-medium text-gray-900">
                    {numeral(data?.plots?.length).format("0,0")}
                  </span>
                </>
              ) : (
                "No"
              )}{" "}
              Plots
            </p>
          </div>
          <div className="flex-1 flex justify-between sm:justify-end">
            <button
              type="button"
              disabled={!previousEnabled}
              onClick={wrapClick(setPreviousPage)}
              className={classNames(
                previousEnabled
                  ? "bg-white dark:bg-gray-800 hover:bg-gray-50 hover:dark:bg-gray-900 cursor-pointer"
                  : "cursor-not-allowed bg-gray-100 dark:bg-gray-900",
                "relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-md text-gray-700 dark:text-gray-200"
              )}
            >
              Previous Plot
            </button>
            <button
              type="button"
              disabled={!nextEnabled}
              onClick={wrapClick(setNextPage)}
              className={classNames(
                nextEnabled
                  ? "bg-white  dark:bg-gray-800 hover:bg-gray-50 hover:dark:bg-gray-900 cursor-pointer"
                  : "cursor-not-allowed bg-gray-100 dark:bg-gray-900",
                "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 text-sm font-medium rounded-md text-gray-700 dark:text-gray-200"
              )}
            >
              Next Plot
            </button>
            <button
              type="button"
              onClick={wrapClick(handlePrint)}
              className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              <PrinterIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              Print Plot
            </button>
          </div>
        </nav>
      </div>
    </main>
  );
};

export default ReadingsPage;
