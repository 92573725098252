import { FC } from "react"
import { ICurrentConfig } from "apollo/cache/config";
import { wrapImage } from "utils";

const PropertyInfoSummary: FC<{ data: any; config: ICurrentConfig }> = ({ data, config: { dateFormat } }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    {/* <div className=''>
      <span className="text-xs font-light">Location Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Ghana Post Address
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.ghanaPostAddress || "N/A"}
          </div>
        </div>
      </div>
    </div> */}
    <div className=''>
      <span className="text-xs font-light">Premise Information</span>
      <div className='grid gap-6 mt-2'>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Premise Images
          </span>
          <div className='mt-2 grid grid-cols-3 gap-3'>
            {data?.propertyImageUrls?.map((image: string) => wrapImage(
              <img
                src={image}
                alt={"back"}
                className="w-full h-56 text-xs object-cover object-center rounded"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PropertyInfoSummary