import { gql, useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import moment from "moment";
import lodash from "lodash";
import { FC, forwardRef, useEffect } from "react";
import { useMatch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { classNames } from "utils";
import numeral from "numeral";
import ls from "localstorage-slim";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import Loader from "components/layouts/loader";
import { currentConfigVar } from "apollo/cache/config";

const GET_SERVICE_POINTS = gql`
  query GetAllServicePoints($sort: String, $plot: ID) {
    servicePoints: getServicePoints(
      sort: $sort
      plot: $plot
      serviceTypes: [AMR, Postpaid]
      page: 0
      pageSize: 0
    ) {
      _id
      code
      geoCode
      customer {
        _id
        code

        organization {
          name
        }
        representative {
          fullName
          phoneNumber
          ghanaCardNumber
        }
      }
      meterCode
      accountCode
      account {
        _id
        code
        balance
        balanceUpdatedAt
        meta {
          lastPaymentDate
          lastPaymentAmount
          lastBillCycle
        }
      }
      meterLocation
      contractedDemand
      property {
        _id
        qrCode
        ghanaPostAddress
        address
        regionCode
        regionName
        districtCode
        districtName
        blockCode
        blockName
        roundCode
        roundName
      }
      propertyCode
      tariffClass {
        _id
        code
        name
      }
      qrCode
      meta {
        lastReadingDate
        lastReadingValue
        lastBillDate
        lastBillValue
      }
      status
      createdAt
      updatedAt
    }
  }
`;

const READ_READING_SET = gql`
  mutation ReadReadingSet($id: ID!, $readings: [ReadReadingSetReadingInput!]!) {
    readReadingSet(id: $id, readings: $readings) {
      _id
    }
  }
`;

interface DocketDetailsProps {
  ref: any;
  plot: {
    roundCode: string;
    blockCode: string;
    regionName: string;
    districtName: string;
    _id: string;
    code: string;
    community: string;
    meta: any;
  };
  minDebtAmount: string;
  maxDebtAmount: string;
}

const DocketDetails: FC<DocketDetailsProps> = forwardRef<
  HTMLTableElement,
  DocketDetailsProps
>(({ plot, minDebtAmount, maxDebtAmount }, ref) => {
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const [getServicePoints, { refetch, loading }] =
    useLazyQuery(GET_SERVICE_POINTS);
  const [readReadingSet] = useMutation(READ_READING_SET);

  const form = useFormik({
    initialValues: {
      servicePoints: [] as {
        _id: string;
        result: object;
      }[],
    },
    validationSchema: Yup.object().shape({
      servicePoints: Yup.array().of(
        Yup.object().shape({
          _id: Yup.string().required(),
          result: Yup.object().shape({
            readingValue: Yup.number().when(
              ["readingAnomaly", "readingNoTrace"],
              {
                is: (readingAnomaly: boolean, readingNoTrace: boolean) =>
                  readingAnomaly === false && readingNoTrace === false,
                then: (schema) => schema.min(0).required(),
                otherwise: (schema) => schema.notRequired(),
              }
            ),
            readingDate: Yup.date().max(moment().toDate()).required(),
            readingAnomaly: Yup.boolean().required(),
            readingNoTrace: Yup.boolean().required(),
            readingNotes: Yup.string().when("readingAnomaly", {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          }),
        })
      ),
    }),
    onSubmit: async (values) => {
      console.log("submit", values);
      await readReadingSet({
        variables: {
          id: params.readingSet,
          servicePoints: values.servicePoints.map((reading) => ({
            _id: reading._id,
            result: reading.result,
          })),
        },
      }).then(({ data }) => {
        if (data?.readReadingSet?._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Reading set uploaded successfully",
            })
          );
          refetch?.();
          navigate({ to: "/reading-sets", replace: true });
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not upload reading set",
            })
          );
        }
      });
    },
  });

  useEffect(() => {
    getServicePoints({
      variables: {
        plot: plot._id,
        sort: "code",
      },
    })
      .then(({ data }) => {
        if (data?.servicePoints) {
          //combine reading sheet data with local data if any
          // const localReadingSheet = ls.get<{
          //   servicePoints: any[];
          //   timestamp: string;
          // }>(`subs:servicepoints:${plot?.code}`, { decrypt: true });
          // if (
          //   localReadingSheet?.servicePoints?.length &&
          //   moment(localReadingSheet.timestamp).isAfter(
          //     moment(data?.readingSheet?.readingSet?.updatedAt)
          //   )
          // ) {
          //   form.setFieldValue("servicePoints", localReadingSheet.servicePoints);
          // } else {
          form.setFieldValue(
            "servicePoints",
            lodash
              .chain(data?.servicePoints)
              .thru((items) =>
                minDebtAmount
                  ? items.filter(
                    (servicePoint: any) =>
                      parseFloat(servicePoint?.account?.balance ?? "0") >=
                      parseFloat(minDebtAmount)
                  )
                  : items
              )
              .thru((items) =>
                maxDebtAmount
                  ? items.filter(
                    (servicePoint: any) =>
                      parseFloat(servicePoint?.account?.balance ?? "0") <=
                      parseFloat(maxDebtAmount)
                  )
                  : items
              )
              ?.map((reading: any) => ({
                ...reading,
                result: {
                  readingDate: "",
                  readingValue: "",
                  readingAnomaly: false,
                  readingNoTrace: false,
                  readingNotes: "",
                },
              }))
              .value()
          );
          // }
        }
      })
      .catch((err) => { });
  }, [plot._id, minDebtAmount, maxDebtAmount]);

  useEffect(() => {
    if (form.values?.servicePoints?.length) {
      ls.set(
        `subs:servicepoints:${plot?.code}`,
        {
          servicePoints: form.values.servicePoints,
          timestamp: new Date().getTime(),
        },
        { encrypt: true, ttl: 60 * 60 * 12 }
      );
    }
  }, [form.values.servicePoints]);

  if (loading) {
    return (
      <div className='flex-1 h-full flex items-center justify-center'>
        <Loader />
      </div>
    );
  }
  return (
    <table
      ref={ref}
      className='w-full divide-y divide-gray-300 border border-gray-300 rounded-md table-fixed'
    >
      <thead className='bg-gray-50'>
        <tr className=''>
          <th scope='col' className='w-10 h-0 p-0' />
          <th scope='col' className='h-0 p-0' />
          <th scope='col' className='h-0 p-0' />
          <th scope='col' className='h-0 p-0' />
          <th scope='col' className='h-0 p-0' />
          <th scope='col' className='h-0 p-0' />
          <th scope='col' className='h-0 p-0' />
          <th scope='col' className='h-0 p-0' />
          <th scope='col' className='h-0 p-0' />
        </tr>
        <tr>
          <th
            colSpan={9}
            scope='col'
            className='border-b border-gray-300 text-sm'
          >
            <div className='flex p-3 items-center print:space-x-4'>
              <div className='bg-primary-800 hidden print:flex items-center justify-center h-12 w-12 p-0.5'>
                <img
                  className=' w-auto bg-white p-0.5'
                  src={require("../../assets/logo.png")}
                  alt='SUBS'
                />
              </div>
              <div className='grid grid-cols-5 gap-3 text-left flex-1'>
                <div className='flex space-x-2 items-center'>
                  <dt className='font-semibold text-gray-900'>Region:</dt>
                  <dd className='col-span-2 font-light'>{plot?.regionName}</dd>
                </div>
                <div className='flex space-x-2 items-center'>
                  <dt className='font-semibold text-gray-900'>District:</dt>
                  <dd className='col-span-2 font-light'>
                    {plot?.districtName}
                  </dd>
                </div>
                <div className='flex space-x-2 items-center'>
                  <dt className='font-semibold text-gray-900'>Plot:</dt>
                  <dd className='col-span-2 font-light'>{plot?.code}</dd>
                </div>
                <div className='flex space-x-2 items-center'>
                  <dt className='font-semibold text-gray-900'>Customers:</dt>
                  <dd className='col-span-2 font-light'>
                    {form.values?.servicePoints?.length}
                  </dd>
                </div>
                <div className='flex space-x-2 items-center'>
                  <dt className='font-semibold text-gray-900 col-span-2'>
                    Generated At:
                  </dt>
                  <dd className='col-span font-light'>
                    {moment().format("DD/MM/YYYY")}
                  </dd>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr className='bg-white'>
          <th
            colSpan={9}
            scope='col'
            className='h-4 border-b border-gray-300'
          />
        </tr>
        <tr className=''>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-2 pb-0.5 text-center text-sm font-semibold text-gray-900 w-10'
          >
            No
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900'
          >
            Account No.
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900'
          >
            Geo Code
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900'
          >
            Customer Name
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900'
          >
            Last Billing Cycle
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-2 pb-0.5 text-right text-sm font-semibold text-gray-900'
          >
            Last Payment Amount (GHS)
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-2 pb-0.5 text-right text-sm font-semibold text-gray-900'
          >
            Debt Balance (GHS)
          </th>
          <th
            scope='col'
            colSpan={2}
            className='whitespace-nowrap px-1 pt-2 pb-0.5 text-left text-sm font-semibold text-gray-900'
          >
            Disconnection
          </th>
        </tr>
        <tr className=''>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900'
          ></th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900'
          >
            Service Point No.
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900'
          >
            Meter No.
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900'
          >
            Customer Phone
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900'
          >
            Tariff Class
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-0.5 pb-2 text-right text-sm font-semibold text-gray-900'
          >
            Last Payment Date
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-0.5 pb-2 text-right text-sm font-semibold text-gray-900'
          >
            Debt Balance Updated At
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900'
          >
            Date
          </th>
          <th
            scope='col'
            className='whitespace-nowrap px-1 pt-0.5 pb-2 text-left text-sm font-semibold text-gray-900'
          >
            Reading
          </th>
        </tr>
      </thead>
      <tbody className='bg-white'>
        {lodash
          .chain(form.values.servicePoints)
          ?.map?.((step: any, idx: string) => (
            <>
              <tr key={idx + "a"} className=''>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-70 text-center'>
                  {numeral(idx + 1).format("0,0")}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.accountCode || "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.geoCode?.split?.("-")?.join?.("") || "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.customer?.representative?.fullName ||
                    step?.customer?.organization?.name ||
                    step?.customer?.representative?.fullName ||
                    "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.account?.meta?.lastBillCycle || "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py- text-right 0 text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {numeral(step?.account?.meta?.lastPaymentAmount).format(
                    "0,0.00"
                  ) || "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-right text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {numeral(step?.account?.balance).format("0,0.00") || "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 pt-0.5 text-sm h-[26px] text-gray-900'>
                  <div className='flex-1 flex space-x-1 items-center'>
                    <input
                      key={idx}
                      type={"checkbox"}
                      id={`servicePoints.[${idx}].result.readingAnomaly`}
                      name={`servicePoints.[${idx}].result.readingAnomaly`}
                      checked={lodash.get(
                        form.values.servicePoints,
                        `[${idx}].result.readingAnomaly`,
                        false
                      )}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      className={classNames(
                        lodash.get(
                          form.errors,
                          `servicePoints.[${idx}].result.readingAnomaly`
                        ) &&
                          lodash.get(
                            form.touched,
                            `servicePoints.[${idx}].result.readingAnomaly`
                          )
                          ? "focus:ring-red-500 focus:border-red-500 border-red-600 border"
                          : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "block sm:text-sm placeholder:font-light placeholder:text-sm h-[20px] w-[20px] border border-gray-300 focus:border print:hidden"
                      )}
                    />
                    <input
                      className={classNames(
                        "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "hidden sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] w-[24px] border border-gray-300 focus:border print:block"
                      )}
                    />
                    <label
                      htmlFor={`[${idx}].result.readingAnomaly`}
                      className='text-xs'
                    >
                      Visited
                    </label>
                  </div>
                </td>
                <td className='whitespace-nowrap px-1 py-0 pt-0.5 text-sm h-[26px] text-gray-900'>
                  <div className='flex-1 flex space-x-1 items-center'>
                    <input
                      key={idx}
                      type={"checkbox"}
                      id={`servicePoints.[${idx}].result.readingAnomaly`}
                      name={`servicePoints.[${idx}].result.readingAnomaly`}
                      checked={lodash.get(
                        form.values.servicePoints,
                        `[${idx}].result.readingAnomaly`,
                        false
                      )}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                      className={classNames(
                        lodash.get(
                          form.errors,
                          `servicePoints.[${idx}].result.readingAnomaly`
                        ) &&
                          lodash.get(
                            form.touched,
                            `servicePoints.[${idx}].result.readingAnomaly`
                          )
                          ? "focus:ring-red-500 focus:border-red-500 border-red-600 border"
                          : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "block sm:text-sm placeholder:font-light placeholder:text-sm h-[20px] w-[20px] border border-gray-300 focus:border print:hidden"
                      )}
                    />
                    <input
                      className={classNames(
                        "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                        "hidden sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] w-[24px] border border-gray-300 focus:border print:block"
                      )}
                    />
                    <label
                      htmlFor={`[${idx}].result.readingAnomaly`}
                      className='text-xs'
                    >
                      Disconnected
                    </label>
                  </div>
                </td>
              </tr>
              <tr key={idx + "b"} className=''>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-900'></td>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.code || "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.meterCode || "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.customer?.representative?.phoneNumber ||
                    step?.customer?.representative?.phoneNumber ||
                    "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.tariffClass?.name || "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-right text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.account?.meta?.lastPaymentDate
                    ? moment(step?.account?.meta?.lastPaymentDate).format(
                      dateFormat
                    )
                    : "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 text-right text-sm h-[26px] text-gray-900 overflow-hidden text-ellipsis'>
                  {step?.account?.balanceUpdatedAt
                    ? moment(step?.account?.balanceUpdatedAt).format(dateFormat)
                    : "N/A"}
                </td>
                <td className='whitespace-nowrap px-1 py-0 pb-0.5 text-sm h-[26px] text-gray-900'>
                  <input
                    key={idx}
                    type={"date"}
                    id={`servicePoints.[${idx}].result.readingDate`}
                    name={`servicePoints.[${idx}].result.readingDate`}
                    value={lodash.get(
                      form.values.servicePoints,
                      `[${idx}].result.readingDate`,
                      ""
                    )}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    max={moment().format("YYYY-MM-DD")}
                    className={classNames(
                      lodash.get(
                        form.errors,
                        `servicePoints.[${idx}].result.readingDate`
                      ) &&
                        lodash.get(
                          form.touched,
                          `servicePoints.[${idx}].result.readingDate`
                        )
                        ? "focus:ring-red-500 focus:border-red-500 border-red-600 border"
                        : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                      "block w-full sm:text-sm placeholder:font-light p-0 px-1 placeholder:text-sm h-[24px] border border-gray-300 focus:border print:hidden"
                    )}
                  />
                  <input
                    className={classNames(
                      "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                      "hidden w-full sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] border border-gray-300 focus:border print:block"
                    )}
                  />
                </td>
                <td className='whitespace-nowrap px-1 py-0 pb-0.5 text-sm h-[26px] text-gray-900'>
                  <input
                    key={idx}
                    type={"number"}
                    step={1}
                    id={`servicePoints.[${idx}].result.readingValue`}
                    name={`servicePoints.[${idx}].result.readingValue`}
                    value={lodash.get(
                      form.values.servicePoints,
                      `[${idx}].result.readingValue`,
                      0
                    )}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    className={classNames(
                      lodash.get(
                        form.errors,
                        `servicePoints.[${idx}].result.readingValue`
                      ) &&
                        lodash.get(
                          form.touched,
                          `servicePoints.[${idx}].result.readingValue`
                        )
                        ? "focus:ring-red-500 focus:border-red-500 border-red-600 border"
                        : "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                      "block w-full sm:text-sm placeholder:font-light p-0 px-1 placeholder:text-sm h-[24px] border border-gray-300 focus:border print:hidden"
                    )}
                  />
                  <input
                    className={classNames(
                      "focus:ring-primary-500 focus:border-primary-500 border-gray-200",
                      "hidden w-full sm:text-sm placeholder:font-light placeholder:text-sm h-[24px] border border-gray-300 focus:border print:block"
                    )}
                  />
                </td>
              </tr>
              <tr key={idx + "c"} className=''>
                <td className='h-[0.5px] p-0 bg-gray-300' colSpan={9} />
              </tr>
            </>
          ))
          .value()}
      </tbody>
      <tfoot className='bg-gray-50'>
        <tr className='hidden print:table-row'>
          <th scope='col' className='' />
          <th scope='col' className='' />
          <th scope='col' className='' />
          <th scope='col' className='' />
          <th scope='col' className='' />
          <th
            scope='col'
            colSpan={2}
            className='whitespace-nowrap px-1 py-2 pt-10 text-sm font-semibold text-gray-900'
          >
            <div className=' inline-flex w-full'>
              Exercise Date:
              <div className='ml-2 flex-1 border-b border-gray-300 text-transparent'>
                date
              </div>
            </div>
          </th>
          <th
            scope='col'
            colSpan={2}
            className='whitespace-nowrap px-1 py-2 pt-10 text-sm font-semibold text-gray-900'
          >
            <div className=' inline-flex w-full'>
              Staff Signature:
              <div className='ml-2 flex-1 border-b border-gray-300 text-transparent'>
                signature
              </div>
            </div>
          </th>
        </tr>
      </tfoot>
    </table>
  );
});

export default DocketDetails;
