import { TextArea } from "components"
import { FormikProps } from "formik";
import { FC } from "react";


type Props = {
    form: FormikProps<any>;
};

const EscalateComplaintForm: FC<Props> = ({ form }) => {
    return (
        <div className="space-y-6 divide-y no-scrollbar divide-gray-200 flex-1 overflow-y-auto">
            <div className=''>
                <span className="text-xs font-light">Escalation Reason</span>
                <div className="grid grid-cols-2 gap-6 mt-2">
                    <div className='col-span-2'>
                        <TextArea
                            id="notes"
                            label="Notes"
                            placeholder="e.g. Connected the cables underground from next door"
                            {...form} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EscalateComplaintForm;