import { FC } from "react";
import { SearchSelectInput } from "components/core";
import { PremiseCategory } from "apollo/data";

interface ActivityPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
}


const ActivityPickerContainer: FC<ActivityPickerContainerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {
  return (
    <SearchSelectInput
      id={id ?? "activity"}
      label={label ?? "Activity"}
      placeholder="Select Activity"
      options={PremiseCategory?.filter((el) => el?.type === "Activity")?.map(
        (activity: any) => ({
          label: {
            title: activity.name as string,
          },
          value: rawId ? activity?._id : activity,
        })
      )}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default ActivityPickerContainer;
