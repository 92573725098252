import { FC } from 'react'
import { Avatar } from 'components/core';
import { classNames, wrapImage } from 'utils';
import numeral from 'numeral';
import moment from 'moment';
import lodash from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapProvider, Marker } from "react-map-gl";
import config from 'config';

interface ReadingDetialsViewProps {
  reading: {
    code: string;
    previousReading: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
      totalAmountDueUpdatedAt: Date;
    };
    currentReading: {
      readingDate: Date;
      readingValue: number;
      readingEstimated: boolean;
      consumptionValue: number;
      billValue: number;
      totalAmountDue: number;
      totalAmountDueUpdatedAt: Date;
    };
    meta: {
      readingImageUrl: string;
      readingLocation: {
        coordinates: [number, number]
      };
      readingImageLocation: {
        coordinates: [number, number]
      }
    };
    bill: {
      outstandingAmount: number;
      totalAmountDue: number;
      consumptionPeriod: number;
      currentBill: {
        readingValue: number;
        consumptionValue: number;
      };
      previousBill: {
        readingValue: number;
        consumptionValue: number;
      };
      currentBillAmount: number;
      bills: {
        currentDate: string;
        previousDate: string;
        tariffInstance: {
          _id: string;
        };
        numberOfDays: number;
        consumption: number;
        billItems: {
          title: string;
          unit: string;
          quantity: number;
          unitCost: number;
          cost: number;
        }[];
        billAmount: number;
      }[];
    };
  };
}

const ReadingDetialsView: FC<ReadingDetialsViewProps> = ({ reading }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Reading Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Reading Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.previousReading?.readingDate ? moment(reading.previousReading.readingDate).format(dateFormat) : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Reading Value
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {numeral(reading?.previousReading?.readingValue || 0).format("0,0")} KWh
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Last Reading Estimated?
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.previousReading?.readingEstimated ? "Yes" : "No"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Outstanding Amount
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              GHS {numeral(reading?.previousReading?.totalAmountDue || 0).format("0,0.00")}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Current Reading Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.currentReading?.readingDate ? moment(reading.currentReading.readingDate).format(dateFormat) : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Current Reading Value
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {reading?.currentReading?.readingValue ? `${numeral(reading.currentReading.readingValue || 0).format("0,0")} KWh` : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Current Reading Estimated?
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {lodash.isBoolean(reading?.currentReading?.readingEstimated) ? reading?.currentReading?.readingEstimated ? "Yes" : "No" : "N/A"}
            </div>
          </div>
          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Reading Image
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {wrapImage(
                <img
                  src={reading?.meta?.readingImageUrl}
                  alt="reading"
                  className="h-64 w-full object-cover object-center"
                />
              )}
            </div>
          </div>
          <div className='col-span-2'>
            <span className="block text-sm font-light text-gray-700">
              Reading Location
            </span>
            <div className="mt-1 block w-full sm:text-sm h-64">
              <MapProvider>
                <Map
                  id="service-location"
                  initialViewState={{
                    longitude: reading?.meta?.readingLocation?.coordinates?.[0],
                    latitude: reading?.meta?.readingLocation?.coordinates?.[1],
                    zoom: 15
                  }}
                  mapStyle="mapbox://styles/mapbox/streets-v12"
                  mapboxAccessToken={config.mapbox.token}
                >
                  <Marker
                    longitude={reading?.meta?.readingLocation?.coordinates?.[0]}
                    latitude={reading?.meta?.readingLocation?.coordinates?.[1]}
                  >
                    <img
                      className="w-6 h-6"
                      src={require("assets/marker.png")}
                      alt={"pin"}
                    />
                  </Marker>
                  <Marker
                    longitude={reading?.meta?.readingImageLocation?.coordinates?.[0]}
                    latitude={reading?.meta?.readingImageLocation?.coordinates?.[1]}
                  >
                    <img
                      className="w-6 h-6"
                      src={require("assets/marker.png")}
                      alt={"pin"}
                    />
                  </Marker>
                </Map>
              </MapProvider>
            </div>
          </div>
        </div>
      </div>
      {reading?.bill && (
        <div className='pt-6'>
          <span className='text-xs font-light'>Bill Preview</span>
          <div className='mt-2 border-gray-300 border p-4 border-dashed bg-gray-50'>
            <div className='grid grid-cols-4 gap-6'>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Previous Reading
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(reading?.bill?.previousBill?.readingValue || 0).format("0,0")} KWh
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Current Reading
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(reading?.bill?.currentBill?.readingValue || 0).format("0,0")} KWh
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Consumption
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {numeral(reading?.bill?.currentBill?.consumptionValue || 0).format("0,0")} KWh
                </div>
              </div>

              <div>
                <span className="block text-sm font-light text-gray-700">
                  Consumption Period
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {reading?.bill?.consumptionPeriod || 0} Days
                </div>
              </div>


            </div>
            <table className="min-w-full divide-y divide-gray-300 mt-4">
              <thead>
                <tr className='bg-primary-600'>
                  <th
                    scope="col"
                    colSpan={2}
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-4"
                  >
                    Items
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
                  >
                    Units
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-white sm:table-cell"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-white sm:pr-4"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {reading?.bill?.bills?.map?.((billX) => (
                  <>
                    <tr className="border-b border-gray-200 bg-yellow-50">
                      <td colSpan={5} className="py-3 pl-4 pr-3 text-sm text-gray-600 sm:pl-4">
                        {moment(billX?.previousDate).format(dateFormat)} - {moment(billX?.currentDate).format(dateFormat)} ({numeral(billX?.numberOfDays).format("0,0")} days)
                      </td>
                    </tr>
                    {billX?.billItems?.map((item, idx) => (
                      <tr key={idx} className="border-b border-gray-200">
                        <td colSpan={2} className="py-4 pl-4 pr-3 text-sm sm:pl-4">
                          <div className="font-medium text-gray-900">{item.title}</div>
                        </td>
                        <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{item.unit}</td>
                        <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{numeral(item.unitCost).format("0,0.0000")}</td>
                        <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-4">{numeral(item.cost).format("0,0.00")}</td>
                      </tr>
                    ))}
                  </>
                ))}
                <tr className="border-b border-transparent">
                  <td colSpan={5} className="h-6" />
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th
                    scope="row"
                    // colSpan={3}
                    className="hidden pl-6 pr-3 pt-6 pb-2.5 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                  >
                    Current Bill
                  </th>
                  <th scope="row" className="pl-4 pr-3 pt-6 pb-2.5 text-left text-sm font-normal text-gray-500 sm:hidden">
                    Current Bill
                  </th>
                  <td className="pl-3 pr-4 pt-6 pb-2.5 text-right text-sm text-gray-500 sm:pr-4">GHS {numeral(reading?.bill?.currentBillAmount).format("0,0.00")}</td>
                </tr>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th
                    scope="row"
                    // colSpan={3}
                    className="hidden pl-6 pr-3 py-2.5 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                  >
                    Previous Balance
                  </th>
                  <th scope="row" className="pl-4 pr-3 py-2.5 text-left text-sm font-normal text-gray-500 sm:hidden">
                    Previous Balance
                  </th>
                  <td className="pl-3 pr-4 py-2.5 text-right text-sm text-gray-500 sm:pr-4">GHS  {numeral(reading?.bill?.outstandingAmount).format("0,0.00")}</td>
                </tr>
                <tr>
                  <th />
                  <th />
                  <th />
                  <th
                    scope="row"
                    // colSpan={3}
                    className="bg-primary-600 hidden pl-6 pr-3 py-3 text-right text-sm font-semibold text-white sm:table-cell md:pl-0"
                  >
                    Amount Payable
                  </th>
                  <th scope="row" className="bg-primary-600 pl-4 pr-3 py-3 text-left text-sm font-semibold text-white sm:hidden">
                    Amount Payable
                  </th>
                  <td className="bg-primary-600 pl-3 pr-4 py-3 text-right text-sm font-semibold text-white sm:pr-4">
                    GHS {numeral(reading?.bill?.totalAmountDue).format("0,0.00")}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReadingDetialsView