import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import ReadingInfoSummary from "components/forms/resolve-installation-service-order/reading-info-summary";
import ServiceInfoSummary from "components/forms/resolve-installation-service-order/service-info-summary";
import { FC } from "react";

interface InvestigationServiceOrderResolutionViewProps {
  resolution: {
    property: any;
    service: any;
    reading: any;
    notes: string;
  };
}

const InvestigationServiceOrderResolutionView: FC<
  InvestigationServiceOrderResolutionViewProps
> = ({ resolution }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="p-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Service Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about property</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <ServiceInfoSummary data={resolution?.service} />
        </div>
      </div>
      <div className="pt-6 p-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-6 font-normal text-gray-900 dark:text-gray-100">
              Reading Infomation
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about property</p>
          </div>
          <div />
        </div>
        <div className="mt-6">
          <ReadingInfoSummary
            data={{
              readingDate: resolution?.reading?.readingDate,
              readingValue: resolution?.reading?.readingValue,
              notes: resolution?.notes,
              appliances: [],
              installationType: null,
              materials: [],
            }}
            config={currentConfig}
          />
        </div>
      </div>
    </div>
  );
};

export default InvestigationServiceOrderResolutionView;
