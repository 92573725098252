
import { Complaints, ComplaintScopes } from 'apollo/data';
import { SelectInput, TextArea, UploadArea } from 'components/core'
import { ComplaintCategoryPicker, ComplaintTypePicker } from 'containers';
import { useFormik } from 'formik'
import lodash from 'lodash';
import { FC } from 'react'
import { wrapClick } from 'utils';
import * as Yup from "yup";

interface ComplaintFormProps {
  handleNext: (values: any) => void
  handlePrevious: () => void;
  initialValues: any;
  values: any;
  handleCancel: () => void;
  parentForm: any;
}

const complaintInfoValidation = Yup.object().shape({
  category: Yup.object().nullable().notRequired(),
  type: Yup.object().nullable().required("Required"),
  description: Yup.string().required("Required"),
  supportingImageUrls: Yup.array().of(Yup.string()).when('type', {
    is: (type: any) => type?.requiresDocument,
    then: Yup.array().min(1, "Atleast an image to support your complaint").required("Atleast an image to support your complaint"),
    otherwise: Yup.array().notRequired()
  }),
})

const ComplaintForm: FC<ComplaintFormProps> = ({ initialValues, handleNext, handlePrevious, handleCancel, parentForm }) => {


  const form = useFormik({
    initialValues,
    validationSchema: complaintInfoValidation,
    onSubmit: (values) => {
      handleNext(values)
    },
    onReset: () => {
      handleCancel?.();
    }
  })

  return (
    <form onSubmit={form.handleSubmit} className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Contract Information</span>
          <div className="grid grid-cols-2 gap-6 mt-2">
            <div className="space-y-1">
              <ComplaintCategoryPicker
                id="category"
                label="Complaint Category"
                {...form}
              />
            </div>
            {/* {JSON.stringify(ComplaintScopes, null, 2)} */}
            <div>
              <ComplaintTypePicker
                id="type"
                label="Complaint Type"
                filter={{ category: form.values.category?.name }}
                {...form}
              />
            </div>


            <div className='col-span-2'>
              <TextArea
                id="description"
                label="Description"
                placeholder="e.g. Connected the cables underground from next door"
                {...form} />
            </div>

            <div className='col-span-2'>
              <UploadArea label='Supporting Images' multiple id={'supportingImageUrls'} {...form} />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default ComplaintForm