
import { FC, useMemo } from 'react'
import { useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { MinusIcon } from '@heroicons/react/20/solid'
import MyResponsivePie from '../graphs/pie';
import numeral from 'numeral';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { currentConfigVar } from 'apollo/cache/config';
import lodash, { startCase } from 'lodash';
import { HomeIcon, BuildingStorefrontIcon, CreditCardIcon, ReceiptPercentIcon } from '@heroicons/react/24/outline'

const GET_CUSTOMERS_BY_GROUP = gql`
  query GetCustomersByGroupSummary($groupBy: CustomersByGroupSummaryGroupBy!, $region: ID, $district: ID) {
    summary: getCustomersByGroupSummary(groupBy: $groupBy, region: $region, district: $district) {
      label: _id
      count
    }
  }
`;

const getIconForGroup = (group: string) => {
  switch (group) {
    case "Residential": {
      return <HomeIcon className="h-6 w-6 text-white" aria-hidden="true" />
    }
    case "NonResidential": {
      return <BuildingStorefrontIcon className="h-6 w-6 text-white" aria-hidden="true" />
    }
    case "Industrial":
    case "SpecialTariffLoad": {
      return <HomeIcon className="h-6 w-6 text-white" aria-hidden="true" />
    }
    case "Prepaid": {
      return <CreditCardIcon className="h-6 w-6 text-white" aria-hidden="true" />
    }
    case "Postpaid": {
      return <ReceiptPercentIcon className="h-6 w-6 text-white" aria-hidden="true" />
    }
  }
}

const CustomersByGroupContainer: FC<{ groupBy: string }> = ({ groupBy }) => {

  const { pollInterval } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>()
  const { data } = useQuery(GET_CUSTOMERS_BY_GROUP, {
    variables: {
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      groupBy
    }
  });
  const totalCustomers = useMemo(() => lodash.sumBy(data?.summary, "count"), [data?.summary])

  return (
    <>
      <h3 className="text-base font-semibold leading-6 text-gray-900">Customers By {startCase(groupBy)}</h3>
      <div className="mt-5 flex items-center space-x-6 overflow-hidden rounded-lg bg-white p-4 shadow sm:p-6 h-full">
        <div className='flex-1 flex space-x-12'>
          <div className='flex-1 space-y-6 my-auto'>
            {lodash.map(data?.summary, (item: any) => (
              <div key={item?.label} className="rounded-lg bg-white p-2 flex space-x-4">
                <div>
                  <div className="rounded-md bg-primary-600 p-2.5">
                    {getIconForGroup(item.label)}
                  </div>
                </div>
                <div className="flex flex-col justify-between h-full flex-1 text-left space-y-2">
                  <div>
                    <dt className="truncate text-xs text-gray-500">{item?.label}</dt>
                    <dd className="flex items-baseline justify-between">
                      <p className="text-xl font-normal text-gray-900">{numeral(item?.count).format("0,0")}</p>
                      <p className='text-gray-600 ml-2 flex items-baseline text-xs font-semibold'>
                        <MinusIcon className="h-4 w-4 flex-shrink-0 self-center text-gray-500" aria-hidden="true" />
                        {numeral(item?.count / totalCustomers * 100).format("0,0.00")}%
                      </p>
                    </dd>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='w-80 h-80'>
            <MyResponsivePie
              data={data?.summary?.map?.((item: any) => ({
                id: item?.label,
                label: item?.label,
                value: item?.count,
                color: item?.label,
              })) || []}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomersByGroupContainer;
