import { gql, useMutation } from "@apollo/client";
import { Modal } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { wrapClick } from "utils";
import EscalateComplaintForm from "./forms/escalate";

const CLOSE_RESOLUTION = gql`
  mutation EscalateComplaint($id: ID!, $notes: String) {
    escalateComplaint(id: $id, notes: $notes) {
      _id
    }
  }
`;

export default function EscalateComplaintContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [closeTicket, { loading: closing }] = useMutation(CLOSE_RESOLUTION);
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();
  const form = useFormik<any>({
    initialValues: {
      notes: "",
    },
    onSubmit: async (values) => {
      closeTicket({
        variables: {
          id: searchParams.id || params.complaint,
          notes: values.notes,
        },
      }).then(({ data }) => {
        if (data.escalateComplaint._id) {
          refetch?.();
          setOpen(false);
          form.resetForm();
          toast(
            JSON.stringify({
              type: "success",
              title: "Ticket Escalated Successfully",
            })
          );
        }
      });
    },
    onReset: () => {
      form.resetForm();
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Escalate Ticket'
      size='4xl'
      description='Provide a reason for escalating this ticket. This will be sent to the customer as a response to their complaint.'
      renderActions={() => (
        <>
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {closing ? "Resolving ..." : "Escalate Ticket"}
          </button>
        </>
      )}
    >
      <EscalateComplaintForm form={form} />
    </Modal>
  );
}
