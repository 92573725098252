export default [
  {
    _id: "6428fc0ea07fa6c7667d6ef6",
    code: "AT02",
    type: "Activity",
    name: "Wholesale & Retail",
    subActivities: [
      {
        code: "AT0201",
        name: "Wholesale Trade",
        _id: "6428fc0ea07fa6c7667d6ef7",
      },
      {
        code: "AT0202",
        name: "Retail Trade",
        _id: "6428fc0ea07fa6c7667d6ef8",
      },
      {
        code: "AT0203",
        name: "Book Shop",
        _id: "6428fc0ea07fa6c7667d6ef9",
      },
      {
        code: "AT0204",
        name: "Supermarket",
        _id: "6428fc0ea07fa6c7667d6efa",
      },
      {
        code: "AT0205",
        name: "Mini Mart",
        _id: "6428fc0ea07fa6c7667d6efb",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d701c",
    code: "MP01",
    type: "MeterPhase",
    name: "1 Phase",
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.573Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.573Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f25",
    code: "AT06",
    type: "Activity",
    name: "Social Work",
    subActivities: [
      {
        code: "AT0601",
        name: "Red Cross",
        _id: "6428fc0ea07fa6c7667d6f26",
      },
      {
        code: "AT0602",
        name: "Private Cemetary",
        _id: "6428fc0ea07fa6c7667d6f27",
      },
      {
        code: "AT0603",
        name: "Old age homes",
        _id: "6428fc0ea07fa6c7667d6f28",
      },
      {
        code: "AT0604",
        name: "Funeral Homes",
        _id: "6428fc0ea07fa6c7667d6f29",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f34",
    code: "AT09",
    type: "Activity",
    name: "Small & Medium Enterprise",
    subActivities: [
      {
        code: "AT0901",
        name: "Hairdressers/Beauty Parlour",
        _id: "6428fc0ea07fa6c7667d6f35",
      },
      {
        code: "AT0902",
        name: "Tailoring Shop",
        _id: "6428fc0ea07fa6c7667d6f36",
      },
      {
        code: "AT0903",
        name: "Barbering Shop",
        _id: "6428fc0ea07fa6c7667d6f37",
      },
      {
        code: "AT0904",
        name: "Electronic Equipment Repair Shop",
        _id: "6428fc0ea07fa6c7667d6f38",
      },
      {
        code: "AT0905",
        name: "Laundary Service Shop",
        _id: "6428fc0ea07fa6c7667d6f39",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f40",
    code: "AT11",
    type: "Activity",
    name: "Arts, Entertainment & Recreation",
    subActivities: [
      {
        code: "AT1101",
        name: "Cinema halls/Movie house",
        _id: "6428fc0ea07fa6c7667d6f41",
      },
      {
        code: "AT1102",
        name: "Recording Studio",
        _id: "6428fc0ea07fa6c7667d6f42",
      },
      {
        code: "AT1103",
        name: "City/Town square",
        _id: "6428fc0ea07fa6c7667d6f43",
      },
      {
        code: "AT1104",
        name: "Theatres",
        _id: "6428fc0ea07fa6c7667d6f44",
      },
      {
        code: "AT1105",
        name: "Sports stadia",
        _id: "6428fc0ea07fa6c7667d6f45",
      },
      {
        code: "AT1106",
        name: "Museums and Monuments",
        _id: "6428fc0ea07fa6c7667d6f46",
      },
      {
        code: "AT1107",
        name: "Forts and castles",
        _id: "6428fc0ea07fa6c7667d6f47",
      },
      {
        code: "AT1108",
        name: "Spa",
        _id: "6428fc0ea07fa6c7667d6f48",
      },
      {
        code: "AT1109",
        name: "Pool Bar / Lounge",
        _id: "6428fc0ea07fa6c7667d6f49",
      },
      {
        code: "AT1110",
        name: "Gymnasia",
        _id: "6428fc0ea07fa6c7667d6f4a",
      },
      {
        code: "AT1111",
        name: "Unclassified",
        _id: "6428fc0ea07fa6c7667d6f4b",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f61",
    code: "AT15",
    type: "Activity",
    name: "Public Service",
    subActivities: [
      {
        code: "AT1501",
        name: "Ministry / Department / Agency",
        _id: "6428fc0ea07fa6c7667d6f62",
      },
      {
        code: "AT1502",
        name: "Prison",
        _id: "6428fc0ea07fa6c7667d6f63",
      },
      {
        code: "AT1503",
        name: "Military Barracks",
        _id: "6428fc0ea07fa6c7667d6f64",
      },
      {
        code: "AT1504",
        name: "Police Barracks",
        _id: "6428fc0ea07fa6c7667d6f65",
      },
      {
        code: "AT1505",
        name: "Police Station",
        _id: "6428fc0ea07fa6c7667d6f66",
      },
      {
        code: "AT1506",
        name: "Fire Service Station",
        _id: "6428fc0ea07fa6c7667d6f67",
      },
      {
        code: "AT1507",
        name: "Municipal / District Assembly",
        _id: "6428fc0ea07fa6c7667d6f68",
      },
      {
        code: "AT1508",
        name: "Government Office",
        _id: "6428fc0ea07fa6c7667d6f69",
      },
      {
        code: "AT1509",
        name: "Unclassified",
        _id: "6428fc0ea07fa6c7667d6f6a",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f6b",
    code: "PT01",
    type: "PremiseType",
    name: "Domestic",
    categories: [
      {
        code: "PT0101",
        name: "Flat Building",
        _id: "6428fc0ea07fa6c7667d6f6c",
      },
      {
        code: "PT0102",
        name: "Multi-storey house",
        _id: "6428fc0ea07fa6c7667d6f6d",
      },
      {
        code: "PT0103",
        name: "Flat",
        _id: "6428fc0ea07fa6c7667d6f6e",
      },
      {
        code: "PT0104",
        name: "Condominium",
        _id: "6428fc0ea07fa6c7667d6f6f",
      },
      {
        code: "PT0105",
        name: "One storey house",
        _id: "6428fc0ea07fa6c7667d6f70",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f71",
    code: "PT02",
    type: "PremiseType",
    name: "Professional & Administrative",
    categories: [
      {
        code: "PT0201",
        name: "Laboratory",
        _id: "6428fc0ea07fa6c7667d6f72",
      },
      {
        code: "PT0202",
        name: "Office Building",
        _id: "6428fc0ea07fa6c7667d6f73",
      },
      {
        code: "PT0203",
        name: "Other Offices",
        _id: "6428fc0ea07fa6c7667d6f74",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f86",
    code: "PT04",
    type: "PremiseType",
    name: "Commercial",
    categories: [
      {
        code: "PT0401",
        name: "Tailoring shop",
        _id: "6428fc0ea07fa6c7667d6f87",
      },
      {
        code: "PT0402",
        name: "Motor Company",
        _id: "6428fc0ea07fa6c7667d6f88",
      },
      {
        code: "PT0403",
        name: "Department Store",
        _id: "6428fc0ea07fa6c7667d6f89",
      },
      {
        code: "PT0404",
        name: "Shopping Mall",
        _id: "6428fc0ea07fa6c7667d6f8a",
      },
      {
        code: "PT0405",
        name: "Supermarket",
        _id: "6428fc0ea07fa6c7667d6f8b",
      },
      {
        code: "PT0406",
        name: "Workshop",
        _id: "6428fc0ea07fa6c7667d6f8c",
      },
      {
        code: "PT0407",
        name: "Floristry",
        _id: "6428fc0ea07fa6c7667d6f8d",
      },
      {
        code: "PT0408",
        name: "Bookstore or Bookshop",
        _id: "6428fc0ea07fa6c7667d6f8e",
      },
      {
        code: "PT0409",
        name: "Bakery",
        _id: "6428fc0ea07fa6c7667d6f8f",
      },
      {
        code: "PT0410",
        name: "Kiosk",
        _id: "6428fc0ea07fa6c7667d6f90",
      },
      {
        code: "PT0411",
        name: "Market",
        _id: "6428fc0ea07fa6c7667d6f91",
      },
      {
        code: "PT0412",
        name: "Dressmaking Shop",
        _id: "6428fc0ea07fa6c7667d6f92",
      },
      {
        code: "PT0413",
        name: "Hairdressing/barbering shop",
        _id: "6428fc0ea07fa6c7667d6f93",
      },
      {
        code: "PT0414",
        name: "Beauty/Cosmetic shop",
        _id: "6428fc0ea07fa6c7667d6f94",
      },
      {
        code: "PT0415",
        name: "Craft Shop",
        _id: "6428fc0ea07fa6c7667d6f95",
      },
      {
        code: "PT0416",
        name: "Mechanic Shop",
        _id: "6428fc0ea07fa6c7667d6f96",
      },
      {
        code: "PT0417",
        name: "Other Shops",
        _id: "6428fc0ea07fa6c7667d6f97",
      },
      {
        code: "PT0418",
        name: "Convention Center",
        _id: "6428fc0ea07fa6c7667d6f98",
      },
      {
        code: "PT0419",
        name: "Forum",
        _id: "6428fc0ea07fa6c7667d6f99",
      },
      {
        code: "PT0420",
        name: "Gas Station",
        _id: "6428fc0ea07fa6c7667d6f9a",
      },
      {
        code: "TF0421",
        name: "Shop",
        _id: "6428fc0ea07fa6c7667d6f9b",
      },
      {
        code: "TF0422",
        name: "Fuel Station",
        _id: "6428fc0ea07fa6c7667d6f9c",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa7",
    code: "PT06",
    type: "PremiseType",
    name: "Agriculture",
    categories: [
      {
        code: "PT0601",
        name: "Farmhouse",
        _id: "6428fc0ea07fa6c7667d6fa8",
      },
      {
        code: "PT0602",
        name: "Barn",
        _id: "6428fc0ea07fa6c7667d6fa9",
      },
      {
        code: "PT0603",
        name: "Chickenhouse",
        _id: "6428fc0ea07fa6c7667d6faa",
      },
      {
        code: "PT0604",
        name: "Cow-shed",
        _id: "6428fc0ea07fa6c7667d6fab",
      },
      {
        code: "PT0605",
        name: "Granary",
        _id: "6428fc0ea07fa6c7667d6fac",
      },
      {
        code: "PT0606",
        name: "Greenhouse",
        _id: "6428fc0ea07fa6c7667d6fad",
      },
      {
        code: "PT0607",
        name: "Hayloft",
        _id: "6428fc0ea07fa6c7667d6fae",
      },
      {
        code: "PT0608",
        name: "Pigpen or Sty",
        _id: "6428fc0ea07fa6c7667d6faf",
      },
      {
        code: "PT0609",
        name: "Root Cellar",
        _id: "6428fc0ea07fa6c7667d6fb0",
      },
      {
        code: "PT0611",
        name: "Silo",
        _id: "6428fc0ea07fa6c7667d6fb1",
      },
      {
        code: "PT0612",
        name: "Stable",
        _id: "6428fc0ea07fa6c7667d6fb2",
      },
      {
        code: "PT0613",
        name: "Stall",
        _id: "6428fc0ea07fa6c7667d6fb3",
      },
      {
        code: "PT0614",
        name: "Storm cellar",
        _id: "6428fc0ea07fa6c7667d6fb4",
      },
      {
        code: "PT0615",
        name: "Well House",
        _id: "6428fc0ea07fa6c7667d6fb5",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb6",
    code: "PT07",
    type: "PremiseType",
    name: "Street Lights",
    categories: [
      {
        code: "PT0701",
        name: "Street Lights",
        _id: "6428fc0ea07fa6c7667d6fb7",
      },
      {
        code: "PT0702",
        name: "Electric Lighting",
        _id: "6428fc0ea07fa6c7667d6fb8",
      },
      {
        code: "PT0703",
        name: "Children's Park",
        _id: "6428fc0ea07fa6c7667d6fb9",
      },
      {
        code: "PT0704",
        name: "Traffic Light",
        _id: "6428fc0ea07fa6c7667d6fba",
      },
      {
        code: "PT0705",
        name: "BillBoard",
        _id: "6428fc0ea07fa6c7667d6fbb",
      },
      {
        code: "PT0706",
        name: "Parks and Garden",
        _id: "6428fc0ea07fa6c7667d6fbc",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe7",
    code: "PT15",
    type: "PremiseType",
    name: "Financial Institutions",
    categories: [
      {
        code: "PT1501",
        name: "Bank",
        _id: "6428fc0ea07fa6c7667d6fe8",
      },
      {
        code: "PT1502",
        name: "Financial Centers",
        _id: "6428fc0ea07fa6c7667d6fe9",
      },
      {
        code: "PT1503",
        name: "Office Building",
        _id: "6428fc0ea07fa6c7667d6fea",
      },
      {
        code: "PT1504",
        name: "Other Offices",
        _id: "6428fc0ea07fa6c7667d6feb",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff1",
    code: "PT17",
    type: "PremiseType",
    name: "Accomodation, Hospitality, Food & Entertainment Services",
    categories: [
      {
        code: "PT1701",
        name: "Restaurant",
        _id: "6428fc0ea07fa6c7667d6ff2",
      },
      {
        code: "PT1702",
        name: "Hotel",
        _id: "6428fc0ea07fa6c7667d6ff3",
      },
      {
        code: "PT1703",
        name: "Cinema",
        _id: "6428fc0ea07fa6c7667d6ff4",
      },
      {
        code: "PT1704",
        name: "Nightclub",
        _id: "6428fc0ea07fa6c7667d6ff5",
      },
      {
        code: "PT1705",
        name: "Food Court",
        _id: "6428fc0ea07fa6c7667d6ff6",
      },
      {
        code: "PT1706",
        name: "Guest House",
        _id: "6428fc0ea07fa6c7667d6ff7",
      },
      {
        code: "PT1707",
        name: "Eatery",
        _id: "6428fc0ea07fa6c7667d6ff8",
      },
      {
        code: "PT1708",
        name: "Fast-food Restaurant",
        _id: "6428fc0ea07fa6c7667d6ff9",
      },
      {
        code: "PT1709",
        name: "Bar",
        _id: "6428fc0ea07fa6c7667d6ffa",
      },
      {
        code: "PT1710",
        name: "Pub",
        _id: "6428fc0ea07fa6c7667d6ffb",
      },
      {
        code: "PT1711",
        name: "Coffee House",
        _id: "6428fc0ea07fa6c7667d6ffc",
      },
      {
        code: "PT1712",
        name: "Pizzaria",
        _id: "6428fc0ea07fa6c7667d6ffd",
      },
      {
        code: "PT1713",
        name: "Sport Club",
        _id: "6428fc0ea07fa6c7667d6ffe",
      },
      {
        code: "PT1714",
        name: "Theater",
        _id: "6428fc0ea07fa6c7667d6fff",
      },
      {
        code: "PT1715",
        name: "Amphitheater",
        _id: "6428fc0ea07fa6c7667d7000",
      },
      {
        code: "PT1716",
        name: "Concert Hall",
        _id: "6428fc0ea07fa6c7667d7001",
      },
      {
        code: "PT1717",
        name: "Opera House",
        _id: "6428fc0ea07fa6c7667d7002",
      },
      {
        code: "PT1718",
        name: "Stadium",
        _id: "6428fc0ea07fa6c7667d7003",
      },
      {
        code: "PT1719",
        name: "City/Town Square",
        _id: "6428fc0ea07fa6c7667d7004",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a192d",
    code: "AP01",
    type: "Appliance",
    name: "Refrigerator",
    wattage: 1.5,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a192e",
    code: "AP02",
    type: "Appliance",
    name: "Air Conditioner",
    wattage: 2.5,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a192f",
    code: "AP03",
    type: "Appliance",
    name: "Washing Machine",
    wattage: 2.2,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1930",
    code: "AP04",
    type: "Appliance",
    name: "Clothes Dryer",
    wattage: 3.5,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1932",
    code: "AP06",
    type: "Appliance",
    name: "Electric Oven",
    wattage: 2,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1931",
    code: "AP05",
    type: "Appliance",
    name: "Dishwasher",
    wattage: 1.5,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1935",
    code: "AP09",
    type: "Appliance",
    name: "Toaster",
    wattage: 0.15,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1942",
    code: "AP22",
    type: "Appliance",
    name: "Telephone",
    wattage: 0.01,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1949",
    code: "AP29",
    type: "Appliance",
    name: "Mixer",
    wattage: 0.25,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a194a",
    code: "AP30",
    type: "Appliance",
    name: "Slow Cooker",
    wattage: 0.2,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a194b",
    code: "AP31",
    type: "Appliance",
    name: "Deep Freezer",
    wattage: 1.5,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb052",
    code: "IT02",
    type: "InstallationType",
    name: "1-Pole Extension",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 3504,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 3504,
    costThreePhase: 5304,
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb053",
    code: "IT03",
    type: "InstallationType",
    name: "2-Pole Extension",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 5424,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 5424,
    costThreePhase: 8064,
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb056",
    code: "IT06",
    type: "InstallationType",
    name: "Post SHEP Straight",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 120,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 120,
    costThreePhase: 240,
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb05a",
    code: "IT10",
    type: "InstallationType",
    name: "Internal Monitoring",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 840,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 840,
    costThreePhase: 1920,
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6efc",
    code: "AT03",
    type: "Activity",
    name: "Educational Institution",
    subActivities: [
      {
        code: "AT0301",
        name: "Senior High School",
        _id: "6428fc0ea07fa6c7667d6efd",
      },
      {
        code: "AT0302",
        name: "Junior High School",
        _id: "6428fc0ea07fa6c7667d6efe",
      },
      {
        code: "AT0303",
        name: "Basic High School",
        _id: "6428fc0ea07fa6c7667d6eff",
      },
      {
        code: "AT0304",
        name: "Technical School",
        _id: "6428fc0ea07fa6c7667d6f00",
      },
      {
        code: "AT0305",
        name: "Vocational School",
        _id: "6428fc0ea07fa6c7667d6f01",
      },
      {
        code: "AT0306",
        name: "Tertiary Institution",
        _id: "6428fc0ea07fa6c7667d6f02",
      },
      {
        code: "AT0307",
        name: "Special School",
        _id: "6428fc0ea07fa6c7667d6f03",
      },
      {
        code: "AT0308",
        name: "Unclasified",
        _id: "6428fc0ea07fa6c7667d6f04",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f30",
    code: "AT08",
    type: "Activity",
    name: "Religious Center",
    subActivities: [
      {
        code: "AT0801",
        name: "Church",
        _id: "6428fc0ea07fa6c7667d6f31",
      },
      {
        code: "AT0802",
        name: "Mosque",
        _id: "6428fc0ea07fa6c7667d6f32",
      },
      {
        code: "AT0803",
        name: "Other religious centers",
        _id: "6428fc0ea07fa6c7667d6f33",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f3a",
    code: "AT10",
    type: "Activity",
    name: "Agriculture",
    subActivities: [
      {
        code: "AT1001",
        name: "Animal Production",
        _id: "6428fc0ea07fa6c7667d6f3b",
      },
      {
        code: "AT1002",
        name: "Horticulture",
        _id: "6428fc0ea07fa6c7667d6f3c",
      },
      {
        code: "AT1003",
        name: "Crop Production",
        _id: "6428fc0ea07fa6c7667d6f3d",
      },
      {
        code: "AT1004",
        name: "Fish Production",
        _id: "6428fc0ea07fa6c7667d6f3e",
      },
      {
        code: "AT1005",
        name: "Forestry",
        _id: "6428fc0ea07fa6c7667d6f3f",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f4c",
    code: "AT12",
    type: "Activity",
    name: "Hospitality Service",
    subActivities: [
      {
        code: "AT1201",
        name: "Drinking Spot / Bar",
        _id: "6428fc0ea07fa6c7667d6f4d",
      },
      {
        code: "AT1202",
        name: "Restaurant",
        _id: "6428fc0ea07fa6c7667d6f4e",
      },
      {
        code: "AT1203",
        name: "Hotel / Motel",
        _id: "6428fc0ea07fa6c7667d6f4f",
      },
      {
        code: "AT1204",
        name: "Guest House",
        _id: "6428fc0ea07fa6c7667d6f50",
      },
      {
        code: "AT1205",
        name: "Condominium",
        _id: "6428fc0ea07fa6c7667d6f51",
      },
      {
        code: "AT1206",
        name: "Catering and rental services",
        _id: "6428fc0ea07fa6c7667d6f52",
      },
      {
        code: "AT1207",
        name: "Chop Bar",
        _id: "6428fc0ea07fa6c7667d6f53",
      },
      {
        code: "AT1208",
        name: "Hostel",
        _id: "6428fc0ea07fa6c7667d6f54",
      },
      {
        code: "AT1209",
        name: "Unclassified",
        _id: "6428fc0ea07fa6c7667d6f55",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f5c",
    code: "AT14",
    type: "Activity",
    name: "Construction",
    subActivities: [
      {
        code: "AT1401",
        name: "Civil works",
        _id: "6428fc0ea07fa6c7667d6f5d",
      },
      {
        code: "AT1402",
        name: "Specialised construction activity",
        _id: "6428fc0ea07fa6c7667d6f5e",
      },
      {
        code: "AT1403",
        name: "Real estate development",
        _id: "6428fc0ea07fa6c7667d6f5f",
      },
      {
        code: "AT1404",
        name: "Unclassified",
        _id: "6428fc0ea07fa6c7667d6f60",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f75",
    code: "PT03",
    type: "PremiseType",
    name: "Industrial",
    categories: [
      {
        code: "PT0301",
        name: "Factory",
        _id: "6428fc0ea07fa6c7667d6f76",
      },
      {
        code: "PT0302",
        name: "Brewery",
        _id: "6428fc0ea07fa6c7667d6f77",
      },
      {
        code: "PT0303",
        name: "Plant",
        _id: "6428fc0ea07fa6c7667d6f78",
      },
      {
        code: "PT0304",
        name: "Foundry",
        _id: "6428fc0ea07fa6c7667d6f79",
      },
      {
        code: "PT0305",
        name: "Corn Mill",
        _id: "6428fc0ea07fa6c7667d6f7a",
      },
      {
        code: "PT0306",
        name: "Saw Mill",
        _id: "6428fc0ea07fa6c7667d6f7b",
      },
      {
        code: "PT0307",
        name: "Wood Workshop",
        _id: "6428fc0ea07fa6c7667d6f7c",
      },
      {
        code: "PT0308",
        name: "Textile Manufacturer",
        _id: "6428fc0ea07fa6c7667d6f7d",
      },
      {
        code: "PT0309",
        name: "Metal Work Welding",
        _id: "6428fc0ea07fa6c7667d6f7e",
      },
      {
        code: "PT0310",
        name: "Furniture Manufacturer",
        _id: "6428fc0ea07fa6c7667d6f7f",
      },
      {
        code: "PT0311",
        name: "Other Manufacturer",
        _id: "6428fc0ea07fa6c7667d6f80",
      },
      {
        code: "PT0312",
        name: "Printing Press",
        _id: "6428fc0ea07fa6c7667d6f81",
      },
      {
        code: "PT0313",
        name: "Construction",
        _id: "6428fc0ea07fa6c7667d6f82",
      },
      {
        code: "PT0314",
        name: "Plastic & Allied Ind.",
        _id: "6428fc0ea07fa6c7667d6f83",
      },
      {
        code: "TF710",
        name: "Flour Mill",
        _id: "6428fc0ea07fa6c7667d6f84",
      },
      {
        code: "TF711",
        name: "Rice Mill",
        _id: "6428fc0ea07fa6c7667d6f85",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc4",
    code: "PT09",
    type: "PremiseType",
    name: "Organizations",
    categories: [
      {
        code: "PT0901",
        name: "Students' Union",
        _id: "6428fc0ea07fa6c7667d6fc5",
      },
      {
        code: "PT0902",
        name: "Community Centers",
        _id: "6428fc0ea07fa6c7667d6fc6",
      },
      {
        code: "PT0903",
        name: "Office",
        _id: "6428fc0ea07fa6c7667d6fc7",
      },
      {
        code: "PT0904",
        name: "Office Building",
        _id: "6428fc0ea07fa6c7667d6fc8",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fbd",
    code: "PT08",
    type: "PremiseType",
    name: "Churches",
    categories: [
      {
        code: "PT0801",
        name: "Church",
        _id: "6428fc0ea07fa6c7667d6fbe",
      },
      {
        code: "PT0802",
        name: "Mosque",
        _id: "6428fc0ea07fa6c7667d6fbf",
      },
      {
        code: "PT0803",
        name: "Synagogue",
        _id: "6428fc0ea07fa6c7667d6fc0",
      },
      {
        code: "PT0804",
        name: "Temple",
        _id: "6428fc0ea07fa6c7667d6fc1",
      },
      {
        code: "PT0805",
        name: "Kingdom Halls",
        _id: "6428fc0ea07fa6c7667d6fc2",
      },
      {
        code: "PT0806",
        name: "Other Religious Center",
        _id: "6428fc0ea07fa6c7667d6fc3",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd7",
    code: "PT13",
    type: "PremiseType",
    name: "Educational",
    categories: [
      {
        code: "PT1301",
        name: "School",
        _id: "6428fc0ea07fa6c7667d6fd8",
      },
      {
        code: "PT1302",
        name: "Library",
        _id: "6428fc0ea07fa6c7667d6fd9",
      },
      {
        code: "PT1303",
        name: "University",
        _id: "6428fc0ea07fa6c7667d6fda",
      },
      {
        code: "PT1304",
        name: "Gymnasium",
        _id: "6428fc0ea07fa6c7667d6fdb",
      },
      {
        code: "PT1305",
        name: "Art Gallery",
        _id: "6428fc0ea07fa6c7667d6fdc",
      },
      {
        code: "PT1306",
        name: "Kindergarten",
        _id: "6428fc0ea07fa6c7667d6fdd",
      },
      {
        code: "PT1307",
        name: "Classroom Building",
        _id: "6428fc0ea07fa6c7667d6fde",
      },
      {
        code: "PT1308",
        name: "College",
        _id: "6428fc0ea07fa6c7667d6fdf",
      },
      {
        code: "PT1309",
        name: "Museum",
        _id: "6428fc0ea07fa6c7667d6fe0",
      },
      {
        code: "PT1310",
        name: "Office",
        _id: "6428fc0ea07fa6c7667d6fe1",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fcb",
    code: "PT11",
    type: "PremiseType",
    name: "Health Care & Social Work Activities",
    categories: [
      {
        code: "PT1101",
        name: "Hospitals",
        _id: "6428fc0ea07fa6c7667d6fcc",
      },
      {
        code: "PT1102",
        name: "Retirement Home",
        _id: "6428fc0ea07fa6c7667d6fcd",
      },
      {
        code: "PT1103",
        name: "Clinic",
        _id: "6428fc0ea07fa6c7667d6fce",
      },
      {
        code: "PT1104",
        name: "Maternity Home",
        _id: "6428fc0ea07fa6c7667d6fcf",
      },
      {
        code: "PT1105",
        name: "Nursing",
        _id: "6428fc0ea07fa6c7667d6fd0",
      },
      {
        code: "PT1106",
        name: "Orphanage",
        _id: "6428fc0ea07fa6c7667d6fd1",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc9",
    code: "PT10",
    type: "PremiseType",
    name: "Mining",
    categories: [
      {
        code: "PT1001",
        name: "Mine",
        _id: "6428fc0ea07fa6c7667d6fca",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1933",
    code: "AP07",
    type: "Appliance",
    name: "Microwave Oven",
    wattage: 0.8,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1936",
    code: "AP10",
    type: "Appliance",
    name: "Electric Grill",
    wattage: 2,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1939",
    code: "AP13",
    type: "Appliance",
    name: "Television",
    wattage: 0.1,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a193a",
    code: "AP14",
    type: "Appliance",
    name: "Computer",
    wattage: 0.2,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1943",
    code: "AP23",
    type: "Appliance",
    name: "Hair Dryer",
    wattage: 1.5,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1945",
    code: "AP25",
    type: "Appliance",
    name: "Iron",
    wattage: 1.5,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1948",
    code: "AP28",
    type: "Appliance",
    name: "Food Processor",
    wattage: 0.3,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a193e",
    code: "AP18",
    type: "Appliance",
    name: "Fax Machine",
    wattage: 0.1,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb058",
    code: "IT08",
    type: "InstallationType",
    name: "Meter Replacement",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 0,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 0,
    costThreePhase: 0,
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d701f",
    code: "MP04",
    type: "MeterPhase",
    name: "3 Phase / 4 wire",
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.574Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.574Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6ef1",
    code: "AT01",
    type: "Activity",
    name: "House / Residence",
    subActivities: [
      {
        code: "AT0101",
        name: "Single dwelling",
        _id: "6428fc0ea07fa6c7667d6ef2",
      },
      {
        code: "AT0102",
        name: "Multiple dwelling",
        _id: "6428fc0ea07fa6c7667d6ef3",
      },
      {
        code: "AT0103",
        name: "Apartment",
        _id: "6428fc0ea07fa6c7667d6ef4",
      },
      {
        code: "AT0104",
        name: "Compound House",
        _id: "6428fc0ea07fa6c7667d6ef5",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f05",
    code: "AT04",
    type: "Activity",
    name: "Manufacturing",
    subActivities: [
      {
        code: "AT0401",
        name: "Fabricated metal products Production",
        _id: "6428fc0ea07fa6c7667d6f06",
      },
      {
        code: "AT0402",
        name: "Cornmill",
        _id: "6428fc0ea07fa6c7667d6f07",
      },
      {
        code: "AT0403",
        name: "Wood products Production",
        _id: "6428fc0ea07fa6c7667d6f08",
      },
      {
        code: "AT0404",
        name: "Printing press",
        _id: "6428fc0ea07fa6c7667d6f09",
      },
      {
        code: "AT0405",
        name: "Concrete products Production",
        _id: "6428fc0ea07fa6c7667d6f0a",
      },
      {
        code: "AT0406",
        name: "Aluminium products Production",
        _id: "6428fc0ea07fa6c7667d6f0b",
      },
      {
        code: "AT0407",
        name: "Computer and electronic products Production",
        _id: "6428fc0ea07fa6c7667d6f0c",
      },
      {
        code: "AT0408",
        name: "Electrical products Production",
        _id: "6428fc0ea07fa6c7667d6f0d",
      },
      {
        code: "AT0409",
        name: "Mineral water Production",
        _id: "6428fc0ea07fa6c7667d6f0e",
      },
      {
        code: "AT0410",
        name: "Building Material Production",
        _id: "6428fc0ea07fa6c7667d6f0f",
      },
      {
        code: "AT0411",
        name: "Steel Production",
        _id: "6428fc0ea07fa6c7667d6f10",
      },
      {
        code: "AT0412",
        name: "Pharmaceuticals",
        _id: "6428fc0ea07fa6c7667d6f11",
      },
      {
        code: "AT0413",
        name: "Plastic and rubber Production",
        _id: "6428fc0ea07fa6c7667d6f12",
      },
      {
        code: "AT0414",
        name: "Beverage Production",
        _id: "6428fc0ea07fa6c7667d6f13",
      },
      {
        code: "AT0415",
        name: "Glassware Production",
        _id: "6428fc0ea07fa6c7667d6f14",
      },
      {
        code: "AT0416",
        name: "Cosmetics Production",
        _id: "6428fc0ea07fa6c7667d6f15",
      },
      {
        code: "AT0417",
        name: "Paper products Production",
        _id: "6428fc0ea07fa6c7667d6f16",
      },
      {
        code: "AT0418",
        name: "Chemical products Production",
        _id: "6428fc0ea07fa6c7667d6f17",
      },
      {
        code: "AT0419",
        name: "Leatherware Production",
        _id: "6428fc0ea07fa6c7667d6f18",
      },
      {
        code: "AT0420",
        name: "Textile Production",
        _id: "6428fc0ea07fa6c7667d6f19",
      },
      {
        code: "AT0421",
        name: "Petroleum refinery",
        _id: "6428fc0ea07fa6c7667d6f1a",
      },
      {
        code: "AT0422",
        name: "Glassware Production",
        _id: "6428fc0ea07fa6c7667d6f1b",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f1c",
    code: "AT05",
    type: "Activity",
    name: "Financial",
    subActivities: [
      {
        code: "AT0501",
        name: "Banking",
        _id: "6428fc0ea07fa6c7667d6f1d",
      },
      {
        code: "AT0502",
        name: "Insurance",
        _id: "6428fc0ea07fa6c7667d6f1e",
      },
      {
        code: "AT0503",
        name: "Forex bureaux",
        _id: "6428fc0ea07fa6c7667d6f1f",
      },
      {
        code: "AT0504",
        name: "Credit unions/Cooperatives",
        _id: "6428fc0ea07fa6c7667d6f20",
      },
      {
        code: "AT0505",
        name: "Mutual funds Co.",
        _id: "6428fc0ea07fa6c7667d6f21",
      },
      {
        code: "AT0506",
        name: "Savings and Loans",
        _id: "6428fc0ea07fa6c7667d6f22",
      },
      {
        code: "AT0507",
        name: "Micro Finance",
        _id: "6428fc0ea07fa6c7667d6f23",
      },
      {
        code: "AT0508",
        name: "Other Financial Center",
        _id: "6428fc0ea07fa6c7667d6f24",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe2",
    code: "PT14",
    type: "PremiseType",
    name: "Extraterritotial Organizations & Bodies",
    categories: [
      {
        code: "PT1401",
        name: "Embassy",
        _id: "6428fc0ea07fa6c7667d6fe3",
      },
      {
        code: "PT1402",
        name: "Consulate",
        _id: "6428fc0ea07fa6c7667d6fe4",
      },
      {
        code: "PT1403",
        name: "Office",
        _id: "6428fc0ea07fa6c7667d6fe5",
      },
      {
        code: "PT1404",
        name: "Office Building",
        _id: "6428fc0ea07fa6c7667d6fe6",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d7005",
    code: "PT18",
    type: "PremiseType",
    name: "Transport & Storage",
    categories: [
      {
        code: "PT1801",
        name: "Airport Terminal",
        _id: "6428fc0ea07fa6c7667d7006",
      },
      {
        code: "PT1802",
        name: "Garage",
        _id: "6428fc0ea07fa6c7667d7007",
      },
      {
        code: "PT1803",
        name: "Warehouse",
        _id: "6428fc0ea07fa6c7667d7008",
      },
      {
        code: "PT1804",
        name: "Bus Station",
        _id: "6428fc0ea07fa6c7667d7009",
      },
      {
        code: "PT1805",
        name: "Taxi Station",
        _id: "6428fc0ea07fa6c7667d700a",
      },
      {
        code: "PT1806",
        name: "Train Station",
        _id: "6428fc0ea07fa6c7667d700b",
      },
      {
        code: "PT1807",
        name: "Signal Box",
        _id: "6428fc0ea07fa6c7667d700c",
      },
      {
        code: "PT1808",
        name: "Lighthouse",
        _id: "6428fc0ea07fa6c7667d700d",
      },
      {
        code: "PT1809",
        name: "Parking Garage",
        _id: "6428fc0ea07fa6c7667d700e",
      },
      {
        code: "PT1810",
        name: "Boathouse",
        _id: "6428fc0ea07fa6c7667d700f",
      },
      {
        code: "PT1811",
        name: "Hangar, of Aircraft or Spacecraft",
        _id: "6428fc0ea07fa6c7667d7010",
      },
      {
        code: "PT1812",
        name: "Storage Silo",
        _id: "6428fc0ea07fa6c7667d7011",
      },
      {
        code: "PT1813",
        name: "Cold Store",
        _id: "6428fc0ea07fa6c7667d7012",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d701a",
    code: "PT20",
    type: "PremiseType",
    name: "Unclassified",
    categories: [
      {
        code: "PT2001",
        name: "Unclassified",
        _id: "6428fc0ea07fa6c7667d701b",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d7013",
    code: "PT19",
    type: "PremiseType",
    name: "Utilities & Power Stations",
    categories: [
      {
        code: "PT1901",
        name: "Power Station",
        _id: "6428fc0ea07fa6c7667d7014",
      },
      {
        code: "PT1902",
        name: "Nuclear Power Plant",
        _id: "6428fc0ea07fa6c7667d7015",
      },
      {
        code: "PT1903",
        name: "Other Power Plant",
        _id: "6428fc0ea07fa6c7667d7016",
      },
      {
        code: "PT1904",
        name: "Water Producer",
        _id: "6428fc0ea07fa6c7667d7017",
      },
      {
        code: "PT1905",
        name: "Sewage Treatment Plant",
        _id: "6428fc0ea07fa6c7667d7018",
      },
      {
        code: "PT1906",
        name: "Sub Station/Offices",
        _id: "6428fc0ea07fa6c7667d7019",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1934",
    code: "AP08",
    type: "Appliance",
    name: "Electric Kettle",
    wattage: 0.2,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1937",
    code: "AP11",
    type: "Appliance",
    name: "Vacuum Cleaner",
    wattage: 0.8,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a193c",
    code: "AP16",
    type: "Appliance",
    name: "Printer",
    wattage: 0.1,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a193f",
    code: "AP19",
    type: "Appliance",
    name: "Game Console",
    wattage: 0.2,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1941",
    code: "AP21",
    type: "Appliance",
    name: "Modem",
    wattage: 0.05,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1940",
    code: "AP20",
    type: "Appliance",
    name: "Router",
    wattage: 0.05,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1946",
    code: "AP26",
    type: "Appliance",
    name: "Toaster Oven",
    wattage: 1.6,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1947",
    code: "AP27",
    type: "Appliance",
    name: "Blender",
    wattage: 0.3,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a193d",
    code: "AP17",
    type: "Appliance",
    name: "Scanner",
    wattage: 0.1,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb054",
    code: "IT04",
    type: "InstallationType",
    name: "Straight Service with Pole",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 2544,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 2544,
    costThreePhase: 3624,
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb055",
    code: "IT05",
    type: "InstallationType",
    name: "Separate Meter",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 840,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 840,
    costThreePhase: 1560,
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb057",
    code: "IT07",
    type: "InstallationType",
    name: "Additional Load",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 1200,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 1200,
    costThreePhase: 2280,
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d701d",
    code: "MP02",
    type: "MeterPhase",
    name: "3 Phase",
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.574Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.574Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d701e",
    code: "MP03",
    type: "MeterPhase",
    name: "3 Phase CT",
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.574Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.574Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f2a",
    code: "AT07",
    type: "Activity",
    name: "Information and Communication Technology",
    subActivities: [
      {
        code: "AT0701",
        name: "Cell Tower",
        _id: "6428fc0ea07fa6c7667d6f2b",
      },
      {
        code: "AT0702",
        name: "Communication centers",
        _id: "6428fc0ea07fa6c7667d6f2c",
      },
      {
        code: "AT0703",
        name: "Telecommunication Company",
        _id: "6428fc0ea07fa6c7667d6f2d",
      },
      {
        code: "AT0704",
        name: "Software Company",
        _id: "6428fc0ea07fa6c7667d6f2e",
      },
      {
        code: "AT0705",
        name: "Internet Cafe",
        _id: "6428fc0ea07fa6c7667d6f2f",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f56",
    code: "AT13",
    type: "Activity",
    name: "Health Service",
    subActivities: [
      {
        code: "AT1301",
        name: "Hospital / Clinic",
        _id: "6428fc0ea07fa6c7667d6f57",
      },
      {
        code: "AT1302",
        name: "Pharmarcy /Chemical Shop",
        _id: "6428fc0ea07fa6c7667d6f58",
      },
      {
        code: "AT1303",
        name: "Herbal Clinic / Center",
        _id: "6428fc0ea07fa6c7667d6f59",
      },
      {
        code: "AT1304",
        name: "CHPS Compound / Healthpost",
        _id: "6428fc0ea07fa6c7667d6f5a",
      },
      {
        code: "AT1305",
        name: "Maternity Homes",
        _id: "6428fc0ea07fa6c7667d6f5b",
      },
    ],
    materials: [],
    categories: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.576Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f9d",
    code: "PT05",
    type: "PremiseType",
    name: "Public Services",
    categories: [
      {
        code: "PT0501",
        name: "City hall",
        _id: "6428fc0ea07fa6c7667d6f9e",
      },
      {
        code: "PT0502",
        name: "Courthouse",
        _id: "6428fc0ea07fa6c7667d6f9f",
      },
      {
        code: "PT0503",
        name: "Fire Station",
        _id: "6428fc0ea07fa6c7667d6fa0",
      },
      {
        code: "PT0504",
        name: "Meeting House",
        _id: "6428fc0ea07fa6c7667d6fa1",
      },
      {
        code: "PT0505",
        name: "Parliament",
        _id: "6428fc0ea07fa6c7667d6fa2",
      },
      {
        code: "PT0506",
        name: "Police Station",
        _id: "6428fc0ea07fa6c7667d6fa3",
      },
      {
        code: "PT0507",
        name: "Prison",
        _id: "6428fc0ea07fa6c7667d6fa4",
      },
      {
        code: "PT0508",
        name: "Office",
        _id: "6428fc0ea07fa6c7667d6fa5",
      },
      {
        code: "PT0509",
        name: "Office Building",
        _id: "6428fc0ea07fa6c7667d6fa6",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd2",
    code: "PT12",
    type: "PremiseType",
    name: "Public Defense",
    categories: [
      {
        code: "PT1201",
        name: "Military Barracks",
        _id: "6428fc0ea07fa6c7667d6fd3",
      },
      {
        code: "PT1202",
        name: "Office Building",
        _id: "6428fc0ea07fa6c7667d6fd4",
      },
      {
        code: "PT1203",
        name: "Office",
        _id: "6428fc0ea07fa6c7667d6fd5",
      },
      {
        code: "PT1204",
        name: "Other Military Building",
        _id: "6428fc0ea07fa6c7667d6fd6",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6fec",
    code: "PT16",
    type: "PremiseType",
    name: "Media",
    categories: [
      {
        code: "PT1601",
        name: "Data Center",
        _id: "6428fc0ea07fa6c7667d6fed",
      },
      {
        code: "PT1602",
        name: "Office Building",
        _id: "6428fc0ea07fa6c7667d6fee",
      },
      {
        code: "PT1603",
        name: "Other Offices",
        _id: "6428fc0ea07fa6c7667d6fef",
      },
      {
        code: "PT1604",
        name: "Cell Site",
        _id: "6428fc0ea07fa6c7667d6ff0",
      },
    ],
    materials: [],
    subActivities: [],
    createdAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    updatedAt: {
      $date: "2023-04-02T03:52:46.577Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a193b",
    code: "AP15",
    type: "Appliance",
    name: "Laptop",
    wattage: 0.05,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1938",
    code: "AP12",
    type: "Appliance",
    name: "Ceiling Fan",
    wattage: 0.05,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a1944",
    code: "AP24",
    type: "Appliance",
    name: "Electric Shaver",
    wattage: 0.1,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b5e9102e2776c389a194c",
    code: "AP32",
    type: "Appliance",
    name: "Hair Blow Dryer",
    wattage: 3,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb051",
    code: "IT01",
    type: "InstallationType",
    name: "Straight Service",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 1224,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 1224,
    costThreePhase: 2304,
    __v: 0,
  },
  {
    _id: "642b67b66a12a76fb06eb059",
    code: "IT09",
    type: "InstallationType",
    name: "Temporal Supply",
    materials: [
      {
        _id: "6477525f88687861301543af",
      },
      {
        _id: "6477525f88687861301543b0",
      },
      {
        _id: "6477525f88687861301543b1",
      },
      {
        _id: "6477525f88687861301543b2",
      },
      {
        _id: "6477525f88687861301543b3",
      },
      {
        _id: "6477525f88687861301543b4",
      },
      {
        _id: "6477525f88687861301543b5",
      },
      {
        _id: "6477525f88687861301543b6",
      },
      {
        _id: "6477525f88687861301543b7",
      },
      {
        _id: "6477525f88687861301543b8",
      },
      {
        _id: "6477525f88687861301543b9",
      },
      {
        _id: "6477525f88687861301543ba",
      },
      {
        _id: "6477525f88687861301543bb",
      },
      {
        _id: "6477525f88687861301543bc",
      },
      {
        _id: "6477525f88687861301543bd",
      },
      {
        _id: "6477525f88687861301543be",
      },
      {
        _id: "6477525f88687861301543bf",
      },
      {
        _id: "6477525f88687861301543c0",
      },
      {
        _id: "6477525f88687861301543c1",
      },
      {
        _id: "6498817363a4c92f53113293",
      },
      {
        _id: "649882fe63a4c92f53113295",
      },
      {
        _id: "649886819f6341b0cb17bfd8",
      },
      {
        _id: "649886a39f6341b0cb17bfda",
      },
      {
        _id: "6498879d9f6341b0cb17bfdc",
      },
    ],
    subActivities: [],
    categories: [],
    cost: 960,
    createdAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:56:38.741Z",
    },
    costOnePhase: 960,
    costThreePhase: 1800,
    __v: 0,
  },
  {
    _id: "64416bc9e3a60b187fc9c108",
    code: "AP33",
    type: "Appliance",
    name: "Light",
    wattage: 1,
    materials: [],
    subActivities: [],
    categories: [],
    createdAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    updatedAt: {
      $date: "2023-04-03T23:17:37.197Z",
    },
    __v: 0,
  },
  {
    _id: "6428fc0ea07fa6c7667d6f3b",
    activity: "6428fc0ea07fa6c7667d6f3a",
    code: "AT1001",
    name: "Animal Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f3c",
    activity: "6428fc0ea07fa6c7667d6f3a",
    code: "AT1002",
    name: "Horticulture",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f3d",
    activity: "6428fc0ea07fa6c7667d6f3a",
    code: "AT1003",
    name: "Crop Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f3e",
    activity: "6428fc0ea07fa6c7667d6f3a",
    code: "AT1004",
    name: "Fish Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f3f",
    activity: "6428fc0ea07fa6c7667d6f3a",
    code: "AT1005",
    name: "Forestry",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f41",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1101",
    name: "Cinema halls/Movie house",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f42",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1102",
    name: "Recording Studio",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f43",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1103",
    name: "City/Town square",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f44",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1104",
    name: "Theatres",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f45",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1105",
    name: "Sports stadia",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f46",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1106",
    name: "Museums and Monuments",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f47",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1107",
    name: "Forts and castles",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f48",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1108",
    name: "Spa",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f49",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1109",
    name: "Pool Bar / Lounge",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f4a",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1110",
    name: "Gymnasia",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f4b",
    activity: "6428fc0ea07fa6c7667d6f40",
    code: "AT1111",
    name: "Unclassified",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f5d",
    activity: "6428fc0ea07fa6c7667d6f5c",
    code: "AT1401",
    name: "Civil works",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f5e",
    activity: "6428fc0ea07fa6c7667d6f5c",
    code: "AT1402",
    name: "Specialised construction activity",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f5f",
    activity: "6428fc0ea07fa6c7667d6f5c",
    code: "AT1403",
    name: "Real estate development",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6efd",
    activity: "6428fc0ea07fa6c7667d6efc",
    code: "AT0301",
    name: "Senior High School",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6efe",
    activity: "6428fc0ea07fa6c7667d6efc",
    code: "AT0302",
    name: "Junior High School",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6eff",
    activity: "6428fc0ea07fa6c7667d6efc",
    code: "AT0303",
    name: "Basic High School",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f00",
    activity: "6428fc0ea07fa6c7667d6efc",
    code: "AT0304",
    name: "Technical School",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f01",
    activity: "6428fc0ea07fa6c7667d6efc",
    code: "AT0305",
    name: "Vocational School",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f02",
    activity: "6428fc0ea07fa6c7667d6efc",
    code: "AT0306",
    name: "Tertiary Institution",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f03",
    activity: "6428fc0ea07fa6c7667d6efc",
    code: "AT0307",
    name: "Special School",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f04",
    activity: "6428fc0ea07fa6c7667d6efc",
    code: "AT0308",
    name: "Unclasified",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f1d",
    activity: "6428fc0ea07fa6c7667d6f1c",
    code: "AT0501",
    name: "Banking",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f1e",
    activity: "6428fc0ea07fa6c7667d6f1c",
    code: "AT0502",
    name: "Insurance",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f1f",
    activity: "6428fc0ea07fa6c7667d6f1c",
    code: "AT0503",
    name: "Forex bureaux",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f20",
    activity: "6428fc0ea07fa6c7667d6f1c",
    code: "AT0504",
    name: "Credit unions/Cooperatives",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f21",
    activity: "6428fc0ea07fa6c7667d6f1c",
    code: "AT0505",
    name: "Mutual funds Co.",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f22",
    activity: "6428fc0ea07fa6c7667d6f1c",
    code: "AT0506",
    name: "Savings and Loans",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f23",
    activity: "6428fc0ea07fa6c7667d6f1c",
    code: "AT0507",
    name: "Micro Finance",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f24",
    activity: "6428fc0ea07fa6c7667d6f1c",
    code: "AT0508",
    name: "Other Financial Center",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f57",
    activity: "6428fc0ea07fa6c7667d6f56",
    code: "AT1301",
    name: "Hospital / Clinic",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f58",
    activity: "6428fc0ea07fa6c7667d6f56",
    code: "AT1302",
    name: "Pharmarcy /Chemical Shop",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f59",
    activity: "6428fc0ea07fa6c7667d6f56",
    code: "AT1303",
    name: "Herbal Clinic / Center",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f5a",
    activity: "6428fc0ea07fa6c7667d6f56",
    code: "AT1304",
    name: "CHPS Compound / Healthpost",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f5b",
    activity: "6428fc0ea07fa6c7667d6f56",
    code: "AT1305",
    name: "Maternity Homes",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f4d",
    activity: "6428fc0ea07fa6c7667d6f4c",
    code: "AT1201",
    name: "Drinking Spot / Bar",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f4e",
    activity: "6428fc0ea07fa6c7667d6f4c",
    code: "AT1202",
    name: "Restaurant",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f4f",
    activity: "6428fc0ea07fa6c7667d6f4c",
    code: "AT1203",
    name: "Hotel / Motel",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f50",
    activity: "6428fc0ea07fa6c7667d6f4c",
    code: "AT1204",
    name: "Guest House",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f51",
    activity: "6428fc0ea07fa6c7667d6f4c",
    code: "AT1205",
    name: "Condominium",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f52",
    activity: "6428fc0ea07fa6c7667d6f4c",
    code: "AT1206",
    name: "Catering and rental services",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f53",
    activity: "6428fc0ea07fa6c7667d6f4c",
    code: "AT1207",
    name: "Chop Bar",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f54",
    activity: "6428fc0ea07fa6c7667d6f4c",
    code: "AT1208",
    name: "Hostel",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ef2",
    activity: "6428fc0ea07fa6c7667d6ef1",
    code: "AT0101",
    name: "Single dwelling",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ef3",
    activity: "6428fc0ea07fa6c7667d6ef1",
    code: "AT0102",
    name: "Multiple dwelling",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ef4",
    activity: "6428fc0ea07fa6c7667d6ef1",
    code: "AT0103",
    name: "Apartment",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ef5",
    activity: "6428fc0ea07fa6c7667d6ef1",
    code: "AT0104",
    name: "Compound House",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f2b",
    activity: "6428fc0ea07fa6c7667d6f2a",
    code: "AT0701",
    name: "Cell Tower",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f2c",
    activity: "6428fc0ea07fa6c7667d6f2a",
    code: "AT0702",
    name: "Communication centers",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f2d",
    activity: "6428fc0ea07fa6c7667d6f2a",
    code: "AT0703",
    name: "Telecommunication Company",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f2e",
    activity: "6428fc0ea07fa6c7667d6f2a",
    code: "AT0704",
    name: "Software Company",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f2f",
    activity: "6428fc0ea07fa6c7667d6f2a",
    code: "AT0705",
    name: "Internet Cafe",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f06",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0401",
    name: "Fabricated metal products Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f07",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0402",
    name: "Cornmill",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f08",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0403",
    name: "Wood products Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f09",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0404",
    name: "Printing press",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f0a",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0405",
    name: "Concrete products Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f0b",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0406",
    name: "Aluminium products Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f0c",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0407",
    name: "Computer and electronic products Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f0d",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0408",
    name: "Electrical products Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f0e",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0409",
    name: "Mineral water Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f0f",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0410",
    name: "Building Material Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f10",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0411",
    name: "Steel Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f11",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0412",
    name: "Pharmaceuticals",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f12",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0413",
    name: "Plastic and rubber Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f13",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0414",
    name: "Beverage Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f14",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0415",
    name: "Glassware Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f15",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0416",
    name: "Cosmetics Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f16",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0417",
    name: "Paper products Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f17",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0418",
    name: "Chemical products Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f18",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0419",
    name: "Leatherware Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f19",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0420",
    name: "Textile Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f1a",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0421",
    name: "Petroleum refinery",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f62",
    activity: "6428fc0ea07fa6c7667d6f61",
    code: "AT1501",
    name: "Ministry / Department / Agency",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f63",
    activity: "6428fc0ea07fa6c7667d6f61",
    code: "AT1502",
    name: "Prison",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f64",
    activity: "6428fc0ea07fa6c7667d6f61",
    code: "AT1503",
    name: "Military Barracks",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f65",
    activity: "6428fc0ea07fa6c7667d6f61",
    code: "AT1504",
    name: "Police Barracks",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f66",
    activity: "6428fc0ea07fa6c7667d6f61",
    code: "AT1505",
    name: "Police Station",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f67",
    activity: "6428fc0ea07fa6c7667d6f61",
    code: "AT1506",
    name: "Fire Service Station",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f68",
    activity: "6428fc0ea07fa6c7667d6f61",
    code: "AT1507",
    name: "Municipal / District Assembly",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f69",
    activity: "6428fc0ea07fa6c7667d6f61",
    code: "AT1508",
    name: "Government Office",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f31",
    activity: "6428fc0ea07fa6c7667d6f30",
    code: "AT0801",
    name: "Church",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f32",
    activity: "6428fc0ea07fa6c7667d6f30",
    code: "AT0802",
    name: "Mosque",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f33",
    activity: "6428fc0ea07fa6c7667d6f30",
    code: "AT0803",
    name: "Other religious centers",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f35",
    activity: "6428fc0ea07fa6c7667d6f34",
    code: "AT0901",
    name: "Hairdressers/Beauty Parlour",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f36",
    activity: "6428fc0ea07fa6c7667d6f34",
    code: "AT0902",
    name: "Tailoring Shop",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f37",
    activity: "6428fc0ea07fa6c7667d6f34",
    code: "AT0903",
    name: "Barbering Shop",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f38",
    activity: "6428fc0ea07fa6c7667d6f34",
    code: "AT0904",
    name: "Electronic Equipment Repair Shop",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f39",
    activity: "6428fc0ea07fa6c7667d6f34",
    code: "AT0905",
    name: "Laundary Service Shop",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f26",
    activity: "6428fc0ea07fa6c7667d6f25",
    code: "AT0601",
    name: "Red Cross",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f27",
    activity: "6428fc0ea07fa6c7667d6f25",
    code: "AT0602",
    name: "Private Cemetary",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f28",
    activity: "6428fc0ea07fa6c7667d6f25",
    code: "AT0603",
    name: "Old age homes",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f29",
    activity: "6428fc0ea07fa6c7667d6f25",
    code: "AT0604",
    name: "Funeral Homes",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ef7",
    activity: "6428fc0ea07fa6c7667d6ef6",
    code: "AT0201",
    name: "Wholesale Trade",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ef8",
    activity: "6428fc0ea07fa6c7667d6ef6",
    code: "AT0202",
    name: "Retail Trade",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ef9",
    activity: "6428fc0ea07fa6c7667d6ef6",
    code: "AT0203",
    name: "Book Shop",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6efa",
    activity: "6428fc0ea07fa6c7667d6ef6",
    code: "AT0204",
    name: "Supermarket",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6efb",
    activity: "6428fc0ea07fa6c7667d6ef6",
    code: "AT0205",
    name: "Mini Mart",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f6a",
    activity: "6428fc0ea07fa6c7667d6f61",
    code: "AT1509",
    name: "Unclassified",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f55",
    activity: "6428fc0ea07fa6c7667d6f4c",
    code: "AT1209",
    name: "Unclassified",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f60",
    activity: "6428fc0ea07fa6c7667d6f5c",
    code: "AT1404",
    name: "Unclassified",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f1b",
    activity: "6428fc0ea07fa6c7667d6f05",
    code: "AT0422",
    name: "Glassware Production",
    type: "SubActivity",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f6c",
    code: "PT0101",
    name: "Flat Building",
    premiseType: "6428fc0ea07fa6c7667d6f6b",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f6d",
    code: "PT0102",
    name: "Multi-storey house",
    premiseType: "6428fc0ea07fa6c7667d6f6b",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f6e",
    code: "PT0103",
    name: "Flat",
    premiseType: "6428fc0ea07fa6c7667d6f6b",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f6f",
    code: "PT0104",
    name: "Condominium",
    premiseType: "6428fc0ea07fa6c7667d6f6b",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f70",
    code: "PT0105",
    name: "One storey house",
    premiseType: "6428fc0ea07fa6c7667d6f6b",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f72",
    code: "PT0201",
    name: "Laboratory",
    premiseType: "6428fc0ea07fa6c7667d6f71",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f73",
    code: "PT0202",
    name: "Office Building",
    premiseType: "6428fc0ea07fa6c7667d6f71",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f74",
    code: "PT0203",
    name: "Other Offices",
    premiseType: "6428fc0ea07fa6c7667d6f71",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f87",
    code: "PT0401",
    name: "Tailoring shop",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f88",
    code: "PT0402",
    name: "Motor Company",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f89",
    code: "PT0403",
    name: "Department Store",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f8a",
    code: "PT0404",
    name: "Shopping Mall",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f8b",
    code: "PT0405",
    name: "Supermarket",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f8c",
    code: "PT0406",
    name: "Workshop",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f8d",
    code: "PT0407",
    name: "Floristry",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f8e",
    code: "PT0408",
    name: "Bookstore or Bookshop",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f8f",
    code: "PT0409",
    name: "Bakery",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f90",
    code: "PT0410",
    name: "Kiosk",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f91",
    code: "PT0411",
    name: "Market",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f92",
    code: "PT0412",
    name: "Dressmaking Shop",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f93",
    code: "PT0413",
    name: "Hairdressing/barbering shop",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f94",
    code: "PT0414",
    name: "Beauty/Cosmetic shop",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f95",
    code: "PT0415",
    name: "Craft Shop",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f96",
    code: "PT0416",
    name: "Mechanic Shop",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f97",
    code: "PT0417",
    name: "Other Shops",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f98",
    code: "PT0418",
    name: "Convention Center",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f99",
    code: "PT0419",
    name: "Forum",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f9a",
    code: "PT0420",
    name: "Gas Station",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f9b",
    code: "TF0421",
    name: "Shop",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f9c",
    code: "TF0422",
    name: "Fuel Station",
    premiseType: "6428fc0ea07fa6c7667d6f86",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa8",
    code: "PT0601",
    name: "Farmhouse",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa9",
    code: "PT0602",
    name: "Barn",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6faa",
    code: "PT0603",
    name: "Chickenhouse",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fab",
    code: "PT0604",
    name: "Cow-shed",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fac",
    code: "PT0605",
    name: "Granary",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fad",
    code: "PT0606",
    name: "Greenhouse",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fae",
    code: "PT0607",
    name: "Hayloft",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6faf",
    code: "PT0608",
    name: "Pigpen or Sty",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb0",
    code: "PT0609",
    name: "Root Cellar",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb1",
    code: "PT0611",
    name: "Silo",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb2",
    code: "PT0612",
    name: "Stable",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb3",
    code: "PT0613",
    name: "Stall",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb4",
    code: "PT0614",
    name: "Storm cellar",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb5",
    code: "PT0615",
    name: "Well House",
    premiseType: "6428fc0ea07fa6c7667d6fa7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb7",
    code: "PT0701",
    name: "Street Lights",
    premiseType: "6428fc0ea07fa6c7667d6fb6",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb8",
    code: "PT0702",
    name: "Electric Lighting",
    premiseType: "6428fc0ea07fa6c7667d6fb6",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fb9",
    code: "PT0703",
    name: "Children's Park",
    premiseType: "6428fc0ea07fa6c7667d6fb6",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fba",
    code: "PT0704",
    name: "Traffic Light",
    premiseType: "6428fc0ea07fa6c7667d6fb6",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fbb",
    code: "PT0705",
    name: "BillBoard",
    premiseType: "6428fc0ea07fa6c7667d6fb6",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fbc",
    code: "PT0706",
    name: "Parks and Garden",
    premiseType: "6428fc0ea07fa6c7667d6fb6",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe8",
    code: "PT1501",
    name: "Bank",
    premiseType: "6428fc0ea07fa6c7667d6fe7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe9",
    code: "PT1502",
    name: "Financial Centers",
    premiseType: "6428fc0ea07fa6c7667d6fe7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fea",
    code: "PT1503",
    name: "Office Building",
    premiseType: "6428fc0ea07fa6c7667d6fe7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6feb",
    code: "PT1504",
    name: "Other Offices",
    premiseType: "6428fc0ea07fa6c7667d6fe7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff2",
    code: "PT1701",
    name: "Restaurant",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff3",
    code: "PT1702",
    name: "Hotel",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff4",
    code: "PT1703",
    name: "Cinema",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff5",
    code: "PT1704",
    name: "Nightclub",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff6",
    code: "PT1705",
    name: "Food Court",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff7",
    code: "PT1706",
    name: "Guest House",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff8",
    code: "PT1707",
    name: "Eatery",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff9",
    code: "PT1708",
    name: "Fast-food Restaurant",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ffa",
    code: "PT1709",
    name: "Bar",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ffb",
    code: "PT1710",
    name: "Pub",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ffc",
    code: "PT1711",
    name: "Coffee House",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ffd",
    code: "PT1712",
    name: "Pizzaria",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ffe",
    code: "PT1713",
    name: "Sport Club",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fff",
    code: "PT1714",
    name: "Theater",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7000",
    code: "PT1715",
    name: "Amphitheater",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7001",
    code: "PT1716",
    name: "Concert Hall",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7002",
    code: "PT1717",
    name: "Opera House",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7003",
    code: "PT1718",
    name: "Stadium",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7004",
    code: "PT1719",
    name: "City/Town Square",
    premiseType: "6428fc0ea07fa6c7667d6ff1",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f76",
    code: "PT0301",
    name: "Factory",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f77",
    code: "PT0302",
    name: "Brewery",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f78",
    code: "PT0303",
    name: "Plant",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f79",
    code: "PT0304",
    name: "Foundry",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f7a",
    code: "PT0305",
    name: "Corn Mill",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f7b",
    code: "PT0306",
    name: "Saw Mill",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f7c",
    code: "PT0307",
    name: "Wood Workshop",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f7d",
    code: "PT0308",
    name: "Textile Manufacturer",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f7e",
    code: "PT0309",
    name: "Metal Work Welding",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f7f",
    code: "PT0310",
    name: "Furniture Manufacturer",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f80",
    code: "PT0311",
    name: "Other Manufacturer",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f81",
    code: "PT0312",
    name: "Printing Press",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f82",
    code: "PT0313",
    name: "Construction",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f83",
    code: "PT0314",
    name: "Plastic & Allied Ind.",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f84",
    code: "TF710",
    name: "Flour Mill",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f85",
    code: "TF711",
    name: "Rice Mill",
    premiseType: "6428fc0ea07fa6c7667d6f75",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc5",
    code: "PT0901",
    name: "Students' Union",
    premiseType: "6428fc0ea07fa6c7667d6fc4",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc6",
    code: "PT0902",
    name: "Community Centers",
    premiseType: "6428fc0ea07fa6c7667d6fc4",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc7",
    code: "PT0903",
    name: "Office",
    premiseType: "6428fc0ea07fa6c7667d6fc4",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc8",
    code: "PT0904",
    name: "Office Building",
    premiseType: "6428fc0ea07fa6c7667d6fc4",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fbe",
    code: "PT0801",
    name: "Church",
    premiseType: "6428fc0ea07fa6c7667d6fbd",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fbf",
    code: "PT0802",
    name: "Mosque",
    premiseType: "6428fc0ea07fa6c7667d6fbd",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc0",
    code: "PT0803",
    name: "Synagogue",
    premiseType: "6428fc0ea07fa6c7667d6fbd",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc1",
    code: "PT0804",
    name: "Temple",
    premiseType: "6428fc0ea07fa6c7667d6fbd",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc2",
    code: "PT0805",
    name: "Kingdom Halls",
    premiseType: "6428fc0ea07fa6c7667d6fbd",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fc3",
    code: "PT0806",
    name: "Other Religious Center",
    premiseType: "6428fc0ea07fa6c7667d6fbd",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd8",
    code: "PT1301",
    name: "School",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd9",
    code: "PT1302",
    name: "Library",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fda",
    code: "PT1303",
    name: "University",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fdb",
    code: "PT1304",
    name: "Gymnasium",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fdc",
    code: "PT1305",
    name: "Art Gallery",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fdd",
    code: "PT1306",
    name: "Kindergarten",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fde",
    code: "PT1307",
    name: "Classroom Building",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fdf",
    code: "PT1308",
    name: "College",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe0",
    code: "PT1309",
    name: "Museum",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe1",
    code: "PT1310",
    name: "Office",
    premiseType: "6428fc0ea07fa6c7667d6fd7",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fcc",
    code: "PT1101",
    name: "Hospitals",
    premiseType: "6428fc0ea07fa6c7667d6fcb",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fcd",
    code: "PT1102",
    name: "Retirement Home",
    premiseType: "6428fc0ea07fa6c7667d6fcb",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fce",
    code: "PT1103",
    name: "Clinic",
    premiseType: "6428fc0ea07fa6c7667d6fcb",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fcf",
    code: "PT1104",
    name: "Maternity Home",
    premiseType: "6428fc0ea07fa6c7667d6fcb",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd0",
    code: "PT1105",
    name: "Nursing",
    premiseType: "6428fc0ea07fa6c7667d6fcb",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd1",
    code: "PT1106",
    name: "Orphanage",
    premiseType: "6428fc0ea07fa6c7667d6fcb",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fca",
    code: "PT1001",
    name: "Mine",
    premiseType: "6428fc0ea07fa6c7667d6fc9",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe3",
    code: "PT1401",
    name: "Embassy",
    premiseType: "6428fc0ea07fa6c7667d6fe2",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe4",
    code: "PT1402",
    name: "Consulate",
    premiseType: "6428fc0ea07fa6c7667d6fe2",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe5",
    code: "PT1403",
    name: "Office",
    premiseType: "6428fc0ea07fa6c7667d6fe2",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fe6",
    code: "PT1404",
    name: "Office Building",
    premiseType: "6428fc0ea07fa6c7667d6fe2",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7006",
    code: "PT1801",
    name: "Airport Terminal",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7007",
    code: "PT1802",
    name: "Garage",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7008",
    code: "PT1803",
    name: "Warehouse",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7009",
    code: "PT1804",
    name: "Bus Station",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d700a",
    code: "PT1805",
    name: "Taxi Station",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d700b",
    code: "PT1806",
    name: "Train Station",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d700c",
    code: "PT1807",
    name: "Signal Box",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d700d",
    code: "PT1808",
    name: "Lighthouse",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d700e",
    code: "PT1809",
    name: "Parking Garage",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d700f",
    code: "PT1810",
    name: "Boathouse",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7010",
    code: "PT1811",
    name: "Hangar, of Aircraft or Spacecraft",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7011",
    code: "PT1812",
    name: "Storage Silo",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7012",
    code: "PT1813",
    name: "Cold Store",
    premiseType: "6428fc0ea07fa6c7667d7005",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d701b",
    code: "PT2001",
    name: "Unclassified",
    premiseType: "6428fc0ea07fa6c7667d701a",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7014",
    code: "PT1901",
    name: "Power Station",
    premiseType: "6428fc0ea07fa6c7667d7013",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7015",
    code: "PT1902",
    name: "Nuclear Power Plant",
    premiseType: "6428fc0ea07fa6c7667d7013",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7016",
    code: "PT1903",
    name: "Other Power Plant",
    premiseType: "6428fc0ea07fa6c7667d7013",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7017",
    code: "PT1904",
    name: "Water Producer",
    premiseType: "6428fc0ea07fa6c7667d7013",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7018",
    code: "PT1905",
    name: "Sewage Treatment Plant",
    premiseType: "6428fc0ea07fa6c7667d7013",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d7019",
    code: "PT1906",
    name: "Sub Station/Offices",
    premiseType: "6428fc0ea07fa6c7667d7013",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f9e",
    code: "PT0501",
    name: "City hall",
    premiseType: "6428fc0ea07fa6c7667d6f9d",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6f9f",
    code: "PT0502",
    name: "Courthouse",
    premiseType: "6428fc0ea07fa6c7667d6f9d",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa0",
    code: "PT0503",
    name: "Fire Station",
    premiseType: "6428fc0ea07fa6c7667d6f9d",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa1",
    code: "PT0504",
    name: "Meeting House",
    premiseType: "6428fc0ea07fa6c7667d6f9d",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa2",
    code: "PT0505",
    name: "Parliament",
    premiseType: "6428fc0ea07fa6c7667d6f9d",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa3",
    code: "PT0506",
    name: "Police Station",
    premiseType: "6428fc0ea07fa6c7667d6f9d",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa4",
    code: "PT0507",
    name: "Prison",
    premiseType: "6428fc0ea07fa6c7667d6f9d",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa5",
    code: "PT0508",
    name: "Office",
    premiseType: "6428fc0ea07fa6c7667d6f9d",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fa6",
    code: "PT0509",
    name: "Office Building",
    premiseType: "6428fc0ea07fa6c7667d6f9d",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd3",
    code: "PT1201",
    name: "Military Barracks",
    premiseType: "6428fc0ea07fa6c7667d6fd2",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd4",
    code: "PT1202",
    name: "Office Building",
    premiseType: "6428fc0ea07fa6c7667d6fd2",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd5",
    code: "PT1203",
    name: "Office",
    premiseType: "6428fc0ea07fa6c7667d6fd2",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fd6",
    code: "PT1204",
    name: "Other Military Building",
    premiseType: "6428fc0ea07fa6c7667d6fd2",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fed",
    code: "PT1601",
    name: "Data Center",
    premiseType: "6428fc0ea07fa6c7667d6fec",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fee",
    code: "PT1602",
    name: "Office Building",
    premiseType: "6428fc0ea07fa6c7667d6fec",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6fef",
    code: "PT1603",
    name: "Other Offices",
    premiseType: "6428fc0ea07fa6c7667d6fec",
    type: "PremiseCategory",
  },
  {
    _id: "6428fc0ea07fa6c7667d6ff0",
    code: "PT1604",
    name: "Cell Site",
    premiseType: "6428fc0ea07fa6c7667d6fec",
    type: "PremiseCategory",
  },
] as const;
