import { FC } from "react";
import { SearchSelectInput } from "components/core";
import ComplaintCategories from './complaint-categories.json';
import _ from "lodash";

interface ComplaintCategoryPickerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched?: any;
  setFieldError?: any;
  rawId?: boolean;
}


const ComplaintCategoryPicker: FC<ComplaintCategoryPickerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {
  return (
    <SearchSelectInput
      id={id ?? "activity"}
      label={label ?? ""}
      placeholder="Select Complaint Category"
      options={ComplaintCategories?.map(
        (complaint: any) => ({
          label: {
            title: _.startCase(complaint.name) as string,
          },
          value: rawId ? complaint?.name : complaint,
        })
      )}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default ComplaintCategoryPicker;
