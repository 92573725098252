import { gql, useQuery } from "@apollo/client";

const GET_SERVICE_REQUESTS = gql`
query GetServiceRequests(
  $page: Int
  $pageSize: Int
  $sort: String
  $search: String
  $searchFields: [String!]
  $region: ID
  $district: ID
  $status: ServiceRequestStatus
  $fromDate: Date
  $toDate: Date
) {
  rows: getServiceRequests(
    page: $page
    pageSize: $pageSize
    sort: $sort
    search: $search
    searchFields: $searchFields
    region: $region
    district: $district
    status: $status
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    code
    isBulk
    region {
      _id
      code
      name
    }
    district {
      _id
      code
      name
    }
    request {
      customer {
        customerType
        representative {
          fullName
          phoneNumber
        }
        organization {
          name
        }
      }
      property {
        ghanaPostAddress
        community
      }
      service {
        serviceType
        serviceClass
      }
    }
    category
    type
    status
    createdAt
    updatedAt
  }
  count: getServiceRequestsCount(
    region: $region
    district: $district
    search: $search
    searchFields: $searchFields
    status: $status
    fromDate: $fromDate
    toDate: $toDate
  )
}

`;

export const useServiceRequests = (variables: any) => {
  const { data, ...rest } = useQuery(GET_SERVICE_REQUESTS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  const serviceRequests = data?.rows || [];
  const count = data?.count || 0;
  return { serviceRequests, count, ...rest };
};
