import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { ServiceClasses, ServiceTypes } from "apollo/data";
import { TextArea, TextInput, UploadArea } from "components/core"
import { ActivityPicker, PremiseCategoryPicker, PremiseTypePicker, SubActivityPicker } from "containers";
import { FormikProps } from "formik"
import { ApproveServiceOrderFormSchema } from "pages/service-request/approve";
import { FC } from "react"
import { classNames } from "utils";

interface ApproveServiceOrderFormProps {
  form: FormikProps<ApproveServiceOrderFormSchema>;
}

const ApproveServiceOrderForm: FC<ApproveServiceOrderFormProps> = ({ form }) => {

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className='text-xs font-light'>Service Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>

          <div className='col-span-3'>
            <RadioGroup value={form.values.serviceType} onChange={form.handleChange("serviceType")}>
              <RadioGroup.Label className="text-sm font-medium text-gray-700">Service Type</RadioGroup.Label>
              <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                {ServiceTypes.map((serviceType) => (
                  <RadioGroup.Option
                    key={serviceType.value}
                    value={serviceType.value}
                    className={({ checked, active }) =>
                      classNames(
                        checked ? 'border-transparent' : 'border-gray-300',
                        active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                        'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                              {serviceType.title}
                            </RadioGroup.Label>
                            <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                              {serviceType.description}
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <CheckCircleIcon
                          className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-primary-600')}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            active ? 'border' : 'border-2',
                            checked ? 'border-primary-500' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <div className='col-span-3'>
            <RadioGroup value={form.values.serviceClass} onChange={form.handleChange("serviceClass")}>
              <RadioGroup.Label className="text-sm font-medium text-gray-700">Service Class</RadioGroup.Label>
              <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                {ServiceClasses.map((serviceClass) => (
                  <RadioGroup.Option
                    key={serviceClass.value}
                    value={serviceClass.value}
                    className={({ checked, active }) =>
                      classNames(
                        checked ? 'border-transparent' : 'border-gray-300',
                        active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                        'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                      )
                    }
                  >
                    {({ checked, active }) => (
                      <>
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                              {serviceClass.title}
                            </RadioGroup.Label>
                            <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                              {serviceClass.description}
                            </RadioGroup.Description>
                          </span>
                        </span>
                        <CheckCircleIcon
                          className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-primary-600')}
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            active ? 'border' : 'border-2',
                            checked ? 'border-primary-500' : 'border-transparent',
                            'pointer-events-none absolute -inset-px rounded-lg'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>
      <div>
        <span className="text-xs font-light">Premise Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-start-1">
            <PremiseTypePicker
              id="premiseType"
              label="Premise Type"
              placeholder="e.g. Accomodation"
              required={true}
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue("premiseCategory", null);
                form.setFieldValue(field, value);
              }}
            />
          </div>
          <div className="col-span-2">
            <PremiseCategoryPicker
              id="premiseCategory"
              label="Premise Category"
              placeholder="e.g. Hotel"
              required={true}
              disabled={!form.values.premiseType}
              {...form}
              filter={{
                premiseType: form?.values?.premiseType?._id
              }}
            />
          </div>
          <div className="col-start-1">
            <ActivityPicker
              id="activity"
              label="Activity"
              placeholder="e.g. Hospitality"
              required={true}
              {...form}
              setFieldValue={(field: string, value: string) => {
                form.setFieldValue("subActivity", null);
                form.setFieldValue(field, value);
              }}
            />
          </div>
          <div className="col-span-2">
            <SubActivityPicker
              id="subActivity"
              label="Sub Activity"
              placeholder="e.g. Guest House"
              required={true}
              disabled={!form.values.activity}
              {...form}
              filter={{
                activity: form?.values?.activity?._id
              }}
            />
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className='text-xs font-light'>Energy Certification Information</span>
        <div className='grid grid-cols-6 gap-6 mt-2'>

          <div className="col-span-2">
            <TextInput
              id="energyCertificateIssuerName"
              label="Energy Certification Issuer Name"
              type="text"
              placeholder='eg. Emmanuel Baidoo'
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-2">
            <TextInput
              id="energyCertificateIssuerPhone"
              label="Energy Certification Issuer Phone Number"
              type="text"
              placeholder='eg. 0506339153'
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-2">
            <TextInput
              id="energyCertificateIssuerId"
              label="Energy Certification Issuer ID"
              type="text"
              placeholder='eg. 55445-7444'
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-2">
            <TextInput
              id="energyCertificateNumber"
              label="Energy Certification Number"
              type="text"
              placeholder='eg. GH034034034'
              required={true}
              {...form}
            />
          </div>
          <div className="col-span-4">
            <UploadArea
              id="energyCertificateDocumentUrl"
              label="Energy Commission Certificate"
              required={true}
              accept={{
                "image/*": [".png", ".jpeg", ".jpg"],
                "application/pdf": [".pdf"]
              }}
              {...form}
            />
          </div>

        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Approval Information</span>
        <div className="grid gap-6 mt-2">
          <div>
            <TextArea
              id="reason"
              label="Notes"
              placeholder="e.g. Details captured are conclusive"
              required={false}
              rows={10}
              {...form}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default ApproveServiceOrderForm