import { Outlet, Route } from "react-location";
import {
  CustomersPage,
  DashboardPage,
  ForgotPasswordPage,
  SigninPage,
  CreateServiceRequestPage,
  ServiceRequestPage,
  ServiceRequestsPage,
  AuthPage,
  ServiceRegularizationsPage,
  ServiceRegularizationPage,
  ComplaintsPage,
  ServicePointReadingsPage,
  ShepRegularizationsPage,
  FlatRateCustomersPage,
  ComplaintPage,
} from "pages";
import {
  BoltSlashIcon,
  CalculatorIcon,
  ChartBarSquareIcon,
  LifebuoyIcon,
  UserCircleIcon,
  UserPlusIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { UserPermission } from "apollo/cache/auth";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <DashboardPage />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "General",
    },
  },
  {
    path: "service-points",
    element: <Outlet />,
    sidebar: {
      label: "Service Points",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Customers",
      section: "Structure",
    },
    children: [
      {
        path: "/",
        element: <CustomersPage />,
        meta: {
          layout: "App",
          section: "Structure",
        },
        withPermissions: ["*:*", "customers:*", "customers:manage"],
      },
      {
        path: ":servicePoint/readings",
        element: <ServicePointReadingsPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Service Point Readings",
          section: "Structure",
        },
        withPermissions: ["*:*", "customers:*", "customers:manage"],
      },
    ],
    withPermissions: ["*:*", "customers:*", "customers:manage"],
  },
  {
    path: "service-requests",
    element: <Outlet />,
    sidebar: {
      label: "Service Requests",
      icon: UserPlusIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Service Requests",
      section: "Customer Relation",
    },
    children: [
      {
        path: "/",
        element: <ServiceRequestsPage />,
        meta: {
          layout: "App",
          section: "Customer Relation",
        },
      },
      {
        path: "new",
        element: <CreateServiceRequestPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Create Service Request",
          section: "Customer Relation",
        },
      },
      {
        path: ":serviceRequest",
        element: <ServiceRequestPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Service Request",
          section: "Customer Relation",
        },
      },
    ],
    withPermissions: ["*:*", "service-requests:*", "service-requests:manage"],
  },
 
  {
    path: "complaints",
    element: <Outlet />,
    sidebar: {
      label: "Tickets",
      icon: BoltSlashIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Tickets",
      section: "Customer Relation",
    },
    children: [
      {
        path: "/",
        element: <ComplaintsPage />,
        meta: {
          layout: "App",
          section: "Customer Relation",
        },
      },

      {
        path: ":complaint",
        element: <ComplaintPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Ticket",
          section: "Customer Relation",
        },
      },
    ],
    withPermissions: ["*:*", "service-requests:*", "service-requests:manage"],
  },
  {
    path: "shep-regularizations",
    element: <ShepRegularizationsPage />,
    sidebar: {
      label: "SHEP Captures",
      icon: LifebuoyIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "SHEP Captures",
      section: "Customer Relation",
    },
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },
  {
    path: "service-regularizations",
    element: <Outlet />,
    sidebar: {
      label: "Regularizations",
      icon: UserCircleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularizations",
      section: "Customer Relation",
    },
    children: [
      {
        path: "/",
        element: <ServiceRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Customer Relation",
        },
      },
      {
        path: ":serviceRegularization",
        element: <ServiceRegularizationPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Regularization",
          section: "Customer Relation",
        },
      },
    ],
    withPermissions: ["*:*", "service-requests:*", "service-requests:manage"],
  },
  {
    path: "flat-rate-customers",
    element: <FlatRateCustomersPage />,
    sidebar: {
      label: "Flat Rate Customers",
      icon: CalculatorIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Flat Rate Customers",
      section: "Customer Relation",
    },
    withPermissions: ["*:*", "service-points:*", "service-points:manage"],
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
  {
    path: "auth",
    element: <AuthPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
