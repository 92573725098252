import { StepWizardAlt } from "components";
import { FormikProps } from "formik";
import { FC, useMemo, useState } from "react";
import SummaryPage from "./summary";
import formSteps from "./form-steps";
import {
  ICreateServiceRequestFormSchema,
  sanitizeCustomer,
  sanitizeIdentity,
  sanitizeProperty,
  sanitizeService,
} from "./schema";

interface CreateServiceRequestFormProps {
  form: FormikProps<ICreateServiceRequestFormSchema>;
  isSubmitLoading: boolean;
}

const CreateServiceRequestForm: FC<CreateServiceRequestFormProps> = ({
  form,
  isSubmitLoading,
}) => {
  const filteredFormSteps = useMemo(
    () =>
      formSteps
        .filter((_step) => Object.keys(form.values).includes(_step.accessor))
        .map((_step, href) => ({ ..._step, href })),
    [Object.keys(form.values)]
  );

  const steps = useMemo(
    () =>
      [
        ...filteredFormSteps,
        {
          name: "Preview",
          FormComponent: SummaryPage,
          accessor: "summary",
          description: "Summary of everything added so far",
        },
      ].map((rawStep, href) => ({ ...rawStep, href })),
    [filteredFormSteps]
  );

  const [step, setStep] = useState(0);
  const [lastStep, setLastStep] = useState(0);

  const handleNextStep = (_step: string) => (_values: any) => {
    // check if last step
    form.setFieldValue(_step, _values);
    setStep(step + 1);
    setLastStep(step === lastStep ? lastStep + 1 : lastStep);
  };

  const handleProStep = (
    ___step: number,
    ___lastStep: number,
    _values: any
  ) => {
    // check if last step
    form.setFieldValue("requestInfo", _values);
    if (_values.existingServicePoint) {
      const { customer: existingCustomer, property: existingProperty } =
        _values.existingServicePoint;
      if (existingCustomer) {
        if (___step === ___lastStep) {
          form.setFieldValue(
            "customerInfo",
            existingCustomer
              ? sanitizeCustomer(existingCustomer)
              : form.values?.customerInfo
          );
          form.setFieldValue(
            "identityInfo",
            existingCustomer
              ? sanitizeIdentity(existingCustomer)
              : form.values?.identityInfo
          );
        }
      }
      if (existingProperty && _values.type !== "NewServiceRequest") {
        if (___step === ___lastStep) {
          form.setFieldValue(
            "propertyInfo",
            existingProperty
              ? sanitizeProperty(existingProperty)
              : form.values?.propertyInfo
          );
          form.setFieldValue(
            "serviceInfo",
            existingProperty
              ? sanitizeService(existingProperty)
              : form.values?.serviceInfo
          );
        }
      }
    } else {
      if (___step === ___lastStep) {
        form.setFieldValue("customerInfo", form.initialValues?.customerInfo);
        form.setFieldValue("propertyInfo", form.initialValues?.propertyInfo);
      }
    }
    setStep(step + 1);
    setLastStep(step === lastStep ? lastStep + 1 : lastStep);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  };

  const renderForm = () => {
    const { FormComponent, accessor } = steps[step || 0];
    return (
      <FormComponent
        handleNext={handleNextStep(accessor)}
        handlePro={handleProStep}
        initialValues={(form.values as any)[accessor]}
        values={form.values}
        parentForm={form}
        handlePrevious={handlePreviousStep}
        handleCancel={handleCancel}
        handleStep={setStep}
        handleSubmit={form.handleSubmit}
        submitLoading={isSubmitLoading || form.isSubmitting}
        steps={filteredFormSteps}
        step={step || 0}
        lastStep={lastStep || 0}
      />
    );
  };

  return (
    <div className="bg-gray-100 shadow-xl flex-1 flex p-8 overflow-hidden relative">
      <div className="w-1/3 justify-center flex flex-col">
        <StepWizardAlt
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className="flex flex-1 overflow-hidden bg-white rounded-lg">
        {renderForm()}
      </div>
    </div>
  );
};

export default CreateServiceRequestForm;
