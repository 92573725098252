import { makeVar, useReactiveVar } from "@apollo/client";
import Cookies from "js-cookie";
import config from "config";
import { Permissions } from "apollo/data";
import { use } from "i18next";

export const UserPermissions = Permissions.map(({ value }) => value);
export type UserPermission = (typeof UserPermissions)[number];
export const Applications = [
  "analytics",
  "billing",
  "commercial",
  "identity",
  "technical",
] as const;
export type Application = (typeof Applications)[number];

interface IUser {
  _id: string;
  code: string;
  lastName: string;
  firstName: string;
  gender?: "Male" | "Female";
  ghanaCardNumber?: string;
  phoneNumber: string;
  emailAddress: string;
  profileImageUrl: string;
  region: {
    _id: string;
  };
  regionCode: string;
  regionName: string;
  district: {
    _id: string;
  };
  districtCode: string;
  districtName: string;
  meta: {
    isPasswordSet: boolean;
    lastLoginAt: Date;
  };
  role: {
    _id: string;
    code: string;
    name: string;
    applications: Application[];
    permissions: UserPermission[];
  };
  createdAt: Date;
  updatedAt: Date;
}

export const isLoggedInVar = makeVar<boolean>(!!Cookies.get(`subs:auth:token`));
export const currentTokenVar = makeVar<string | null>(
  Cookies.get(`subs:auth:token`) || null
);
export const currentUserVar = makeVar<IUser>(
  JSON.parse(Cookies.get(`subs:auth:user`) ?? "{}") ?? null
);
export const currentPushTokenVar = makeVar<string | null>(
  Cookies.get(`subs:auth:push-token`) || null
);

export const setAuth = ({ user, token }: { user: IUser; token: string }) => {
  currentUserVar(user);
  Cookies.set(`subs:auth:user`, JSON.stringify(user), { ...config.cookies, expires: 1 });
  isLoggedInVar(true);
  currentTokenVar(token);
  Cookies.set(`subs:auth:token`, token, { ...config.cookies, expires: 1 });
};

export const setMe = (user: IUser) => {
  currentUserVar(user);
  Cookies.set(`subs:auth:user`, JSON.stringify(user), { ...config.cookies , expires: 1});
};

export const setPushToken = (token: any) => {
  currentPushTokenVar(token);
  Cookies.set(`subs:auth:push-token`, token, { ...config.cookies, expires: 1 });
};

export const clearAuth = () => {
  isLoggedInVar(false);
  currentTokenVar(null);
  // TODO: handle extra checks for better user experience
  Object.keys(Cookies.get()).forEach((key) => {
    Cookies.remove(key, { ...config.cookies });
  });
};
