import { FC } from "react";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import Map from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapProvider, Marker } from "react-map-gl";
import config from "config";

interface ReadingViewProps {
  shepRegularization: any;
}

const ReadingView: FC<ReadingViewProps> = ({ shepRegularization }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className='space-y-6 divide-y divide-gray-200'>
      <div>
        <span className='text-xs font-light'>Service Point Information</span>
        <div className='grid grid-cols-3 gap-6 mt-2'>
          <div className='row-span-3 flex flex-col space-y-3 items-center justify-center'>
            <Avatar
              alt={
                shepRegularization?.customerData?.representative?.fullName ||
                shepRegularization?.customerData?.representative?.fullName ||
                "N A"
              }
              src={
                shepRegularization?.customerData?.representative
                  ?.profileImageUrl ||
                shepRegularization?.customerData?.representative
                  ?.profileImageUrl ||
                ""
              }
              size='xl'
            />
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              ServicePoint Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Ghana Card Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.customerData?.representative
                ?.ghanaCardNumber ||
                shepRegularization?.customerData?.representative
                  ?.ghanaCardNumber ||
                "N/A"}
            </div>
          </div>
          <div className='col-span-2'>
            <span className='block text-sm font-light text-gray-700'>
              Full Name
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.customerData?.representative?.fullName ||
                shepRegularization?.customerData?.representative?.fullName ||
                "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Phone Number
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.customerData?.representative?.phoneNumber ||
                shepRegularization?.customerData?.representative?.phoneNumber ||
                "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Email Address
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.customerData?.representative?.emailAddress ||
                "N/A"}
            </div>
          </div>
        </div>
      </div>

      <div className='pt-6'>
        <span className='text-xs font-light'>Regularization Information</span>
        <div className='grid grid-cols-4 gap-6 mt-2'>
          <div>
            <span className='block text-sm font-light text-gray-700'>Date</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.createdAt
                ? moment(shepRegularization.createdAt).format(dateFormat)
                : "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>Code</span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Account Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.accountCode || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Customer Code
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.accountCode || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              Region
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.region?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className='block text-sm font-light text-gray-700'>
              District
            </span>
            <div className='mt-1 block w-full sm:text-sm'>
              {shepRegularization?.district?.name || "N/A"}
            </div>
          </div>
       
        </div>
      </div>
    </div>
  );
};

export default ReadingView;
