import { FC } from "react";
import { ICurrentConfig } from "apollo/cache/config";
import moment from "moment";


const RequestInfoSummary: FC<{
  data: {
    code: string;
    servicePoint: any;
    meter: any;
    regularizedMeterCode: string;
    createdAt: string;
    createdBy: any;
  };
  config: ICurrentConfig;
  isDisplay?: boolean;
}> = ({ data, isDisplay, config: { dateFormat } }) => (
  <div className='border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200'>
    <div className=''>
      <span className='text-xs font-light'>Request Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div className='col-span-1'>
          <span className='block text-sm font-light text-gray-700'>
            Regularization Code
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {data?.code || "N/A"}
          </div>
        </div>
        <div className='col-span-1'>
          <span className='block text-sm font-light text-gray-700'>
            Regularization Date
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {moment(data.createdAt).format(dateFormat) || "N/A"}
          </div>
        </div>
        <div className='col-span-1'>
          <span className='block text-sm font-light text-gray-700'>
            Regularized By
          </span>
          <div className='mt-1 block w-full sm:text-sm'>
            {[data?.createdBy?.firstName, data?.createdBy?.firstName].join(" ").trim() || "N/A"}
          </div>
        </div>
      </div>
    </div>
      <div className='pt-6'>
        <span className='text-xs font-light'>
          Regularization Information
        </span>

        <div className='mt-2 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md'>
        
          <div className='grid grid-cols-4 gap-4 p-4'>
            <div className=''>
              <span className='block text-sm font-light text-gray-700'>
                Account Number
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.servicePoint?.accountCode || "N/A"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Customer Number
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.servicePoint?.customerCode || "N/A"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Property Number
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.servicePoint?.propertyCode || "N/A"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Service Point Number
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.servicePoint?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Flat Rate Meter
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.meter?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className='block text-sm font-light text-gray-700'>
                Regularized Meter
              </span>
              <div className='mt-1 block w-full sm:text-sm'>
                {data?.regularizedMeterCode || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
);

export default RequestInfoSummary;
