import { gql, useQuery, useReactiveVar } from '@apollo/client';
import { Shimmers, TableComponent } from 'components';
import moment from 'moment';
import { FC, useEffect, useMemo } from 'react'
import { useNavigate, useSearch } from 'react-location';
import { LocationGenerics } from 'router/location';
import { wrapClick, useUrlState, withPermissions } from 'utils';
import config from 'config';
import ViewCustomerContainer from './view';
import { Avatar } from 'components/core';
import numeral from 'numeral';
import { currentConfigVar } from 'apollo/cache/config';
import BillsFilterContainer from './filter';
import SendBillContainer from './send';
import ActionButton, { Action } from 'components/buttons/action-button';
import { DistrictPicker, ExportDataContainer, RegionPicker } from 'containers';

const GET_BILLS = gql`
query GetBills(
  $page: Int $pageSize: Int $sort: String $search: String $searchFields: [String!]
  $region: ID $district: ID
) {
  getBills(
    page: $page pageSize: $pageSize sort: $sort search: $search searchFields: $searchFields
    region: $region district: $district
  ) {
      rows {
        _id
        code
        emailAddress
        phoneNumber
        fullName
        profileImageUrl
        ghanaCardNumber
        payer {
          fullName
          emailAddress
        }
        servicePointNumber
        accountNumber
        geoCode
        contractedDemand
        contractStatus
        meterNumber
        customerType
        serviceClass
        serviceType
        tariffClassCode
        tariffClassName
        propertyCode
        qrCode
        address
        ghanaPostAddress
        customerCode
        readingSetCode
        readingSetName
        month
        year
        consumptionPeriod
        billPeriod
        billStartDate
        billEndDate
        previousBill {
          readingDate
          readingValue
          readingEstimated
          consumptionValue
          billValue
          totalAmountDue
        }
        currentBill {
          readingDate
          readingValue
          readingEstimated
          consumptionValue
          billValue
          totalAmountDue
        }
        currentBillAmount
        outstandingAmount
        totalAmountDue
        totalAmountDueAt
        createdAt
        updatedAt
      }
      total
      totalPages
      page
      pageSize
    }
  }
`

const BillsPage: FC = () => {
  const { dateFormat, pollInterval } = useReactiveVar(currentConfigVar);
  const dispatchAction = (id: string, action: Exclude<Action, ("expand" | "goto" | "clone")>) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action
      })
    })
  }

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [region, setRegion] = useUrlState("region");
  const [district, setDistrict] = useUrlState("district");

  const filter = useMemo(() => ({
    page: searchParams.page || 1,
    pageSize: searchParams.pageSize || 12,
    search: (searchParams.search || "").toString(),
    searchFields: ["qrCode", "geoCode", "propertyCode", "ghanaPostAddress", "address"],
    sort: searchParams.sort || "",
    region: searchParams.region || undefined,
    district: searchParams.district || undefined,
    block: searchParams.block || undefined,
    round: searchParams.round || undefined,
    plot: searchParams.plot || undefined,
    customerType: searchParams.customerType || undefined,
    serviceType: searchParams.serviceType || undefined,
    serviceClass: searchParams.serviceClass || undefined,
    tariffClass: searchParams.tariffClass || undefined,
  }), [searchParams]);

  const { data, loading, networkStatus, refetch } = useQuery(GET_BILLS, {
    variables: filter,
    notifyOnNetworkStatusChange: false
  });

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "-createdAt" } : {}),
      })
    })
  }, [navigate])

  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50">
      <div className='flex flex-1 overflow-y-auto'>
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={'bills'}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={data?.getBills}
            hasSearch={true}
            renderFilter={({ filterOpen, setFilterOpen }) => (
              <BillsFilterContainer
                open={filterOpen}
                setOpen={setFilterOpen}
              />
            )}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                open={exportOpen}
                setOpen={setExportOpen}
                entityType="Bill"
                filter={filter}
              />
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex space-x-2">
              <RegionPicker
                id="region"
                labelHidden={true}
                setFieldTouched={() => {}} setFieldError={() => {}}
                setFieldValue={(_field: string, value: string) => {
                  setRegion(value);
                  setDistrict(undefined);
                }}
                values={{ region }}
                rawId={true}
              />
              {region && (
                <DistrictPicker
                  id="district"
                  labelHidden={true}
                  setFieldTouched={() => {}} setFieldError={() => {}}
                  setFieldValue={(_field: string, value: string) => setDistrict(value)}
                  values={{ district }}
                  filter={{ region }}
                  rawId={true}
                />
              )}
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Bill No | Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  SPN | Account No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Geo Code | Meter No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Bill Period
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Consumption | Bill
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr className="border-t border-gray-200">
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={2} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr key={item._id} className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer" onClick={wrapClick(dispatchAction(item._id, "view"))}>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.code || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{moment(item?.createdAt).format(dateFormat)}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={item?.fullName?.trim() || "N A"}
                        src={item?.profileImageUrl || ""}
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">{item?.fullName?.trim() || "N A"}</div>
                      <div className="text-gray-500 dark:text-gray-400">{item?.customerCode || "N/A"}</div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.servicePointNumber || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.accountNumber || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">{item?.geoCode || "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.meterNumber || "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200  text-right">
                  <div className="text-gray-900 dark:text-gray-100">{moment(item?.billStartDate || item?.previousBill?.readingDate).format(dateFormat)} - {moment(item?.billEndDate || item?.currentBill?.readingDate).format(dateFormat)} </div>
                  <div className="text-gray-500 dark:text-gray-400 ">{numeral(item?.billPeriod || item?.consumptionPeriod || 0).format("0,0")} days</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200  text-right">
                  <div className="text-gray-900 dark:text-gray-100">{item?.currentBill?.consumptionValue ? `${numeral(item.currentBill.consumptionValue || 0).format("0,0")} KWh` : "N/A"}</div>
                  <div className="text-gray-500 dark:text-gray-400">{item?.currentBill?.billValue ? `GHS ${numeral(item.currentBill.billValue || 0).format("0,0.00")}` : "N/A"}</div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className='space-x-1'>
                    <ActionButton
                      action='view'
                      onClick={dispatchAction(item?._id, "view")}
                    />
                    {withPermissions(["bills:*", "bills:send"])(
                      <ActionButton
                        action='send'
                        onClick={dispatchAction(item?._id, "send")}
                      />
                    )}
                  </div>
                </td>
              </tr>
            )}
          />

        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewCustomerContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          {withPermissions(["bills:*", "bills:send"])(
            <SendBillContainer
              open={modal === "send"}
              setOpen={(val: boolean) => setModal(val ? "send" : undefined)}
            />
          )}
        </>
      )}
    </main>
  )
}

export default BillsPage;
