import { gql, useMutation } from "@apollo/client";
import { Modal } from "components";
import { useMatch, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import toast from "react-hot-toast";
import ResolveComplaintForm from "./forms/resolve";
import { useFormik } from "formik";
import { wrapClick } from "utils";


const CLOSE_RESOLUTION = gql`
mutation CloseComplaint(
  $id: ID!
  $resolution: ComplaintResolutionInput
) {
  closeComplaint(id: $id, resolution: $resolution) {
    _id
  }
}
`;


export default function ResolveComplaintContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const [closeTicket, { loading: closing }] = useMutation(CLOSE_RESOLUTION);
  const searchParams = useSearch<LocationGenerics>();
  const { params } = useMatch<LocationGenerics>();

  const form = useFormik<any>({
    initialValues: {
      resolution: {
        notes: "",
        supportingDocumentUrls: []
      },
    },
    onSubmit: async (values) => {
      closeTicket({ variables: { id: searchParams.id || params.complaint, resolution: values.resolution } }).then(() => {
        refetch?.();
        setOpen(false);
        form.resetForm();
        toast(
          JSON.stringify({
            type: "success",
            title: "Ticket Resolved Successfully",
          })
        );
      });
    },
    onReset: () => {
      setOpen(false);
    },
  })


  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title='Resolve Ticket'
      size="4xl"
      description='Provide a resolution to the ticket.'
      renderActions={() => (
        <>
          <button
            type='button'
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {closing ? "Resolving ..." : "Resolve Ticket"}
          </button>
        </>
      )}
    >
      <ResolveComplaintForm form={form} />
    </Modal>
  );
}
