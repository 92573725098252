import { FC } from "react";
import { wrapImage } from "utils";

const ServiceInfoSummary: FC<{
  data: any;
}> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className="grid grid-cols-3 gap-4">
      <div>
        <span className="block text-sm font-light text-gray-700">
          Installed Meter Number
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.meter?.code || "N/A"}
        </div>
      </div>
      <div className="row-span-4 col-span-2">
        <span className="block text-sm font-light text-gray-700">
          Installed Meter Images
        </span>
        <div className="mt-2 grid grid-cols-1 gap-3">
          {data?.property?.propertyImageUrls?.map((image: string) =>
            wrapImage(
              <img
                src={image}
                alt={"back"}
                className="w-full h-48 text-xs object-cover object-center rounded"
              />
            )
          )}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
          Installed Meter Make 
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.meter?.modelMeta?.brandName || "N/A"}
        </div>
      </div>
      <div>
        <span className="block text-sm font-light text-gray-700">
          Installed Meter Model
        </span>
        <div className="mt-1 block w-full sm:text-sm">
          {data?.meter?.modelMeta?.modelName || "N/A"}
        </div>
      </div>
    </div>
  </div>
);

export default ServiceInfoSummary;
