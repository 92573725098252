import ServiceInfoSummary from "./service-info-summary";
import PropertyInfoSummary from "./property-info-summary";
import CustomerInfoSummary from "./customer-info-summary";
import IdentityInfoSummary from "./identity-info-summary";
import RequestInfoSummary from "./request-info-summary";

const serviceRegularizationSteps = [
  {
    name: "Regularization Info",
    description: "Information about the regularization.",
    accessor: "requestInfo",
    SummaryComponent: RequestInfoSummary,
  },
  {
    name: "Customer Info",
    description: "Information about the customer making the request.",
    accessor: "customerInfo",
    SummaryComponent: CustomerInfoSummary,
  },
  {
    name: "Identity Info",
    description: "Information about the identity of the customer.",
    accessor: "identityInfo",
    SummaryComponent: IdentityInfoSummary,
  },
  {
    name: "Property Info",
    description: "Information about the facility / premise of the service.",
    accessor: "propertyInfo",
    SummaryComponent: PropertyInfoSummary,
  },
  {
    name: "Service Info",
    description: "Information about the service required by the customer.",
    accessor: "serviceInfo",
    SummaryComponent: ServiceInfoSummary,
  },
];

export default serviceRegularizationSteps;
