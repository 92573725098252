import { FC } from "react"
import _ from "lodash"
import numeral from "numeral"

const ServiceInfoSummary: FC<{ data: any }> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className=''>
      <span className='text-xs font-light'>Meter Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Pole Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.poleNumber || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Meter Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meterNumber || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Meter Make
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meterModel?.make || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Meter Model
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meterModel?.model || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Meter Digits
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meterModel?.digits ? numeral(data?.meterModel?.digits).format("0,0") : "N/A"}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ServiceInfoSummary