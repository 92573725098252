import { useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal, } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { GET_CUSTOMER } from "./view";
import { withPermissions, wrapClick } from "utils";
import * as Yup from "yup";
import TerminateCustomerForm from "./forms/terminate-customer";

const UPDATE_CUSTOMER = gql`
mutation TerminateServicePoint(
  $terminateServicePointId: ID!
  $reading: TerminateServicePointReading
  $reason: String
) {
  terminateServicePoint(
    id: $terminateServicePointId
    reading: $reading
    reason: $reason
  ) {
    _id
  }
}
`;

export default function TerminateCustomerContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      getServicePointId: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const form = useFormik<any>({
    initialValues: {
      // type: "",
      reading: {
        readingDate: "",
        readingValue: "",
      },
      reason: "",
    },
    validationSchema: Yup.object().shape({
      // type: Yup.string().required("Suspension is Required"),
      reading: Yup.object().shape({
        readingDate: Yup.string().required("Reading Date is Required"),
        readingValue: Yup.string().required("Reading Value is Required"),
      }),
      reason: Yup.string().required("Reason is Required"),
    }),
    onSubmit: async (values) => {
      await updateCustomer({
        variables: {
          terminateServicePointId: data?.customer?._id,
          reading: values.reading,
          type: values.type,
          reason: values.reason,
        },
      }).then(({ data }) => {
        if (data.terminateServicePoint._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Customer terminated Successfully",
            })
          );
          refetch?.();
          setOpen(false);
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not terminate Customer",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.customer) {
      // alert("Hello" + data?.customer?.customer?.fullName)
      form.setValues({
        fullName: data?.customer?.customer?.representative?.fullName,
        primaryPhoneNumber: data?.customer?.customer?.representative?.phoneNumber || "",
        primaryEmailAddress: data?.customer?.customer?.representative?.emailAddress || "",
        reason: "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customer, searchParams.id, open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      // hideActions={true}
      loading={loading}
      size='4xl'
      title='Terminate Customer'
      description='Provide the details to terminate customer'
      renderActions={() => (
        <>
          {withPermissions(["*:*", "service-points:*", "service-points:update"])(
            <button
              type='button'
              disabled={form.isSubmitting || !form.isValid}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(form.handleSubmit)}
            >
              {form.isSubmitting ? "Saving Changes ..." : "Save Changes"}
            </button>
          )}
        </>
      )}
    >
      {/* {JSON.stringify(form.errors, null, 2)} */}
      {data?.customer?._id && (
        <TerminateCustomerForm form={form} customer={data?.customer} />
      )}
    </Modal>
  );
}
