import { FC } from "react";
import { Avatar } from "components/core";
import { classNames, wrapImage } from "utils";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";

interface CustomerReadingViewProps {
  readings: any[];
  loading: boolean;
}

const CustomerReadingView: FC<CustomerReadingViewProps> = ({ readings }) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      {readings?.length > 0 ? (
        <div>
          {/* <!-- Heading --> */}
          <div className="ps-2 my-2 first:mt-0">
            <h3 className="text-xs font-medium uppercase text-gray-500 dark:text-gray-400">
              Reading History
            </h3>
          </div>
          {readings?.map((reading) => (
            <div className="flex gap-x-3">
              {/* <!-- Icon --> */}
              <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-gray-700">
                <div className="relative z-10 size-7 flex justify-center items-center">
                  <div className="size-2 rounded-full bg-gray-400 mr-4 dark:bg-gray-600"></div>
                </div>
              </div>
              {/* <!-- End Icon --> */}

              {/* <!-- Right Content --> */}
              <div className="grow pt-0.5 pb-8">
                <h3 className="flex gap-x-1.5 items-center font-semibold text-gray-800 dark:text-white">
                  <svg className="flex-shrink-0 size-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" /><polyline points="14 2 14 8 20 8" /><line x1="16" x2="8" y1="13" y2="13" /><line x1="16" x2="8" y1="17" y2="17" /><line x1="10" x2="8" y1="9" y2="9" /></svg>
                  Reading captured on {moment(reading?.currentReading?.readingDate).format(
                    "DD MMM, YYYY LT"
                  )}
                </h3>
                <div className="grid grid-cols-3 mt-5 gap-11">
                  <div className="mt-1 block w-full sm:text-sm">
                    {wrapImage(
                      <img
                        src={reading?.meta?.readingImageUrl}
                        alt="reading"
                        className="h-64 w-full rounded-xl object-cover object-center"
                      />
                    )}
                  </div>
                  <div>
                    <span className="text-xs font-light">Reading Information</span>
                    <div className="grid grid-cols-1 gap-11">
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          Current Reading Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {reading?.currentReading?.readingDate
                            ? moment(reading.currentReading.readingDate).format(
                              dateFormat
                            )
                            : "N/A"}
                        </div>
                      </div>
                      <div>
                        <span className="block text-sm font-light text-gray-700">
                          Current Reading Value
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {reading?.currentReading?.readingValue
                            ? `${numeral(
                              reading.currentReading.readingValue || 0
                            ).format("0,0")} KWh`
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

              </div>
              {/* <!-- End Right Content --> */}
            </div>
          ))}
          {/* <div className="ps-[7px] flex gap-x-3">
            <button type="button" className="hs-collapse-toggle hs-collapse-open:hidden text-start inline-flex items-center gap-x-1 text-sm text-blue-600 font-medium decoration-2 hover:underline dark:text-blue-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" id="hs-timeline-collapse-content" data-hs-collapse="#hs-timeline-collapse">
              <svg className="flex-shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6" /></svg>
              Show older
            </button>
          </div> */}
        </div>
      ) : (
        <div className="flex min-h-[600px] items-center justify-center">
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-300"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-100">
              No Reading History
            </h3>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              Kindly Refer To CMS For Reading History.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerReadingView;
