import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { Header, CreateServiceRequestForm } from "components";
import { FC } from "react";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { ICreateServiceRequestFormSchema } from "components/forms/create-service-request/schema";
import _ from "lodash";
import { currentUserVar } from "apollo/cache/auth";

const CREATE_SERVICE_REQUEST = gql`
  mutation CreateServiceRequest(
    $category: ServiceRequestCategoryName!
    $customer: ServiceRequestCustomerInput!
    $property: ServiceRequestPropertyInput!
    $service: ServiceRequestServiceInput!
    $type: ServiceRequestType!
    $region: ID
    $district: ID
    $isExistingCustomer: Boolean!
    $existingCustomer: ID
    $isExistingProperty: Boolean!
    $existingProperty: ID
    $existingServicePoint: ID
  ) {
    createServiceRequest(
      category: $category
      customer: $customer
      property: $property
      service: $service
      type: $type
      region: $region
      district: $district
      isExistingCustomer: $isExistingCustomer
      existingCustomer: $existingCustomer
      isExistingProperty: $isExistingProperty
      existingProperty: $existingProperty
      existingServicePoint: $existingServicePoint
    ) {
      _id
    }
  }
`;

const CreateServiceRequestPage: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);
  const navigate = useNavigate<LocationGenerics>();
  const [createServiceRequest, { loading }] = useMutation(
    CREATE_SERVICE_REQUEST
  );

  const form = useFormik<ICreateServiceRequestFormSchema>({
    initialValues: {
      requestInfo: {
        isExistingCustomer: false,
        existingServicePoint: null,
        existingCustomer: null,
        category: "Standard",
        type: "NewServiceRequest",
        quantity: 0,
        isExistingProperty: false,
        existingProperty: null,
      },
      customerInfo: {
        customerType: "Individual",
        representative: {
          title: "Mr",
          fullName: "",
          nationality: "Ghanaian",
          dateOfBirth: "",
          gender: "Male",
          phoneNumber: "",
          emailAddress: "",
          profileImageUrl: "",
          hasGhanaCard: true,
          ghanaCardNumber: "",
          ghanaCardIssueDate: "",
          ghanaCardExpiryDate: "",
        },
        organization: {
          name: "",
          type: "Public",
        },
      },
      identityInfo: {
        representative: {
          hasGhanaCard: true,
          ghanaCardNumber: "",
          ghanaCardIssueDate: "",
          ghanaCardExpiryDate: "",
          ghanaCardFrontImageUrl: "",
          ghanaCardBackImageUrl: "",
          identityCardType: "DriversLicense",
          identityCardNumber: "",
          identityCardIssueDate: "",
          identityCardExpiryDate: "",
          identityCardFrontImageUrl: "",
          identityCardBackImageUrl: "",
        },
        organization: {
          taxIdentificationNumber: "",
          organizationRegistrationNumber: "",
          organizationRegistrationDate: "",
          organizationRegistrationDocumentUrl: "",
          certificateOfIncorporationDocumentUrl: "",
        },
      },
      propertyInfo: {
        owner: {
          fullName: "",
          phoneNumber: "",
        },
        region: null,
        district: null,
        ghanaPostAddress: "",
        community: "",
        streetName: "",
        houseNumber: "",
        structureNumber: "",
        landmark: "",
        premiseType: null,
        premiseCategory: null,
        activity: null,
        subActivity: null,
        geoLocation: {
          longitude: -0.234361,
          latitude: 5.667032,
        },
        sitePlanDocumentUrl: "",
      },
      serviceInfo: {
        serviceType: "Postpaid",
        serviceClass: "Residential",
        energyCertificateNumber: "",
        energyCertificateIssuerPhone: "",
        energyCertificateDocumentUrl: "",
      },
    },
    onSubmit: async (values) => {
      const customerData = _.chain(values.customerInfo)
        .merge(values.identityInfo)
        .thru((val) => {
          return _.pick(val, [
            "customerType",
            "category",
            ...(val.customerType === "Individual"
              ? ["representative"]
              : ["organization", "representative"]),
          ]);
        })
        .tap((val) => {
          (val.representative?.hasGhanaCard
            ? [
                "representative.identityCardType",
                "representative.identityCardNumber",
                "representative.identityCardIssueDate",
                "representative.identityCardExpiryDate",
                "representative.identityCardFrontImageUrl",
                "representative.identityCardBackImageUrl",
              ]
            : [
                "representative.ghanaCardNumber",
                "representative.ghanaCardIssueDate",
                "representative.ghanaCardExpiryDate",
                "representative.ghanaCardFrontImageUrl",
                "representative.ghanaCardBackImageUrl",
              ]
          ).forEach((field) => _.unset(val, field));
        })
        .value();
      const {
        existingCustomer,
        quantity,
        existingProperty,
        existingServicePoint,
        ...requestInfoData
      } = values.requestInfo;
      await createServiceRequest({
        variables: {
          ...requestInfoData,
          ...(existingCustomer
            ? { existingCustomer: existingCustomer._id }
            : {}),
          ...(existingProperty
            ? { existingProperty: existingProperty._id }
            : {}),
          ...(existingServicePoint
            ? { existingServicePoint: existingServicePoint._id }
            : {}),
          customer: customerData,
          property: {
            ...values.propertyInfo,
            region: values.propertyInfo.region?._id || currentUser.region?._id,
            district:
              values.propertyInfo.district?._id || currentUser.district?._id,
            premiseType: values.propertyInfo.premiseType?._id,
            premiseCategory: values.propertyInfo.premiseCategory?._id,
            activity: values.propertyInfo.activity?._id,
            subActivity: values.propertyInfo.subActivity?._id,
          },
          service: {
            ...values.serviceInfo,
            serviceQuantity: quantity + 1,
          },
          region: values.propertyInfo.region?._id || currentUser.region?._id,
          district:
            values.propertyInfo.district?._id || currentUser.district?._id,
        },
      }).then(({ data }) => {
        if (data.createServiceRequest._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Request Created Successfully",
            })
          );
          form.resetForm();
          navigate({
            to: `/service-requests/${data.createServiceRequest._id}`,
            search: {},
            replace: true,
          });
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not create Service Request",
            })
          );
        }
      });
    },
    onReset: () => {
      navigate({
        to: "/service-requests",
        replace: true,
      });
    },
  });

  return (
    <main className='flex-1 flex flex-col overflow-hidden h-screen bg-gray-50'>
      <Header />
      <div className='flex flex-1 overflow-y-auto'>
        <CreateServiceRequestForm form={form} isSubmitLoading={loading} />
      </div>
    </main>
  );
};

export default CreateServiceRequestPage;
