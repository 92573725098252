import { FC } from "react";
import numeral from "numeral";
import moment from "moment";
import lodash from "lodash";
import { ICurrentConfig } from "apollo/cache/config";
import { wrapImage } from "utils";

const ReadingInfoSummary: FC<{ data: any; config: ICurrentConfig }> = ({
  data,
  config: { dateFormat },
}) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    {/* {JSON.stringify(data, null, 2)} */}
    <div className="">
      <span className="text-xs font-light">Final Reading Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Initial Reading Date
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.previousReading?.readingDate
              ? moment(data?.readingDate).format(dateFormat)
              : "N/A"}
          </div>
        </div>
        <div className="col-span-2 row-span-3">
          <span className="block text-sm font-light text-gray-700">
            Initial Reading Image
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.previousReading?.readingImageUrl ? (
              <>
                {wrapImage(
                  <img
                    src={data?.readingImageUrl}
                    alt={"reading"}
                    className="w-full  h-64 object-cover object-center text-xs"
                  />
                )}
              </>
            ) : (
              <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                Initial Reading image not provided
              </div>
            )}
          </div>
        </div>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Initial Reading Value
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {lodash.isNumber(data?.previousReading?.readingValue)
              ? numeral(data?.previousReading?.readingValue).format("0,0")
              : "N/A"}{" "}
            KWh
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6">
      <span className="text-xs font-light">Final Reading Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Final Reading Date
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.finalReading?.readingDate
              ? moment(data?.readingDate).format(dateFormat)
              : "N/A"}
          </div>
        </div>
        <div className="col-span-2 row-span-3">
          <span className="block text-sm font-light text-gray-700">
            Final Reading Image
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.finalReading?.readingImageUrl ? (
              <>
                {wrapImage(
                  <img
                    src={data?.readingImageUrl}
                    alt={"reading"}
                    className="w-full  h-64 object-cover object-center text-xs"
                  />
                )}
              </>
            ) : (
              <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                Final Reading image not provided
              </div>
            )}
          </div>
        </div>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Initial Reading Value
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {lodash.isNumber(data?.finalReading?.readingValue)
              ? numeral(data?.finalReading?.readingValue).format("0,0")
              : "N/A"}{" "}
            KWh
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6">
      <span className="text-xs font-light">Extra Information</span>
      <div className="grid gap-6 mt-2">
        <div>
          <span className="block text-sm font-light text-gray-700">Notes</span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.notes || "N/A"}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ReadingInfoSummary;
